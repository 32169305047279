import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Text from './Text'
import Button from './Button'

const useStyle = makeStyles((theme)=>({
	root: {
		},
	dialog: {
		background: theme.palette.type==='dark' ? theme.palette.secondary.dark : theme.palette.secondary.lightest
	},

}))

function Confirm ({ 
	cancelText="Cancel", 
	onOpen, 
	open, 
	confirmText="Confirm", 
	title="Confirm",
	content="Are You Sure..." ,
	className,
	onCancel, 
	onConfirm, 
	children
}) {
	
	const style= useStyle()

	return (<React.Fragment>
		<Dialog
		  className={style.root+' '+className}
		  classes={{paper:style.dialog}}
	      disableBackdropClick
	      disableEscapeKeyDown
	      maxWidth="xs"
	      open={open}
	    >
	      <DialogTitle>{title}</DialogTitle>
	      <DialogContent>
	        <Text>{content}</Text>
	      </DialogContent>
	      {onConfirm!==false && <DialogActions>
	      	        {onCancel!==false && <Button onClick={onCancel}>
	      	          {cancelText}
	      	        </Button>}
	      	        <Button onClick={onConfirm} primary>
	      	          {confirmText}
	      	        </Button>
	      	      </DialogActions>}
	    </Dialog>
	    <div onClick={onOpen} style={{display: 'inline-flex'}}>
	    	{children}
	    </div>
	</React.Fragment>)
}

export default Confirm