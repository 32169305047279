import React from 'react'
import Box from '../../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles';
import ChartTipTimeBase from './ChartTipTimeBase'

const pill = {
	height:24,
	width:24,
	borderRadius:12
}

const useStyles = makeStyles(theme=>({
	danger: {
  		backgroundColor:'red',
  		...pill
  	},
  	warning: {
  		backgroundColor:'orange',
  		...pill
  	},
  	none: {
  		backgroundColor:'green',
  		...pill
  	}
}))

function AnomalyWarningChartTip (props){
	const styles = useStyles()
	const {payload} = props
	if(payload===null || !payload[0]) return false

	const level = payload[0].value
	const cls = level<=0?'none':(level===1?'warning':'danger')
	return <ChartTipTimeBase {...props}>
		<Box center margin={7}>
			<Box className={styles[cls]}></Box>
		</Box>
	</ChartTipTimeBase>
}

export default AnomalyWarningChartTip