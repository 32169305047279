import React from 'react'
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import Link from '../../base/ui/Link'
import { useTheme } from '@material-ui/core/styles'
import withSensorsList from './withSensorsList'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StatusIcon from '../sensors/StatusIcon'

function SensorMiniList ({sensors=[], limit, count, skip, loadMoreSensors, goToSensor}){
	const theme = useTheme()
	
	return <Box vert hidden grow>
		<Text center heading={3}>Sensors</Text>
		{sensors.length>0 && <Box scroll vert>
			<List component="nav">
		        {sensors.map((s)=>{
		        //console.log(1111,s)
		        const warning = s.warning===2
		        	? theme.palette.error.main
		        	: (s.warning===1 ? theme.palette.warning.main : theme.palette.success.main)

		        return <ListItem button onClick={()=>goToSensor(s.id)}>
		          <ListItemIcon>
		            <StatusIcon status={s.state} color={warning}  />
		          </ListItemIcon>
		          <ListItemText 
		          	secondary={<Text style={{color:theme.palette.text.muted}} size={.9}>{s.label?s.id:''}</Text>} 
		          	primary={<Text size={1.1}>{s.label||s.id}</Text>} 
		          />
		        </ListItem>})}
		    </List>
		    {(count>(skip+limit)) && <Link onClick={()=>loadMoreSensors()}>See More</Link>}
		</Box>}
	</Box>
}

export default withSensorsList(SensorMiniList)