import React from 'react';
import {dispatch, on} from '../bus'
import {records as recordsFilter} from '../../base/utils/filterFactory'
import {recordsSorter} from '../../base/utils/sortFactory'
import {buildRecordsQuery} from '../../base/utils/queryFactory'
import { withRouter } from "react-router-dom";

export const withRecordsBase = (Comp, {config, store, services}) => {
  
  class Cls extends React.Component {

  	state = {
      ...store.getRecordSet(),
      recordsLoading: store.isRecordSetLoading(),
      isEditing: false, 
      columns:[],
      facets:[],
      bulkActions:[],
    }

    dataGridId = `data-grid-${Math.random()}-${Date.now()}`

    updateRecord=({id}, atts, options={})=>{
      services.updateRecord(id, atts)
      .then(()=>{
        if(options.closeEditing!==false) this.setState({isEditing:false})
        this.loadRecordsSet({
          sorter: this.state.sorter
        })
        dispatch('notify-success', {message:`Successfully Updated ${config.recordTypeDisplayName}`})
      })
    }

    deleteRecord=(record)=>{
      services.deleteRecord(record)
      .then(()=>{
        this.loadRecordsSet()
      })
    }

    deleteRecords=(records)=>{
      Promise.all(records.map((rec)=>{
        return services.deleteRecord(rec)
      })).then((res)=>{
        this.loadRecordsSet()
      }).catch((e)=>{
        dispatch('global-loading',false)
        dispatch('error', e)
      })  
    }

    archiveRecord=(record)=>{
      services.archiveRecord(record)
      .then(()=>{
        this.loadRecordsSet()
      })
    }

    setTable=({columns, facets, bulkActions})=>{
      this.setState({columns, facets, bulkActions},()=>{
        this._initFilters={...this.state.filters}
        this.loadRecordsSet({
          sorter: store.getRecordSet().sorter,
          filter: store.getRecordSet().filter
        })
      })
    }

    setEditing=(index)=>{
      this.setState({isEditing:index})
    }

    setLoading=(status)=>{
      this.setState({recordsLoading:status})
    }

    setSorter=(sorter)=>{
      if(this.state.pager){
        this.loadRecordsSet({
          pager:{index:0},
          sorter
        })
      }else{
        dispatch(`loaded-${config.recordSetTypeName}`, {sorter})
      }
    }

    setFilters=(attFilters)=>{
      const filters = {...this.state.filters, ...attFilters}
      if(this.state.pager){
        this.loadRecordsSet({
          pager:{index:0},
          filters
        })
      }else{
        dispatch(`loaded-${config.recordSetTypeName}`, {filters})
      }
    }

    buildRecordsSet=(params={})=>{
      if(!this.state.pager) return
      const pager = {...this.state.pager, ...params.pager}
      const sorter = params.sorter || this.state.sorter
      const filters = params.filters || this.state.filters
      return({pager,sorter,filters})
    }

    getRecordsSetPayload=({records, count, params={}})=>{
      if(!this.state.pager){
        return {
          records,
          rowCount:count.filtered,
          totalRows:count.collection
        }
      }
      return({
        records: (this.state.pager.type==='endless' && !params.sorter && !params.filters && (params.pager||{}).index>0) 
          ? [...this.state.records, ...records] 
          : records,     
        rowCount: count.filtered,
        totalRows: count.collection, 
      })
    }

    loadRecordsSet=(params)=>{
      const recordSet = this.buildRecordsSet(params)
      const serviceParams = buildRecordsQuery({
        ...recordSet, 
        facets:this.state.facets, 
        columns:this.state.columns
      })

      if(recordSet && recordSet.pager && recordSet.pager.index===0){
        dispatch(`datagrid-${this.dataGridId}-scroll-to-item`, {rowIndex:0})
      }

      dispatch(`loading-${config.recordSetTypeName}`, recordSet)
      services.getRecords(serviceParams)
      .then(({records, count})=>{
        const payload = this.getRecordsSetPayload({records, count, params})
        dispatch(`loaded-${config.recordSetTypeName}`, payload)
      })
      .catch(()=>{
        this.setState({recordsLoading:false})
      })
    }

    onItemsRendered=(scans)=>{
      if(!this.state.pager) return

      if(scans.overscanRowStopIndex+1 >= (this.state.pager.index+1)*this.state.pager.size){
        //console.log('new page')
        this.loadRecordsSet({
          pager:{index: this.state.pager.index+1}
        })
      }
    }

    componentDidMount(){
      
      this._loading = on(`loading-${config.recordSetTypeName}`, ()=>{
        this.setState({...store.getRecordSet(), recordsLoading:true})
      })
      this._set = on(`loaded-${config.recordSetTypeName}`, ()=>{
        this.setState({...store.getRecordSet(), recordsLoading: false})
      })

      this._update = on(`updated-${config.recordTypeName}`, ()=>{
        this.loadRecordsSet({pager:{index:0}})
      })
    }

    componentWillUnmount(){
      this._loading()
      this._set()
      this._update()
    }


    clearFilters = ()=>{
      this.setFilters({...this._initFilters})
    }

    isFiltered = ()=>{
      if(!this._initFilters) return
      //console.log(this._initFilters, this.state.filters)
      const m = Object.keys(this._initFilters).find((k)=>{
        return this._initFilters[k] === this.state.filters[k]?false:true
      })
      return m?true:false
    }

    render() {
      if(!this.state.columns) return false

      const {records, pager, filters, sorter} = this.state

      const columns = this.state.columns.map((col)=>{
        return col.sortable===false ? col : {...col, sorter: this.setSorter}
      })
      const facets = this.state.facets.map((facet)=>{
        return {...facet, filter: this.setFilters}
      })

      var rows = pager ? records : recordsFilter({records, filters, facets}) 
      if(!pager && columns.length) recordsSorter({records: rows, sorter, columns})

      return (<Comp {...this.props} {...this.state} 
        dataGridId={this.dataGridId}
        records={rows}
        columns={columns}
        facets={facets}
        isFiltered={this.isFiltered}
        onItemsRendered={this.onItemsRendered}
        clearFilters={this.clearFilters}
        rowCount={pager ? this.state.rowCount : rows.length}
        totalRows={pager ? this.state.totalRows : records.length}
        setEditing={this.setEditing}
        setLoading={this.setLoading}
        setTable={this.setTable}
        updateRecord={this.updateRecord}
        deleteRecords={this.deleteRecords}
        archiveRecord={this.archiveRecord}
        deleteRecord={this.deleteRecord}
        setSorter={this.setSorter}
        setFilters={this.setFilters} />)
    }
  }

  return withRouter(Cls)
}

export default withRecordsBase