import React from 'react'
import TextAreaFormField from '../../base/ui/TextAreaFormField'
import TextFormField from '../../base/ui/TextFormField'
import DateTimeRangeFormField from '../../base/ui/DateTimeRangeFormField'
import SelectFormField from '../../base/ui/SelectFormField'
import SwitchFormField from '../../base/ui/SwitchFormField'
import NumberFormField from '../../base/ui/NumberFormField'
import ImageInput from '../../base/ui/ImageInput'
import SwitchesField from '../../base/ui/SwitchesField'
import TextListFormField from '../../base/ui/TextListFormField'
import FormSubFieldsModelFactory from './FormSubFieldsModelFactory'

function FormFieldModelFactory ({
	field, form, subFieldsComponent
}) {
	if(field.render){
		return field.render
	}

	var props = {
		form: form,
		id: field.field,
		label: field.name,
		key: field.field

	}
	if(field.autoFocus){
		props.autoFocus=true
	}
	if(field.options){
		props.options=field.options
	}

	const Comp = field.component

	if(Comp){
		return <Comp {...props} />
	}

	if(field.subfields){
		return <FormSubFieldsModelFactory
			subFieldsComponent={subFieldsComponent}
			field={field} 
			form={form}
			fields={Object.keys(field.subfields).map((f)=>field.subfields[f])} 
		/>
	}

	if(field.type==='array' && field.options){
		return <SwitchesField 
	    	{...props}
	    	value={form.values[field.field].map((r)=>r.id||r)}
	    	onChange={form.handleChange}
	    />
	}  

	if(field.type==='array'){
		return <TextListFormField {...props} />
	}

	if(field.type==='image'){
		return <ImageInput {...props} />
	}

	if(field.options){
		return <SelectFormField {...props} />
	}

	if(field.type==='boolean'){
		return <SwitchFormField {...props} />
	}

	if(field.type==='string'){
		if(field.multiline) {
			return <TextAreaFormField {...props} />
		}else{
			return <TextFormField {...props} slugify={field.slugify} />
		}
	}

	if(field.type==='password'){
		return <TextFormField type={'password'} {...props} />
	}

	if(field.type==='number'){
		return <NumberFormField {...props} />
	}
		
	if(field.type==='date'){
		return <DateTimeRangeFormField {...props} />
	}

	return false
}

export default FormFieldModelFactory