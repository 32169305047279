import React from 'react'
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';
import PercentVaritionChartTip from './PercentVaritionChartTip'

function PercentVariationChart ({data}){
	const theme = useTheme()

	const pvArray = data.m_AutotuningElbowPVArray
	const clusterCounts = data.m_AutotuningElbowClusterCounts

	const plots = pvArray.map((pv, i)=>{
		return {
			clusterCount: clusterCounts[i],
			pv,
		}
	})

	return <LineChart 
			width={400} 
			height={400}
			data={plots}
			margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
		>
  			<YAxis
  				type="number"
  				tickCount={10}
  				dataKey="clusterCount"
  				allowDecimals={false}
				tickLine={false}
  				position="left"
      			domain={[0, 1000]}
      			stroke={theme.palette.text.disabled}
  			>
  				<Label      			
					value="Count"
					position="left"      				
					angle={-90}
					style={{ textAnchor: "middle"}}
					fill={theme.palette.text.label}
		        />
  			</YAxis>
  			<XAxis
				type="number" 
				dataKey="pv"
				position="bottom"	
				allowDecimals={true}
				tickLine={false}
				ticks={[0, .025, .05, .075, .1, .125, .15, .175, .2]}
				domain={[0, .2]}
				stroke={theme.palette.text.disabled}
			>
				<Label
					value="PV"
					position="bottom"
					style={{ textAnchor: "middle" }}
					fill={theme.palette.text.label}
		        />
			</XAxis>
			<CartesianGrid 
				stroke={theme.palette.text.disabled}
			/>
			<Tooltip isAnimationActive={false} content={<PercentVaritionChartTip />} />
		    <Line 
		    	isAnimationActive={false}
		    	dot={false}
		    	type="monotone" 
		    	dataKey="clusterCount" 
		    	stroke={theme.palette.primary.main}
		    	strokeWidth={2} 
		    />
		    <ReferenceLine x={data.m_Nano.m_NanoConfig.m_PercentVariation} stroke="red" />
		</LineChart>
}

export default PercentVariationChart