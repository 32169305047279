import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import prettyMilliseconds from 'pretty-ms'

const useStyles = makeStyles(theme=>({
	bar: {
		background: theme.palette.text.muted
	}
}))

function BulkSensorsSummery ({
	averageResponseTimes=[],
	totalExecutionTime,
	records,
	loaded, 
	errors
}){
	const classes = useStyles()
	const theme = useTheme()

	const toTime = (ms)=>{
		return prettyMilliseconds(ms, {separateMilliseconds:true})
	}

	const totalExecuted = errors+loaded
	const percentLoaded = totalExecuted/records.length
	const totalProjected = totalExecutionTime/percentLoaded

	return <>
			<Box hor>
				{records.map((cell)=>{
					return <Box className={classes.bar}></Box>
				})}
			</Box>
			<Box hor center>
				<Box center vert margin={10}>
					<Text>{'Total Time Remaining'}</Text>
					<Text size={1.5}>{averageResponseTimes.length ? toTime(Math.max(0,totalProjected-totalExecutionTime)) : ''}</Text>
				</Box>
				<Box center vert margin={10}>
					<Text>{'Total Projected Time'}</Text>
					<Text size={1.5}>{averageResponseTimes.length ? toTime(totalProjected) : ''}</Text>
				</Box>
				<Box center vert margin={10}>
					<Text>{'Percent Complete'}</Text>
					<Text size={1.5}>{averageResponseTimes.length ? `(${totalExecuted} of ${records.length}) or ${parseInt(percentLoaded*100)}%` : ''}</Text>
				</Box>
				<Box center vert margin={10}>
					<Text>{'Total Execution Time'}</Text>
					<Text size={1.5}>{totalExecutionTime===0?'':toTime(totalExecutionTime)}</Text>
				</Box>
				<Box center vert margin={10}>
					<Text>{'Minimum Execution Time'}</Text>
					<Text size={1.5}>{averageResponseTimes.length>0 ? toTime(Math.min(...averageResponseTimes)) :''}</Text>
				</Box>
				<Box center vert margin={10}>
					<Text>{'Maximum Execution Time'}</Text>
					<Text size={1.5}>{averageResponseTimes.length>0  ? toTime(Math.max(...averageResponseTimes)) :''}</Text>
				</Box>
			</Box>
		</>
}

export default BulkSensorsSummery