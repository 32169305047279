import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Link from  './Link'

const useStyle = makeStyles(theme=>({
  	logo:{
  		display: 'block',
  	}
}))

function Logo ({src, alt='Home', height=50, width, to}) {
	
	const styles = useStyle()

	var css={}
	if(width){
		css.width=width
	}else{
		css.height=height
	}

	if(to) {
		return <Link to={to}>
			<img src={src} alt={alt} className={styles.logo} style={css} />
		</Link>
	}

	return <img src={src} alt={alt} className={styles.logo} style={css} />
}

export default Logo