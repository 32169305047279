import {inferFields} from '../../base/utils/model'
export const recordTypeName = 'role'
export const recordSetTypeName = 'roles'
export const recordTypeDisplayName = 'Role'
export const recordSetTypeDisplayName = 'Roles'
export const clientEndpoint = 'role'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/roles'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field:'name',
	order:'asc'
}

export const initialFilters = {
    'id':'',
    'name':'',
    'description':'',
    'updatedAt':null,
    'createdAt':null
}

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	name:{
	  	type: 'string',
	},
	description:{
	  	type: 'string',
	},
	createdAt:{
	  	name: 'Created',
	  	type: 'date',
	},
	updatedAt:{
	  	name: 'Updated',
	  	type: 'date',
	}
})