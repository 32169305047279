import React from 'react'
import ActionFormBase from '../../base/ui/ActionForm'
import {recordTypeDisplayName} from './config'
import withRecordForm from '../../base/hocs/withRecordForm'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'
import {modelFormFieldValues} from '../../base/utils/form'
import FormFieldsModelFactory from '../../base/ui/FormFieldsModelFactory'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

	const validate = async function(values){
        var errors = {}

        if(await required(values.name)) {
          errors.name="Please input your tenant username"
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const fields = [{
			...config.fields.name,
			autoFocus:true
		},{
			...config.fields.env
		},{
			...config.fields.active
		}]

    const initialValues = modelFormFieldValues(record, fields) 

	const form = useFormik({
		enableReinitialize: true,
		validate,
		initialValues,
		onSubmit
	})

	return  <ActionFormBase 
		recordTypeDisplayName={recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<FormFieldsModelFactory fields={fields} form={form} />
	    
	</ActionFormBase>
}


export default withRecordForm(ActionForm, {config, store, services})