import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import moment from 'moment'
import Text from '../../base/ui/Text'
import DateTimeRangeField from '../../base/ui/DateTimeRangeField'
import Link from '../../base/ui/Link'
import withUser from '../auth/withUser'
import EditIcon from '../../base/ui/EditIcon'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import IconButton from '../../base/ui/IconButton'
import {getToken} from '../auth/store'
import {authServer} from '../../config'
import ImageAvatar from '../../base/ui/ImageAvatar'
import Box from '../../base/ui/Box'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)

      this.state = {
        mimeOptions:[]
      }
      
      services.getRecords({distinct:'mimetype'}).then(({records})=>{
        //console.log(records)
        const mimeOptions = records.map((r)=>{
          return {value:r, label:r}
        })
        this.setState({mimeOptions})
        this.props.setTable(this.setTable())
      })
    }

    setTable=()=>{

      var columns =[{
        ...fields.filename,
        sortable:false,
        cell:({record, index, value}) => {
          return (<Box centerVert hor>
            <ImageAvatar size={32} to={`${config.recordsPath}/${record.id}`} src={`${authServer}/api/files/${value}?token=${getToken()}`} />
            <Link block to={`${config.recordsPath}/${record.id}`} >{value}</Link>  
          </Box>)
        }
      },{
        ...fields.mimetype,
        width:150
      },{
        ...fields.size,
        width:150
      },{
        ...fields.createdAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>  
        )
      },/*{
        ...fields.updatedAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>    
        )
      },*/]

      if(this.props.getPerm(`+:${config.recordTypeName}:update`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <IconButton onClick={()=>{
            this.props.history.push(`${config.recordsPath}/${record.id}/edit`)
          }} ><EditIcon /></IconButton> 
        )
      })


      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.filename}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })
     
      return {
        columns,
        facets: [{
          ...fields.filename
        },{
          ...fields.mimetype,
          options: this.state.mimeOptions
        },{
          ...fields.createdAt,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        },{
          ...fields.updatedAt,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        }]
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords