import React from 'react'

export const withNavigation = (Comp) => {
  
  class Cls extends React.Component {

  	state = {
  		playing: false,
  		rate:3000,
  	}

    iter = () =>{
      this._t = setTimeout(()=>{
        if(this.state.playing) {
          this.props.goToNext()
          this.iter()
        }
      }, this.state.rate)
    }

  	play = ()=>{
      this.setState({playing:true}, this.iter)
    }

    pause=()=>{
      this.setState({playing:false}, clearTimeout(this._t))
    }

    slower = ()=>{
      this.setState({rate:this.state.rate+500})
    }

    faster=()=>{
      this.setState({rate:Math.max(this.state.rate-500,0)})
    }

    render() {
      return (<Comp {...this.props} {...this.state} 
      	play={this.play}
      	pause={this.pause}
        faster={this.faster}
        slower={this.slower} />)
    }
  }

  return Cls
}

export default withNavigation
