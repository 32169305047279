import React from 'react'
import withSuccess from '../hocs/withSuccess'
import Notify from './Notify'


function SuccessToast({success, onCloseSuccess}) {

  return (<Notify 
        open={success?true:false} 
        message={success.message||success} 
        onClose={onCloseSuccess} 
        autoHideDuration={2000}
        type='success' 
      />)
}

export default withSuccess(SuccessToast);
