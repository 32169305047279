import {inferFields} from '../base/utils/model'

export const recordTypeName = 'user'
export const recordSetTypeName = 'users'
export const recordTypeDisplayName = 'User'
export const recordSetTypeDisplayName = 'Users'
export const clientEndpoint = 'user'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/users'
export const primaryAttribute = 'name'

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	avatar:{
		type:'image',
	},
	password:{ 
	  	type: 'string',
	},
	firstname:{
	  	name: 'First Name',
	  	type: 'string',
	},
	lastname:{
	  	name: 'Last Name',
	  	type: 'string',
	},
	name:{
	  	type: 'string',
	},
	username:{
		type: 'string'
	},
	email:{
	  	type: 'string',
	}
})