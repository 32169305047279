import React, {useState, useEffect} from 'react'
import ActionFormBase from '../../base/ui/ActionForm'
import {recordTypeDisplayName} from './config'
import withRecordForm from '../../base/hocs/withRecordForm'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'
import {modelFormFieldValues} from '../../base/utils/form'
import FormFieldsModelFactory from '../../base/ui/FormFieldsModelFactory'
import {getRecords as TenantRecords} from '../../amber/tenants/services'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

	const [tenantList, setTenantList] = useState([])
	useEffect(()=>{
		TenantRecords()
		.then((res)=>{
			if(res.records){
				setTenantList(res.records.map((rec)=>{
					return {value:rec.id, label:rec.name}
				}))
			}
		})	
	},[])

	const validate = async function(values){
        var errors = {}

        if(await required(values.warning)) {
          errors.address="Please set a warning level"
        }

		if(await required(values.state)) {
          errors.address="Please determine a state"
        }

        if(await required(values.tenant)) {
          errors.address="Please choose a tenant"
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const fields = [{
			...config.fields.label,
			autoFocus:true
		},{
			...config.fields.warning
		},{
			...config.fields.samples
		},{
			...config.fields.state
		},{
			...config.fields.tenant,
			options:tenantList
		}]

    const initialValues = modelFormFieldValues(record, fields) 

	const form = useFormik({
		enableReinitialize: true,
		validate,
		initialValues,
		onSubmit
	})

	if(action==='section') return false

	return  <ActionFormBase 
		recordTypeDisplayName={recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<FormFieldsModelFactory fields={fields} form={form} />

	</ActionFormBase>
}


export default withRecordForm(ActionForm, {config, store, services})