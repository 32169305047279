import React from 'react'
import {dispatch, on} from '../../base/bus' 
import { withRouter } from "react-router-dom";

export const withDetailBase = (Comp, {config, store, services}) => {
  
  class Cls extends React.Component {

  	state = {
      record: undefined,
      isRecordLoading: true
    }

    setLoading=(status)=>{
      this.setState({
        isRecordLoading: status
      })
    }

    loadRecord(){
      if(!this.props.match.params.id) return
      dispatch(`loading-${config.recordTypeName}`)
      services.findRecordById(this.props.match.params.id)
      .then((res)=>{
        dispatch(`loaded-${config.recordTypeName}`,res.record)
      })
      .catch((error)=>{
        console.error(error)
      })
    }

    componentDidMount(){
      this._loading = on(`loading-${config.recordTypeName}`, ()=>{
        this.setState({isRecordLoading:true})
      })
      this._set = on(`loaded-${config.recordTypeName}`, ()=>{
        this.setState({record:store.getRecord(), isRecordLoading: false})
      })
      this._update = on(`updated-${config.recordTypeName}`, ()=>{
        this.setState({record:store.getRecord(), isRecordLoading: false})
      })
      
      this.loadRecord()
    }

    componentWillUnmount(){
      this._loading()
      this._set()
      this._update()
    }

    render() {
      return (<Comp {...this.props} {...this.state} 
        setLoading={this.setLoading}
      />)
    }
  }

  return withRouter(Cls)
}

export default withDetailBase
