import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from './Box'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles((theme)=>({
	root: {
		margin:'15px 5px',
		borderBottom:'1px solid rgba(0,0,0,0)'
	},
	pill:{
		margin:5
	},
	field:{
		margin:5
	},
	error:{
		color: theme.palette.error.main
	},
	label:{
		margin:'17px 5px 0px'
	}
}))

function TextListFormField ({form, id, hint, label, ...rest}){
	
	const [newItem, setNewItem] = useState('')
	const theme = useTheme()
	const styles = useStyles()

	const { errors, handleBlur, touched, setValues, handleChange, values} = form
	const value = values[id]||[]

	const onExit=(v)=>{
		console.log(1111,'v',v)
		value[value.length-1]=v
		setValues({...values, [id]:[...value]})
	}

	const removeItem=(i)=>{
		console.log(1111,'index',i)
		console.log(111,'before',value)

		const nv = value.filter((v,j)=>i===j?false:true)
		console.log(111, 'after',nv)
		setValues({...values, [id]:nv})
	}

	const addItem=()=>{
		if(newItem!==''){
			const nv = [...value, newItem]
			setValues({...values, [id]:nv})
			setNewItem('')
		}
	}

	const handleMouseDown = (event) => {
	    event.preventDefault();
	}

	const error = (touched[id] && errors[id])?errors[id]:false
	const style = error?{borderBottomColor: theme.palette.error.main}:{}

	return <Box vert>
		{label && <InputLabel className={styles.label}>{label}</InputLabel>}
		<Box style={style} className={styles.root} hor wrap>
			{value.map((v, i)=>{
				return <Chip
					key={i+v+''}
			        label={v}
			        className={styles.pill}
			        //onClick={handleClick}
			        onDelete={()=>removeItem(i)}
			        deleteIcon={<RemoveCircleIcon />}
			      />
			})}
			<TextField 
				id={id}
				placeholder={'next item ...'}
				name={id}
				value={newItem}
				onChange={(ev)=>setNewItem(ev.target.value)}
				onBlur={handleBlur}
				className={styles.field}
				InputProps={{endAdornment:<InputAdornment position="end">
			        <IconButton
			          onClick={addItem}
			          onMouseDown={handleMouseDown}
			        >
			          <AddCircleIcon />
			        </IconButton>
			      </InputAdornment>}}
				/> 
		</Box>
		{error && <FormHelperText className={styles.error}>{error}</FormHelperText>}
		{hint && <FormHelperText>{hint}</FormHelperText>}
	</Box>
}


export default TextListFormField