import React from 'react'
import AnalyticWarningLevelChart from './AnalyticWarningLevelChart'
import AnalyticIndexesChart from './AnalyticIndexesChart'
import AnalyticDetectionsChart from './AnalyticDetectionsChart'
import AnalyticHistoryChart from './AnalyticHistoryChart'


function AnalyticStack (props){
	return <>
		<AnalyticWarningLevelChart 
			{...props}
		/>
		<AnalyticHistoryChart 
			{...props}
		/>
		<AnalyticDetectionsChart 
			{...props}
		/>
		<AnalyticIndexesChart 
			{...props}
		/>
	</>
}

export default AnalyticStack