import React from 'react'
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import ChartTipTimeBase from './ChartTipTimeBase'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	value: {
		margin:5,
		fontSize:'.9em'
	},
	label:{
		margin:5,
		fontSize:'.7em'
	},
  	dot: {
  		height:24,
		width:24,
		borderRadius:12
  	}
}))

function AnomaliesChartTip (props){
	const styles = useStyles()
	const {payload} = props
	if(payload===null || !payload[0]) return false

	return <ChartTipTimeBase {...props}>
		<Text center>Anomaly</Text>
		{payload.map((plot,i)=>(
			<Box hor centerVert key={i}>
				<Box className={styles.dot} style={{background:plot.color}}></Box>
				<Text className={styles.label}>{plot.name}</Text>
				<Box vert grow></Box>
				<Text className={styles.value}>{plot.value}</Text>
			</Box>
		))}
	</ChartTipTimeBase>
}

export default AnomaliesChartTip