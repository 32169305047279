import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '../../base/ui/Box'
import IconButton from '../../base/ui/IconButton'

const useStyles = makeStyles(theme=>({
	pill:{
		height:24,
		width:24,
		borderRadius:12,
	}
}))

function AnomalySeriesBtn ({color, series, onChange}){
	const styles = useStyles()

	return <IconButton onClick={()=>onChange(!series)} >
		<Box className={styles.pill} style={{border:`2px solid ${color}`, background:series?color:'transparent'}} vert></Box>
	</IconButton>
}

export default AnomalySeriesBtn