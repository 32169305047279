import React from 'react'
import store from './store'
import * as services from './services'
import * as config from './config'
import withDetailBase from '../../base/hocs/withDetailBase'
import {getRecords} from '../sensors/services'

export const withDetailScene = (Comp) => {
  
  class Cls extends React.Component {

  	constructor(pars){
  		super(pars)

  		this.state = {
	  		sensors: [],
	  		limit:100,
	  		skip:0,
	  		count:0
	  	}

	  	this.getSensors({})
  	}

  	getSensors = async ({limit, skip})=>{
  		try{
  			const res = await getRecords({
  				limit:this.state.limit, 
  				skip:this.state.skip,
  				query:{
  					tenant: this.props.match.params.id
  				}
  			})

  			var state = {
  				sensors: res.records,
  				count: res.count.filtered,
  			}
  			if(limit) state.limit=limit
  			if(skip!==undefined) state.skip=skip

  			this.setState(state)
  		}catch(e){
  			console.error(e)
  		}
  	}

  	goToSensor=(id)=>{
  		this.props.history.push(`/admin/sensors/${id}`)
  	}

  	loadMoreSensors=()=>{
  		this.getSensors({
  			skip: this.state.skip+this.state.limit
  		})
  	}

    render() {
      return (<Comp {...this.props} {...this.state} 
      	loadMoreSensors={this.loadMoreSensors}
      	goToSensor={this.goToSensor} />)
    }
  }

  return withDetailBase(Cls, {store, config, services})
}

export default withDetailScene
