import {inferFields} from '../../base/utils/model'
export const recordTypeName = 'perm'
export const recordSetTypeName = 'perms'
export const recordTypeDisplayName = 'Permission'
export const recordSetTypeDisplayName = 'Permissions'
export const clientEndpoint = 'perm'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/permissions'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field:'key',
	order:'asc'
}

export const initialFilters = {
    'id':'',
    'key':'',
    'name':'',
    'access':'',
    'resource':'',
    'action':'',
    'updatedAt':null,
    'createdAt':null
}

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	key:{
	  	type: 'string',
	},
	name:{
	  	type: 'string',
	},
	access:{
	  	type: 'string',
	},
	resource:{
	  	type: 'string',
	},
	action:{
	  	type: 'string',
	},
	createdAt:{
	  	name: 'Created',
	  	type: 'date',
	},
	updatedAt:{
	  	name: 'Updated',
	  	type: 'date',
	}
})