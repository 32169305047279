import React from 'react'
import Icon from '@material-ui/icons/Create'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({
	icon: {
		color:({color})=>color?theme.palette[color].main:theme.palette.icon.active,
		fontSize:({size})=>size
	}
}))

function CheckedIcon ({color, size, className=''}) {

	const style = useStyle({color,size})

	return <Icon className={style.icon+' '+className} />
}

export default CheckedIcon