import {requireUserFieldUsername} from '../../config'
import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'user'
export const recordSetTypeName = 'users'
export const recordTypeDisplayName = 'User'
export const recordSetTypeDisplayName = 'Users'
export const clientEndpoint = 'user'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/users'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field: requireUserFieldUsername?'username':'email',
	order:'asc'
}

export const initialFilters = {
    'id':'',
    'firstname':'',
    'lastname':'',
    'email':'',
    'username':'',
    'confirmed':null,
    'roles':null,
    'updatedAt':null,
    'createdAt':null
}

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	avatar:{
		type:'image'
	},
	firstname:{
	  	name: 'First Name',
	  	type: 'string',
	},
	lastname:{
	  	name: 'Last Name',
	  	type: 'string',
	},
	password:{
		type: 'string',
	},
	confirmed:{
		type: 'boolean'
	},
	tenant:{
		type: 'string'
	},
	tenants:{
		type: 'array',
	},
	name:{
	  	type: 'string',
	},
	username:{
		type: 'string'
	},
	email:{
	  	type: 'string',
	},
	roles:{
	  	type: 'array',
	},
	createdAt:{
	  	name: 'Created',
	  	type: 'date',
	},
	updatedAt:{
	  	name: 'Updated',
	  	type: 'date',
	}
})