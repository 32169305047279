import React from 'react'
import useModelRecord from '../../base/hooks/useModelRecord'
import store from './store'
import * as services from './services'
import * as config from './config'
import Box from '../../base/ui/Box'
import SceneHeader from '../../base/ui/SceneHeader'
import JSONView from '../../base/ui/JSONView'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Overview from "./Overview"
import Status from "./Status"
import FeaturesStack from './FeaturesStack'

function Detail (){
	const [record, loading] = useModelRecord({store, config, services})
  	const { path } = useRouteMatch();

	if(!record || !record.m_Nano || loading) return false
	//console.log(record)
	return <Box vert grow hidden>
		<SceneHeader 
			title={`${config.recordTypeDisplayName} ${record.label||record[config.primaryAttribute]}`} 
			back={config.recordSetTypeDisplayName} 
		/>
		<Route path={`${path}/section/:section`} render={({history, location, match}) => {

			const tab = match.params.section||'stack'

			return (<>
				<Box hor centerVert>
					<Box vert grow>
						<Tabs
					        value={tab}
					        indicatorColor="secondary"
					        textColor="secondary"
					        onChange={(e,value)=>history.push(`/admin/sensors/${match.params.id}/section/${value}`)}
					    > 
					    	<Tab value="overview" label="Overview" />
					        <Tab value="stack" label="Features Stack" />
					        <Tab value="data" label="Data" />
					    </Tabs>
					</Box>
					<Box vert grow>
						<Status data={record} />
					</Box>
					<Box vert grow></Box>
			    </Box>

				<Box vert scroll margin={20}>
					<Switch>
						<Route path={`${path}/section/overview`}>
							<Overview data={record}  />
						</Route>
						<Route path={`${path}/section/stack`}>
							<FeaturesStack data={record}  />
						</Route>
						<Route path={`${path}/section/data`} >
							<JSONView data={record}  />
						</Route>
					</Switch>
				</Box>
			</>)
		}} />
	</Box>
}

export default Detail