import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import Box from './Box'
import Text from './Text'

function DateTimeRangeField ({label, empty, hint, error, value, onChange, ranger}) {

	const [range, setRange] = React.useState(false);
	const [time, setTime] = React.useState(false);

	const classes = makeStyles(theme => ({
	  formControl: {
	    marginTop: 30,
	    minWidth: 200,
	  },
	  range: {
	  	marginTop:5
	  },
	  sublabel: {
	  	marginTop:15
	  },
	  dialog: {
	  	width:500,
	  	maxWidth:500,
	  	'& .MuiPickersBasePicker-pickerView':{
	  		margin:'auto'
	  	},
	  	label:{
	  		color: theme.palette.text.label
	  	}
	  }
	}))()

	const rangeChange = ()=>{
		onChange(null)
		setRange(!range)
	}

	const timeChange = ()=>{
		onChange(null)
		setTime(!time)
	}

	return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<Box vert className={classes.formControl}>
	    	<Text className={classes.label}>{label}</Text>
		    {ranger!==false  && <Box hor centerVert className={classes.range}>
		    	<Switch color='primary' checked={range} onChange={rangeChange} />
		        <Text className={classes.text}>Date Range</Text>
		    </Box>}
		    {range && <Box hor centerVert className={classes.range}>
		    	<Switch color='primary' checked={time} onChange={timeChange} />
		        <Text className={classes.text}>Include Time</Text>
		    </Box>}

			{range && <Box vert>
				<Text className={classes.text}>Start Date</Text>
				{!time && <DatePicker
			        clearable={empty}
			        value={value===null?null: (value.laterThenDate||null)}
			        onChange={(v)=>onChange({...value, laterThenDate:v})}
		      	/>}
		      	{time && <DateTimePicker
		      		DialogProps={{classes:{dialogRoot:classes.dialog}}}
			        clearable={empty}
			        value={value===null?null: (value.laterThenDateTime||null)}
			        onChange={(v)=>onChange({...value, laterThenDateTime:v})}
		      	/>}
		      	<Text className={classes.text+' '+classes.sublabel}>End Date</Text>
		      	{!time && <DatePicker
			        clearable={empty}
			        value={value===null?null: (value.earlierThenDate||null)}
			        onChange={(v)=>onChange({...value, earlierThenDate:v})}
		      	/>}
		      	{time && <DateTimePicker
			        clearable={empty}
			        DialogProps={{classes:{dialogRoot:classes.dialog}}}
			        value={value===null?null: (value.earlierThenDateTime||null)}
			        onChange={(v)=>onChange({...value, earlierThenDateTime:v})}
		      	/>}
			</Box>}
			{!range && <DatePicker
		        clearable={empty}
		        value={value===null?null: (value.equalsDate||value||null)}
		        onChange={(v)=>onChange(v?{equalsDate:v}:null)}
	      	/>}

	    	{error && <FormHelperText>{error}</FormHelperText>}
		</Box>
	</MuiPickersUtilsProvider>)
}

export default DateTimeRangeField