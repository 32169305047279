import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles';
import ActionFormFrame from './ActionFormFrame'

const useStyles = makeStyles(theme=>({
	paper: {
		width:400,
      	background: theme.palette.background.modelForm
	},
	back: {
		backgroundColor: theme.palette.background.modal
	}
}))

function ActionForm ({
	action, onClose, children, isFormProcessing, 
	onDelete, failureMessage, infoMessage, title,
	successMessage,
	form, display, createText, editText, cancelText
}) {
	const styles = useStyles()

	const FormContent = <ActionFormFrame 
				action={action}
				onClose={onClose}
				isFormProcessing={isFormProcessing}
				onDelete={onDelete} 
				failureMessage={failureMessage} 
				infoMessage={infoMessage} 
				title={title}
				successMessage={successMessage}
				editText={editText}
				createText={createText}
				cancelText={cancelText}
				form={form}
			>
				{children}
			</ActionFormFrame>

	if(display==="inline"){
		return FormContent
	}

	return <Drawer 
			classes={{paper:styles.paper}}
			anchor={'right'} 
			ModalProps={{disableBackdropClick:true, BackdropProps:{classes:{root:styles.back}}}}
			open={action?true:false} 
			onClose={onClose}
		>
			{FormContent}
		</Drawer>	
}

export default ActionForm