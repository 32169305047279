import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	root: {
      	background: theme.palette.background.tooltip,
      	borderRadius:9,
      	padding:'5px  10px 5px 10px',
      	overflow:'hidden',
      	border:`1px solid ${theme.palette.secondary.main}`
	},
	title:{
		textAlign:'center',
		margin:'0 5px 5px 5px',
		color: theme.palette.secondary.main,
		fontWeight:400,
		fontSize: '.8rem'
	}
}))

function ChartTipTimeBase ({ active, label, children, allTimesArray=[]}){
	const styles = useStyles()

	if (active && label) {
		return <Box vert className={styles.root+" custom-tooltip"} >
			<Text className={styles.title}>{allTimesArray[label].time}</Text>
	        {children}
	      </Box>
	}

	return null
}

export default ChartTipTimeBase