import React from 'react'
import Link from '../base/ui/Link'
import Box from '../base/ui/Box'
import Button from '../base/ui/Button'
import { makeStyles } from '@material-ui/core/styles';
import withUser from './auth/withUser'
import ImageAvatar from '../base/ui/ImageAvatar'
import Text from '../base/ui/Text'

const useStyle = makeStyles({

	padd: {
		padding:'0 20px'
	},
	name: {
		fontSize:'1rem',
		textAlign:'left'
	},
	role: {
		fontSize:'.75rem',
		textAlign:'left'
	},
	btn: {
		borderRadius:0
	}
})

function LoggedInStatus ({user, signIn}) {

	const style = useStyle()

	return (<Box vert >
				{user && <Button className={style.btn} text to="/admin/profile">
					<Box hor grow centerVert margin={5}>
						<ImageAvatar size={45} src={user.avatar} />
						<Box vert margin={'0 7px'} >
							<Text className={style.name} >{user.name}</Text>
							<Text className={style.role} >{user.roles[0].name}</Text>
						</Box>
					</Box>
				</Button>}
				{user===false && <Link className={style.padd} onClick={signIn}>Sign In</Link>}
			</Box>)
}

export default withUser(LoggedInStatus)