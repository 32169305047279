import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'apikey'
export const recordSetTypeName = 'apikeys'
export const recordTypeDisplayName = 'API Key'
export const recordSetTypeDisplayName = 'API Keys'
export const clientEndpoint = 'apikey'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/apikeys'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field:'created',
	order:'desc'
}

export const initialFilters = {
	'name':'',
	'tenant':'',
    'token':'',
    'user':'',
    'revoked':null,
    'created':null,
    'expires':null,
    'perms':null
}

export const fields = inferFields({
	token:{
	  	name: 'API Access Token',
	  	type: 'string',
	},
	user:{
		type: 'string',
	},
	name:{
		name: 'Application Name',
		type: 'string'
	},
	tenant: {
		type: 'string'
	},
	perms: {
		type: 'array',
		options: [{value:'stream', name:'stream'}],
		default: ['stream']
	},
	revoked: {
		type: 'date'
	},
	isActive:{
		type: 'boolean'
	},
	isExpired:{
		type: 'boolean'
	},
	created:{
	  	type: 'date',
	},
	expires:{
		name: 'Expiration',
	  	type: 'number',
	}
})