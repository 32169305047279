import React from 'react'
import withForm from '../../base/hocs/withForm'
import Frame from './Frame'
import Button from '../../base/ui/Button'
import Link from '../../base/ui/Link'
import Text from '../../base/ui/Text'
import {verifyCode} from './services'
import TextField from '../../base/ui/TextFormField'
import {required} from '../../base/utils/validate'
import {useFormik} from 'formik'
import FormMessageBox from '../../base/ui/FormMessageBox'
import FormErrorList from '../../base/ui/FormErrorList'
import {validators} from '../../config'

function ValidateCode ({
	history,
	setFailureMessage, 
	setInfoMessage, 
	infoMessage, 
	failureMessage, 
	successMessage,
	setSuccessMessage,
	isFormProcessing, 
	setFormProcessing
}) {

	var initialValues = {
		code:'',
		newpassword:''
	}

	const validate = async (values) => {
        var errors = {}

        const passErrs = await validators.password({value:values.newpassword})
        if(passErrs.length){
        	errors.newpassword=<FormErrorList items={passErrs} />
        }

        if(await required(values.code)) {
          errors.code='Please input the Varification Code.'
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return 
        }
    }

    const onSubmit = async ({code, newpassword}, actions) => {
		try{
			setFormProcessing(true)
			setInfoMessage('Verifing Code...')
			const res = await verifyCode({code, password:newpassword})
			setSuccessMessage(`Code Verified`)
			setFormProcessing(false)
			history.push('/login')
        }
        catch(error){
          setFormProcessing(false)
          setFailureMessage(error.message)
        }
	}

	const form = useFormik({
		initialValues,
		validate,
	    onSubmit
	})

	const {handleSubmit, touched, errors} = form

	return <Frame title={"Reset Password"} >
		<Text style={{padding:10}} center >{"Please Check your email for a verification code and enter a new password"}</Text>
		<Text style={{padding:10}} center >{"if your code is valid your new password will be accepted."}</Text>
		<form onSubmit={handleSubmit}>
			<FormMessageBox
				failureMessage={failureMessage}
				successMessage={successMessage}
				infoMessage={infoMessage}
			/>

			<TextField
				id="code"
				form={form}
				label="Code"
				autoFocus={true}
	        />

	        <TextField
				id="newpassword"
				form={form}
				label="New Password"
				type="password"
				margin="normal"
	        />

			<Button primary submit 
				loading={isFormProcessing} 
				disabled={isFormProcessing}
			>
				Verify Code
			</Button>
			<Link to={'/login'}>Never mind. I remember it now.</Link>
		</form>
	</Frame>
}


export default withForm(ValidateCode)
