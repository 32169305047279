import React from 'react'

import {Switch, Route, useRouteMatch } from "react-router-dom";
import withProtected from '../admin/auth/withProtected'

import Home from '../admin/Home'
import E404 from '../ui/E404'
import E401 from '../ui/E401'
import Frame from '../ui/Frame'
import Assets from '../admin/assets/Records'
import Users from '../admin/users/Records'
import Roles from '../admin/roles/Records'
import Permissions from '../admin/permissions/Records'
import Profile from '../profile/Profile'
import Tenants from '../amber/tenants/Records'
import Fusion from '../fusion/Home'
import Tokens from '../admin/tokens/Records'
import ApiKeys from '../admin/apikeys/Records'
import Exports from '../admin/exports/Records'
import Sensors from '../amber/sensors/Records'
import Swagger from '../docs/explorer/Swagger'

function Admin({getPerm}) {
  let { path } = useRouteMatch();

  return (
    <Frame>
    	<Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={`${path}/profile/:id?/:action?`}>
          <Profile />
        </Route>
        <Route path={`${path}/users/:id?/:action?`} >
          {getPerm('+:user:read')?<Users />:<E401 />}
        </Route>
        <Route path={`${path}/assets/:id?/:action?`} >
          {getPerm('+:asset:read')?<Assets />:<E401 />}
        </Route>
        <Route path={`${path}/roles/:id?/:action?`} >
          {getPerm('+:role:read')?<Roles />:<E401 />}
        </Route>
        <Route path={`${path}/permissions/:id?/:action?`} >
          {getPerm('+:perm:read')?<Permissions />:<E401 />}
        </Route> 
        <Route path={`${path}/tenants/:id?/:action?`}>
          {getPerm('+:tenant:read')?<Tenants />:<E401 />}
        </Route>
        <Route path={`${path}/tokens/:id?/:action?`}>
          {getPerm('+:refreshtoken:read')?<Tokens />:<E401 />}
        </Route>
        <Route path={`${path}/apikeys/:id?/:action?`}>
          {getPerm('+:apikey:read')?<ApiKeys />:<E401 />}
        </Route>
        <Route path={`${path}/sensors/:id?/:action?`}>
          {getPerm('+:sensor:read')?<Sensors />:<E401 />}
        </Route>
        <Route path={`${path}/exports/:id?/:action?`}>
          {getPerm('+:exportedcollection:read')?<Exports />:<E401 />}
        </Route>
        <Route path={`${path}/fusion`}>
          {(getPerm('+:assetconfigorg:read')||getPerm('+:assetconfiggroup:read')||getPerm('+:assetconfigasset:read')||getPerm('+:assetconfigsensor:read')||getPerm('+:assetconfigtopic:read'))?<Fusion getPerm={getPerm} />:<E401 />}
        </Route>
        <Route path={`${path}/explorer`}>
          {getPerm('+:explorer:read')?<Swagger />:<E401 />}
        </Route>
        <Route path="*">
          <E404 />
        </Route>
     </Switch>
    </Frame>
  );
}

export default withProtected(Admin);
