import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageAvatar from '../../base/ui/ImageAvatar'
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import Card from '../../base/ui/Card'
import SceneHeader from '../../base/ui/SceneHeader'
import withDetailScene from './withDetailScene'

const useStyle = makeStyles(theme=>({
	root: {
		height:'100%'
	},
	name: {
		margin:7,
		color: theme.palette.common.white
	},
	role: {
		color: theme.palette.common.white
	},
	content: {
		padding: '130px 0px 0px 0px',
	},
	header: {
		backgroundColor: theme.palette.primary.dark,
		paddingBottom:80,
		borderBottom: `10px solid ${theme.palette.secondary.dark}`,
		zIndex:111
	},
	avatar: {
		margin:'-140px 20px 20px 20px'
	},
	perm: {
		margin:'5px 20px',
		width:200
	},
	swatch: {
		height:80,
		width:80,
		borderRadius:8,
		border: theme.palette.type==='dark'?'1px solid rgba(255,255,255,.2)':`1px solid rgba(0,0,0,.2)`,
	},
	card:{
		display:'flex',
		flexDirection:'column',
		flex:1,
		margin:10
	},
	text:{
		color: theme.palette.type==='dark'?'rgba(255,255,255,.42)':`rgba(0,0,0,.4)`,
	},
	activeText:{
		color: theme.palette.secondary.main,
	},
	activeSwatch:{
		border: `1px solid ${theme.palette.secondary.main}`
	}
}))

function Detail ({user}){
	const style = useStyle()

	if(!user) return false

	return <Box vert grow hidden>
		<SceneHeader />
		<Box vert scroll>
			<Box vert hidden grow className={style.content}>
				<Box vert  center className={style.header}>
					<Box vert className={style.avatar} center>
						<ImageAvatar size={200} src={user.avatar} />
					</Box>
					<Text className={style.name} heading={1}>{user.name}</Text>
					<Text className={style.role} heading={4}>{user.roles && user.roles[0] && user.roles[0].name}</Text>
				</Box>
			</Box>
			<Box hor hidden grow >
				<Box vert scroll grow between>
					<Box margin={10} hor>
						<Card className={style.card} title={'Permissions'}>	
							<Box hor wrap>	
								{user.roles && user.roles[0] && user.roles[0].perms.map((perm)=>{
									return<Text key={perm.name} className={style.perm} weight={300}>{perm.name}</Text>
								})}
							</Box>
						</Card>
					</Box>
				</Box>
				<Box vert grow>
				</Box>
			</Box>
		</Box>
	</Box>
}

export default withDetailScene(Detail)