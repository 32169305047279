import moment from 'moment'

export const buildRecordsQuery = ({pager,sorter, facets=[], columns=[], filters,})=>{
  if(!pager) return

  var serviceParams = {}

  if(pager.size){
    serviceParams.limit = pager.size;
    serviceParams.skip = pager.index * pager.size;
  }
    
  if(sorter){
    serviceParams.sort = (sorter.order==='desc'?'-':'')+sorter.field
  }

  if(columns.length){
    serviceParams.fields = columns.filter(col=>col.field).map(col=>col.field).join(',')
  }

  if(filters && facets.length) {
    var query = {}
    Object.keys(filters).forEach((field)=>{
      const value = filters[field]
      const facet = facets.find((f)=>f.field===field)

      if(facet && facet.filterFn){
        query[field] = facet.filterFn(value)
        return
      }

      if(facet && facet.type==='multiple-string'){
        const qfo = MultipleString({value})
        if(qfo) query[field] = qfo
        return
      }

      if(facet && facet.field==='_id'){
        const qfo = Exact({value})
        if(qfo) query[field] = qfo
        return
      }

      if(facet && facet.type==='combined-date-time'){
        const qfo = CombinedDateTime({value})
        if(qfo) query[field] = qfo
        return
      }

      if (facet && facet.options) {
        const qfo = Exact({value})
        if(qfo) query[field] = qfo
        return
      }

      if (facet && facet.type==='string') {
        const qfo = StartsWith({value})
        if(qfo) query[field] = qfo
        return
      }

      if (facet && facet.type==='number') {
        const qfo = isNumber({value})
        if(qfo!=='') query[field] = qfo
        return
      }
           
    })
    if(Object.keys(query).length>0) {
      serviceParams.query = JSON.stringify(query)
    }
  }

  return serviceParams
}

export const MultipleString = ({value})=>{
  if(value==='') return
  const parts = value.split(',').map((p)=>p.trim())
  return {'$in':parts}
}
    
export const CombinedDateTime = ({value})=>{
  if(value===null) return

  if(value.equalsDate){
    return {
      $gt: moment(moment(value.equalsDate).format('YYYY-MM-DD')), 
      $lt: moment(moment(value.equalsDate).add(1, 'd').format('YYYY-MM-DD'))
    }
  }

  var q = {}
  if(value.earlierThenDateTime){
    q['$lt'] = moment(moment(value.earlierThenDateTime).add(1, 'm').format('YYYY-MM-DD HH:mm'))
  }
  if(value.laterThenDateTime){
    q['$gt'] = moment(moment(value.laterThenDateTime).format('YYYY-MM-DD HH:mm'))
  }
  if(value.earlierThenDate){
    q['$lt'] = moment(moment(value.earlierThenDate).add(1, 'd').format('YYYY-MM-DD'))
  }
  if(value.laterThenDate){
    q['$gt'] = moment(moment(value.laterThenDate).format('YYYY-MM-DD'))
  }
  return q
}

export const StartsWith = ({value})=>{
  if(value==='') return
  return {'$regex': '^'+value, '$options':'igm'}
}

export const Exact = ({value})=>{
  if(value==='') return
  return value
}

export const isNumber = ({value})=>{
  try{
    if(typeof(parseFloat(value))==='number'){
      return value
    }
  }catch(e){
    return
  }
}