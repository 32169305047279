import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '../../base/ui/Card'
import Text from '../../base/ui/Text'

const useStyles = makeStyles((theme)=>({
	scene: {
		display: 'flex',
		flexDirection: 'column',
	    flex: 1,
	    justifyContent: 'center',
	    alignItems: 'center',
	    '& h1':{
	    	margin: 20
	    },
	    '& form': {
	    	display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'&>div': {
				margin:20,
				width: 400
			},
			'&>button': {
				margin:20,
				width: 150,
			}
	    }
	},
	card:{
		background: theme.palette.background.authForm,
		margin:50
	}
}))

function AuthFrame ({children, title, subtitle}) {

	const styles = useStyles()

	return <div className={styles.scene}>
		<Card className={styles.card}>
			{title && <Text center caps heading={1} weight={100}>{title}</Text>}
			{subtitle && <Text center caps heading={4} weight={400}>{subtitle}</Text>}
			{children}
		</Card>
	</div>
}

export default AuthFrame
