import React from 'react'
import useConfirm from '../hooks/useConfirm'
import Confirm from '../../base/ui/Confirm'

function ControlledConfirm ({children, onCancel, onConfirm, onOpen, ...rest}) {

	const confirm = useConfirm({onCancel, onConfirm, onOpen})

	return <Confirm {...rest} {...confirm}>
		{children}
	</Confirm>
}

export default ControlledConfirm