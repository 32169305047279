import React from 'react';
import {getUser} from './store'
import {on} from '../../base/bus'
import withUser from './withUser'

export const withProtected = (Comp) => {
  class Cls extends React.Component {
    componentDidMount(){
      this._e = on('user-data', ()=>{
        this.signOut()
      })
      this.signOut()
    }
    signOut=()=>{
      if(getUser()===false){
        this.props.signIn()
      }
    }
    componentWillUnmount(){
      this._e()
    }
    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }
  return withUser(Cls)
}
export default withProtected