import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';
import {materialRainbow} from '../../base/utils/color'
import Color from 'color'
import Swatch from './Swatch'

const useStyles = makeStyles(theme=>({
	root:{
	},
	heading:{		
	},
	title:{
		width:'50%'
	},
	section:{
		borderBottom:`1px solid ${theme.palette.text.disabled}`,
		marginBottom:15,
		paddingBottom:10
	},
	text:{
		margin:'0 10px 0 10px'
	}
}))

function Section ({title, subtitle, children, pre, post, start, end}){
	const styles = useStyles()

	return <Box vert className={styles.root} grow>
		
		<Box hor center className={styles.heading}>
			<Box hor start grow>
				{start && start}
			</Box>
			<Box hor center className={styles.title}>
				{pre && pre}
				<Text heading={3} className={styles.text} center >{title}</Text>
				{post && post}
			</Box>
			<Box hor end grow>
				{end && end}
			</Box>
		</Box>
		{subtitle && <Text center heading={5} className={styles.heading}>{subtitle}</Text>}
		<Box vert className={styles.section}>
			{children}
		</Box>
	</Box>
}

export default Section