import {dispatch} from '../bus'

var resizeTimer;

const updateDimensions = ()=>{
	clearTimeout(resizeTimer);
  	resizeTimer = setTimeout(()=>{
		_dims = {
			height: window.innerHeight,
		    width: window.innerWidth
		}
		dispatch('updated-dimensions',_dims)
		console.log('updated-dimensions',_dims)	
	},250)
}

var _dims = {
	height: window.innerHeight,
    width: window.innerWidth
}

export const getDimensions = () => {
  return _dims
}

window.addEventListener("resize", updateDimensions)

