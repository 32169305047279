import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RecentSamplesChartScub from './RecentSamplesChartScub'
import RecentSamplesAreaChart from './RecentSamplesAreaChart'

const useStyles = makeStyles(theme=>({
	root:{
		position:'relative',
		height:200,
	},
	layer:{
		position:'absolute',
		height:200,
		left:0,
		right:0
	},
}))

function RecentSamplesLayers ({data, colors, selectedFeatures, allTimesArray, zoomed, zoomScrub, setZoomScrub}){
	const styles = useStyles()
	const theme = useTheme()

	return <div className={styles.root}>
		<div className={styles.layer}>
			<RecentSamplesAreaChart 
				data={data} 
				theme={theme}
				zoomed={zoomed}
				selectedFeatures={selectedFeatures} 
				colors={colors} 
				allTimesArray={allTimesArray}
				zoomScrub={zoomScrub}
				setZoomScrub={setZoomScrub}
			/>
		</div>
		<div className={styles.layer}>
			<RecentSamplesChartScub data={data} 
				zoomed={zoomed}
				theme={theme}
				selectedFeatures={selectedFeatures} 
				colors={colors} 
				allTimesArray={allTimesArray}
				zoomScrub={zoomScrub}
				setZoomScrub={setZoomScrub}
			/>
		</div>
	</div>
}

export default RecentSamplesLayers