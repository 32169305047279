import React from 'react'
import Box from './Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(theme=>({
	footer: {
	    backgroundColor: theme.palette.background.footer,
	    zIndex: 2,
	    justifyContent:'space-between',
	    alignItems: 'flex-end',
	    padding:5
	},
}))

function Footer ({ actions}) {

	const style = useStyle()

	return (
		<Box hor className={style.footer}>
			<Box vert>
			</Box>
			<Box vert>
				{actions}
			</Box>
		</Box>)
}

export default Footer