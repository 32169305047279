import React from 'react'

import Button from '../base/ui/Button'
import Box from '../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles'
import withUser from './auth/withUser'
import Color from 'color'

const useStyle = makeStyles(theme=>{
	return {
		wrap: {
			padding:10,

			'&>button': {
				width: 200,
			    height: 200,
			    margin: 10,
			    //color: theme.palette.primary.dark,//==='dark'? Color(theme.palette.secondary.lightest).alpha(.4).toString(): Color(theme.palette.secondary.dark).alpha(.4).toString()
			    //borderColor: theme.palette.primary.dark,// Color(theme.palette.secondary.lightest).alpha(.4).toString(): Color(theme.palette.secondary.dark).alpha(.4).toString()
			}
		}
	}
})

function Home ({getPerm, userRole, user}) {
	
	const style = useStyle()

	return <Box center hor grow>
			<Box wrap hor centerHor test="main-menu" className={style.wrap}>
				{getPerm('+:user:read') && <Button to="/admin/users">Users</Button>}
				{getPerm('+:role:read') && <Button to="/admin/roles">Roles</Button>}
				{getPerm('+:perm:read') && <Button to="/admin/permissions">Permissions</Button>}
				{getPerm('+:asset:read') && <Button to="/admin/assets">Assets</Button>}
				{getPerm('+:tenant:read') && <Button to="/admin/tenants">Tenants</Button>}
				{getPerm('+:refreshtoken:read') && <Button to="/admin/tokens">Refresh Tokens</Button>}
				{getPerm('+:apikey:read') && <Button to="/admin/apikeys">API Keys</Button>}
				{getPerm('+:sensor:read') && <Button to="/admin/sensors">Sensors</Button>}
			</Box>
		</Box>
}

export default withUser(Home)