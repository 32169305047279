import React from 'react'

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Text from './Text'
import { makeStyles } from '@material-ui/core/styles';

function Notify ({open, type='info', autoHideDuration=10000, onClose, message }) {
	
  const style = makeStyles((theme)=>({
    root: {
      "&>div": {
        backgroundColor: theme.palette[type].main
      },
      "&>svg": {
        color: theme.palette.common.white
      }
    },
    text: {
      color: theme.palette.common.white
    }
  }))()

	return <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={style.root}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={<Text weight={600} className={style.text}>{message}</Text>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            className={style.text}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
}

export default Notify