import React from 'react'
import {debounce} from '../../base/utils/listeners'
export const withFacet = (Comp) => {
  
  class Cls extends React.Component {

  	state = {
  		value:this.props.value
  	}

  	componentDidMount(){
  		this.filterValue = debounce(this.filterValue, 500)
  	}

  	componentDidUpdate(prevProps, prevState) {
  		if(this.props.value!==prevProps.value){
  			//console.log('componentDidUpdate',this.props.value,prevProps.value)
  			this.setState({value:this.props.value})
  		}
  	}

  	filterValue=(value)=>{
  		this.props.filter({[this.props.field]:value})
  	}

  	setValue=(value)=>{
  		this.setState({value})
  		this.filterValue(value)
  	}

    render() {
      return (<Comp {...this.props} {...this.state} setValue={this.setValue} />)
    }
  }

  return Cls
}

export default withFacet
