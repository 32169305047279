import React from 'react'
import AttributesDetail from '../../base/ui/AttributesDetail'
import useModelRecord from '../../base/hooks/useModelRecord'
import store from './store'
import * as services from './services'
import * as config from './config'

function Detail (){
	const [record, loading] = useModelRecord({store, config, services})
	console.log('Detail', record, loading)
	
	if(!record || loading) return false

	return <AttributesDetail 
		record={record} 
		title={`${config.recordTypeDisplayName} ${record[config.primaryAttribute]}`} 
		back={config.recordSetTypeDisplayName} />
}


export default Detail