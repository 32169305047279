import React from 'react';
import {on} from '../bus'

export const withCollection = (Comp, {config, services}) => {
  
  class Cls extends React.Component {

  	state = {
      [config.recordSetTypeName]:[],
      query: config.query||{}
    }

    loadRecordsSet=()=>{
      services.getRecords(this.state.query)
      .then(({records, count})=>{
        this.setState({
          [config.recordSetTypeName]:records
        })
      })
      .catch(()=>{
        this.setState({recordsLoading:false})
      })
    }

    setQuery = (query) => {
      this.setState({query})
    }

    componentDidMount(){
      this._update = on(`updated-${config.recordTypeName}`, ()=>{
        this.loadRecordsSet()
      })

      this.loadRecordsSet()
    }

    componentWillUnmount(){
      this._update()
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return Cls
}

export default withCollection