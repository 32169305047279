import React from 'react'
import Text from '../../base/ui/Text'
import ChartTipTimeBase from './ChartTipTimeBase'

function AnomalyHistoryChartTip (props){
	const {payload} = props
	if(payload===null || !payload[0]) return false

	return <ChartTipTimeBase {...props}>
		<Text center>{payload[0].value}</Text>
	</ChartTipTimeBase>
}

export default AnomalyHistoryChartTip