import React from 'react'
import {on} from '../bus'
import {isLoading} from '../stores/loader'

export const withLoader = (Comp) => {
  return class extends React.Component {

  	state = {
      loading: isLoading()
    }

    componentDidMount(){
      this._e = on('global-loading', ()=>{
        this.setState({loading:isLoading()})
      })
    }

    componentWillUnmount(){
      this._e()
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }
}

export default withLoader
