import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'tenant'
export const recordSetTypeName = 'tenants'
export const recordTypeDisplayName = 'Tenant'
export const recordSetTypeDisplayName = 'Tenants'
export const clientEndpoint = 'tenant'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/tenants'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field:'name',
	order:'asc'
}

export const initialFilters = {
    'id':'',
    'name':'',
}

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	name:{
	  	type: 'string',
	},
})