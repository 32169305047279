import React from 'react'
import Box from '../../base/ui/Box'
import FeaturesOverview from './FeaturesOverview'
import ClusterGrowthChart from './ClusterGrowthChart'
import PercentVariationChart from './PercentVariationChart'
import ThresholdChart from './ThresholdChart'
import Section from './Section'
import Autotune from './Autotune'
import Learning from './Learning'

function Overview ({data}){
	return <Box vert grow>
		<FeaturesOverview data={data} />
		<ClusterGrowthChart data={data} />
		<Box hor wrap>
			<Section title="Percent Variation" subtitle={`PV ${data.m_Nano.m_NanoConfig.m_PercentVariation.toFixed(3)}`}>
				<Box hor centerHor>
					<PercentVariationChart data={data} />
					<Autotune data={data} />
				</Box>
			</Section>
			<Section title="Threshold" subtitle={data.m_AnomalyThreshold}>
				<Box hor centerHor>
					<ThresholdChart data={data} />
					<Learning data={data} />
				</Box>
			</Section>
		</Box>
	</Box>
}

export default Overview