import React from 'react'
import TextFormField from '../../base/ui/TextFormField'
import SelectField from '../../base/ui/SelectField'
import ActionFormBase from '../../base/ui/ActionForm'
import {recordTypeDisplayName} from './config'
import withRecordForm from '../../base/hocs/withRecordForm'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

    const validate = async function(values){
        var errors = {}

        if(await required(values.access)) {
          errors.access="Please Grant or Reject Permission"
        }

        if(await required(values.resource)) {
          errors.resource="Please Provide a Resource for this Permission"
        }

        if(await required(values.action)) {
          errors.action="Please Provide an Action for this Permission"
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const initialValues = {
		access:record?record.access:'grant',
		resource:record?record.resource:'',
		action:record?record.action:''
	}

	const form = useFormik({
		enableReinitialize: true,
		validate,
		initialValues,
		onSubmit
	})

	return <ActionFormBase 
		recordTypeDisplayName={recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<SelectField
			id="access"
			label="Access"
			onChange={form.handleChange}
	    	options={[{label:'Grant', value:'grant'},{label:'Reject', value:'reject'}]}
	    	error={form.errors['access']}
	    	value={form.values['access']}
	    />
	    <TextFormField
			form={form}
			id="resource"
			label="Resource"
	    />
	    <TextFormField
			form={form}
			id="action"
			label="Action"
	    />
	</ActionFormBase>
}


export default withRecordForm(ActionForm, {config, store, services})