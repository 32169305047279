import React from 'react'
import MDCard from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
	root: {
		backgroundColor:'rgba(255,255,255, .05)',
		border: '1px solid rgba(255,255,255, .1)'
	},
	title: {
		padding:({padding})=>padding,
	}
})

function Card ({
	title,
	padding=5,
	children,
	className=''
}) {

	const style= useStyle({padding})

	return (<MDCard className={style.root+ ' '+className}> 
		{title && <CardHeader title={title} className={style.title} />}
		<CardContent className={style.title} style={{paddingBottom:padding}} >
			{children}
		</CardContent>
	</MDCard>)
}


export default Card