import {toSpaceCase} from './string'
import {defaultFieldValueFactory} from './form'
import {models} from '../../config'

export class MetaModelPrimer {
  constructor(recordTypeName) {
    this.recordTypeName=recordTypeName
    this.meta = models[this.recordTypeName]
    this.fields = this.meta.fields
  }
  inferFields(modfields={}){
    const {fields} = this
    this.fields = inferFields({ ...fields, ...modfields})
    return this.fields
  }
  getFacets(){
    const {fields} = this
    let ff = {}
    Object.keys(fields).map((field)=>{
      if(fields[field].facet){
        ff[field]=defaultFieldValueFactory(fields[field])
      }
    })
    return ff
  }
  initialSort(){
    const {fields} = this
    let sort = {}
    Object.keys(fields).map((field)=>{
      if(fields[field].sort){
        sort[field]=fields[field].sort
      }
    })
    return sort
  }
}

export const buildField = (field, att, flds, obj)=>{
  var props = {
    field: field,
    name: att.name || toSpaceCase(field).replace(" I D"," ID"),
    type: att.type || 'string',
  }

  //title
  props.title = att.title || props.name

  //description
  if(att.description){
    props.description=att.description
  }

  //options
  if(att.options){
    props.options=att.options
  }

  //facet
  if(att.facet){
    props.facet = att.facet
  }

  //sort
  if(att.sort){
    props.sort = att.sort
  }

  //slugify
  if(att.slugify){
    props.slugify = att.slugify
  }

  //slugified
  if(att.slugified){
    //set related field to slugify
    obj[att.slugified]=obj[att.slugified]||{}
    obj[att.slugified]['slugify']=props.field
  }

  //multiline
  if(att.multiline){
    props.multiline=att.multiline
  }

  //required
  if(att.required){
    props.required = att.required
  }

  //unique
  if(att.unique){
    props.unique = att.unique
  }

  //references
  if(att.type==='array' && att.items && att.items['$ref']){
    props.references = att.items['$ref']
  }
  //reference
  if(att.reference){
    props.reference = att.reference
  }

  //default
  if(att.default!==undefined){
    props.default=att.default
  }else if(att.type==='array'){
    props.default=[]
  }

  return props
}   

export const inferFields = function(fields) {
  
  var o = {}

  const infer = (flds, obj)=>{

    if(flds.properties){
      if(flds.required){
        flds.required.forEach((k)=>{
          //merge down required
          obj[k]=obj[k]||{}
          obj[k].required=true
        })
      }
      infer(flds.properties, o)
    }
    else{
      for (var field in flds){
        const att={...flds[field], ...flds[field]._ui_}

        var props = buildField(field, att, flds, obj)

        //nest form fields
        if(att.properties){
          props.subfields={}
          infer(att.properties, props.subfields)
        }

        obj[field] = obj[field] || {}
        obj[field] = {...obj[field], ...props}
      }     
    }
  }

  infer(fields, o)

  return o
}
