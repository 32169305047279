import {on} from '../base/bus'
import {recordTypeName, recordSetTypeName} from './config'

var _selectedTheme = localStorage.getItem('selected-theme')||'light'
var _showBackgroundImage = localStorage.getItem('show-background-image')||'true'
var _loader = localStorage.getItem('selected-loader')||'circular'

on('selected-theme-change', (theme)=>{
	 localStorage.setItem('selected-theme', theme)
	_selectedTheme=theme
})

on('selected-loader-change', (loader)=>{
	 localStorage.setItem('selected-loader', loader)
	_loader=loader
})

on('show-background-image-change', (status)=>{
	 localStorage.setItem('show-background-image', status?'true':'false')
	_showBackgroundImage=status?'true':'false'
})

export const getSelectedTheme=()=>{
	return _selectedTheme
}

export const showBackgroundImage=()=>{
	return _showBackgroundImage==='true' ? true : false
}

export const getLoader=()=>{
	return _loader
}


