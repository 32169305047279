import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import Button from '../../base/ui/Button'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SlideshowIcon from '@material-ui/icons/Slideshow';
import PauseIcon from '@material-ui/icons/Pause';
import withNavigation from './withNavigation'
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';

const useStyles = makeStyles(theme=>({
	root:{
		'&>button':{
			margin:5
		}
	}
}))

function CarouselNavigator ({goToNext, slower, faster, playing, rate, play, pause, goToPrevious}){
	const styles = useStyles()
	
	return <Box hor className={styles.root}>
		{playing && <Button primary onClick={slower} >
			<FastRewindIcon />
			Slower
		</Button>}
		{!playing && <Button primary onClick={goToPrevious} >
			<SkipPreviousIcon />
			Previous
		</Button>}
		{!playing && <Button primary onClick={play} >
			<SlideshowIcon />
			Play
		</Button>}
		{playing && <Button primary onClick={pause} >
			<SlideshowIcon />
			pause
		</Button>}
		{!playing && <Button primary onClick={goToNext} >
			Next
			<SkipNextIcon />
		</Button>}
		{playing && <Button primary onClick={faster} >
			Faster
			<FastForwardIcon />
		</Button>}
	</Box>
		
}

export default withNavigation(CarouselNavigator)