
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
}

//var debounceMap={}

// export const waitFor = (id, fn, timeoutMs)=>{

//   if(!debounceMap[id]){
//     debounceMap[id]=null
//   }

//   const later = () => {
//     debounceMap[id]=null
//     fn()
//   }
  
//   clearTimeout(debounceMap[id])
  
//   debounceMap[id] = setTimeout(later, timeoutMs)
// }
