import {on} from '../bus'

var _loading=0;

on('global-loading', (loading)=>{
	if(loading){
		_loading=_loading+1
	}else{
		_loading=Math.max(0, _loading-1)
	}
})

export const isLoading = ()=>{
	return _loading>0?true:false
}