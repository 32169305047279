import {makeStyles} from '@material-ui/core/styles'
import MontserratThin from "../fonts/Montserrat/Montserrat-Thin.ttf"
import MontserratMedium from "../fonts/Montserrat/Montserrat-Medium.ttf"
import MontserratBold from "../fonts/Montserrat/Montserrat-Bold.ttf"
import back from './back.jpg'
import Color from 'color'
import {showBackgroundImage} from '../profile/store'
import {withStyleChange} from '../profile/withStyleChange'

const useStyles = makeStyles(theme=>{

const html = {
  height: '100%',
  fontFamily: theme.typography.fontFamily,
  fontSize:16,
  backgroundColor: theme.palette.background.body
}

const background = {
  backgroundImage: `url(${back})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  backgroundSize: 'cover'
}

return {
  '@global': {
    '@font-face': [{
      fontFamily: "Montserrat",
      src: `local(MontserratThin), url(${MontserratThin})`,
      fontWeight:100
    },{
      fontFamily: "Montserrat",
      src: `local(MontserratMedium), url(${MontserratMedium})`,
      fontWeight:500
    }, {
      fontFamily: "Montserrat",
      src: `local(MontserratBold), url(${MontserratBold})`,
      fontWeight:700

    }],
    'html': ({showBackgroundImage}) => showBackgroundImage? {...html, ...background} : html ,
    'body':{
      background: `linear-gradient(180deg, ${theme.palette.background.body} 20%, ${Color(theme.palette.background.body).darken(.2).alpha(.7).toString()} 100%)`,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
      width: '100%'
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden'
    }

  },
}});

function GlobalStyle({children, showBackgroundImage}) {
  useStyles({showBackgroundImage})
  return children
}

export default withStyleChange(GlobalStyle)
