import React from 'react'
import { withRouter } from "react-router-dom";

import MIconButton from '@material-ui/core/IconButton'


function IconButton ({history, test, style, onClick, className, id, disabled, children, to, back}) {
	
	var props = {
		test:test,
		disabled: disabled,
		className: className,
		onClick: onClick,
		id:id,
		style:style
	}

	if(to){
		props.onClick=()=>{
			history.push(to)
		}
	}
	else if(back){
		props.onClick=()=>{
			history.goBack()
		}
	}

	return <MIconButton {...props} >{children}</MIconButton>
}


export default withRouter(IconButton)