import React from 'react';
import {getUser} from './store'
import {logout} from './services'
import {on, dispatch} from '../../base/bus'

export const withUser = (Comp) => {
  class Cls extends React.Component {

    state = {
      user:getUser()
    }

    componentDidMount(){
      this._e = on('user-data', ()=>{
        this.setState({user: getUser()})
      })
      this.setState({user: getUser()})
    }

    componentWillUnmount(){
      this._e()
    }

    signIn = () => {
      window.location.href = '/login'
    }

    signOut = async () => {
      await logout()
      dispatch('auth-logout')
    }

    getPerm = (key) => {
      const {user} = this.state
      if (!key || !user || !user.roles || !user.roles.length) {
        return false
      }
      
      const valid = user.roles.find((role)=>{
        const perms = role.perms.map((perm)=>{
          return perm.key
        })
        return (perms.includes('+:*:*')||perms.includes(key))?true:false
      })
      return valid?true:false
    }

    render() {
      return (<Comp {...this.props} {...this.state}
        signOut={this.signOut} signIn={this.signIn}
        getPerm={this.getPerm}
      />)
    }
  }

  return Cls
}

export default withUser
