import React from 'react'
import SwitchField from './SwitchField'
import Box from './Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	field: {},
	formControl: {
		margin:'20px 0 10px 0'
	}
})

function SwithFormField ({form, disabled, style, label, id, ...rest}){

	const styles = useStyles()

	const { errors, touched, handleChange, values} = form
	const error = touched[id] && errors[id]

	return <Box vert style={style} className={styles.formControl}>
	   	<SwitchField 
			id={id}
			name={id}
			label={label}
			error={error?true:false}
			hint={error}
			disabled={disabled}
			value={values[id]}
			onChange={handleChange}
			className={styles.field}
			{...rest}
	    />
	</Box>
}


export default SwithFormField