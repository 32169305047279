import React from 'react'
import AnomalyStack from './AnomalyStack'
import AnomalyCombo from './AnomalyCombo'

class Anomalies extends React.Component {
	render(){
		if(this.props.expandedAnomalies) {
			return < AnomalyStack {...this.props} />
		}
		return <AnomalyCombo {...this.props} /> 
	}
	
	shouldComponentUpdate(nextProps){
		return (nextProps.data===this.props.data && nextProps.expandedAnomalies===this.props.expandedAnomalies && nextProps.sampleSize === this.props.sampleSize && nextProps.endDate === this.props.endDate)?false:true
	}

}

export default Anomalies