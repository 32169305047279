import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
})

function FormErrorList ({
	items=[]
}) {

	const style=useStyle()

	return (<ul>{items.map((e)=>(<li key={e}>{e}</li>))}</ul>)
}


export default FormErrorList