import React from 'react'
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import ChartTipBase from './ChartTipBase'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	label:{
		margin:'0 5px 5px 5px',
		fontWeight:400,
		fontSize: '.7rem',
		marginRight:10
	},
	value:{
		margin:'0 5px 5px 5px',
		color: theme.palette.secondary.main,
		fontWeight:400,
		fontSize: '.9rem'
	}
}))

function PercentVaritionChartTip (props){
	const styles = useStyles()
	const {payload} = props
	if(payload===null || !payload[0]) return false

	return <ChartTipBase {...props}>
		<Box hor between>
			<Text className={styles.label}>Count</Text>
			<Text className={styles.value}>{payload[0].payload.clusterCount}</Text>
		</Box>
		<Box hor between>
			<Text className={styles.label}>PV</Text>
			<Text className={styles.value}>{payload[0].payload.pv.toFixed(5)}</Text>
		</Box>
	</ChartTipBase>
}

export default PercentVaritionChartTip