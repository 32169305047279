import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Autotuning from '@material-ui/icons/Tune'
import Monitoring from '@material-ui/icons/Tv'
import Buffering from '@material-ui/icons/Timer'
import Learning from '@material-ui/icons/School'

const useStyles = makeStyles(theme=>({
	icon:{
		fontSize:40
	}
}))

function StatusIcon ({status, color}){
	const styles = useStyles()
	const theme = useTheme()
	const style = {color: color||theme.palette.secondary.main}
	if(status==="Autotuning") return <Autotuning style={style} className={styles.icon} />
	if(status==="Monitoring") return <Monitoring style={style} className={styles.icon} />
	if(status==="Buffering") return <Buffering style={style} className={styles.icon} />
	if(status==="Learning") return <Learning style={style} className={styles.icon} />
	return false
}

export default StatusIcon