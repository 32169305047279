import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color'


const useStyles = makeStyles(theme=>({
	swatch: {
		width:({width})=>width,
      	background: Color(theme.palette.secondary.main).alpha(.3).toString(),
      	borderRadius:9,
      	margin:10,
      	padding:10,
      	height:({height})=>height,
      	overflow:'hidden'
	},
	title:{
		marginBottom:5
	},
	colorBadge:{
		height:10,
		margin:'-10px -10px 5px -10px'

	}
}))

function Swatch ({children, height='auto', width='auto', title, className='', onClick, colorBadge}){
	const styles = useStyles({height, width})

	return <Box onClick={onClick} vert className={styles.swatch+' '+className}>
				{colorBadge && <Box className={styles.colorBadge} style={{backgroundColor:colorBadge}}></Box>}
				{title && <Text className={styles.title} heading={6} center>{title}</Text>}
				{children}
			</Box>
}

export default Swatch