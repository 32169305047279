import React from 'react'
import TextField from '@material-ui/core/TextField';
import withFacet from '../hocs/withFacet'
import SelectField from '../../base/ui/SelectField'
import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

function Facet ({
	value,
	filter,
	type='string',
	field,
	name,
	reference,
	options,
	disabled,
	render,
	setValue
}){	
	const handleMouseDown = (event) => {
	    event.preventDefault();
	}

	if(render) {
		return render({value, setValue, disabled, field, name, filter})
	}

	if(options) return <SelectField 
      	value={value} 
  		disabled={disabled}
      	empty={`Clear Selection ${name}`} 
	  	label={`Filter By ${name}`}
      	options={options}
      	onChange={(ev)=>{
        	setValue(ev.target.value)
      	}}
    />

	if(type==='string') return <TextField
		label={`Filter By ${name}`}
		value={value}
		clearable={''}
		disabled={disabled}
		onChange={(e)=>{
			setValue(e.target.value)
		}}
		InputProps={{endAdornment:<InputAdornment position="end">
	        {value!=='' && value!==undefined && <IconButton
	        	          onClick={()=>setValue('')}
	        	          onMouseDown={handleMouseDown}
	        	        >
	        	          <CancelIcon color="primary" />
	        	        </IconButton>}
	      </InputAdornment>}}
	/>
          
	if(type==='number') return <TextField
		label={`Filter By ${name}`}
		value={value}
		clearable={0}
		disabled={disabled}
		onChange={(e)=>{
			setValue(e.target.value)
		}}
		InputProps={{endAdornment:<InputAdornment position="end">
	        {value!=='' && <IconButton
	          onClick={()=>setValue('')}
	          onMouseDown={handleMouseDown}
	        >
	          <CancelIcon color="primary" />
	        </IconButton>}
	      </InputAdornment>}}
	/>
}

export default withFacet(Facet)