import React from 'react'
import IconButton from '../../base/ui/IconButton'
import UnfoldLess from '@material-ui/icons/UnfoldLess'
import UnfoldMore from '@material-ui/icons/UnfoldMore'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	icon:{
		color: theme==='dark'? '#ffffff' : theme.palette.primary.main
	},
}))

function ExpanderIcon ({expanded, onChange}){
	const style = useStyles()
	
	return <IconButton onClick={()=>onChange(expanded?false:true)}>
		{expanded && <UnfoldLess className={style.icon} />}
		{!expanded && <UnfoldMore className={style.icon} />}
	</IconButton>
}

export default ExpanderIcon