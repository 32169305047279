import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import moment from 'moment'
import Text from '../../base/ui/Text'
import DateTimeRangeField from '../../base/ui/DateTimeRangeField'
import Link from '../../base/ui/Link'
import Box from '../../base/ui/Box'
import withUser from '../auth/withUser'
import EditIcon from '../../base/ui/EditIcon'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import ImageAvatar from '../../base/ui/ImageAvatar'
import IconButton from '../../base/ui/IconButton'
import CheckedIcon from '../../base/ui/CheckedIcon'
import {requireUserFieldUsername, requireUserFieldEmail} from '../../config'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)
      this.props.setTable(this.setTable())
    }

    setTable=()=>{

      var columns =[{
        ...fields.name,
        sortable:false,
        cell:({record, index, value}) => {
          return (<Box centerVert hor>
            <ImageAvatar size={32} to={`${config.recordsPath}/${record.id}`} src={record.avatar} />
            <Link block to={`${config.recordsPath}/${record.id}`} >{value}</Link>  
          </Box>)
        }
      }]

      if(requireUserFieldEmail){
        columns.push({
          ...fields.email,
        })
      }

      if(requireUserFieldUsername){
        columns.push({
          ...fields.username,
        })
      }

      columns = columns.concat([{
        ...fields.tenant,
        cell:({record, index, value}) => {
          return <Text>{value && value.name}</Text>  
        }
      },{
        ...fields.roles,
        cell:({record, index, value}) => {
          return <Text>{value && value.length>0 && value.map((r)=>r.name)}</Text>  
        }
      },{
        ...fields.confirmed,
        width:150,
        cell:({record, index, value}) => {
          return (<Box centerVert hor>
            {value && <CheckedIcon />}
          </Box>)
        }
      },{
        ...fields.createdAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>  
        )
      }])

      if(this.props.getPerm(`+:${config.recordTypeName}:update`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <IconButton onClick={()=>{
            this.props.history.push(`${config.recordsPath}/${record.id}/edit`)
          }} ><EditIcon /></IconButton> 
        )
      })

      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.name}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })

      var facets = []
      if(requireUserFieldEmail) {
        facets.push({
          ...fields.email
        })
      }
      if(requireUserFieldUsername) {
        facets.push({
          ...fields.username
        })
      }

      facets = facets.concat([{
          ...fields.id,
          field:'_id'
        },{
          ...fields.firstname
        },{
          ...fields.lastname
        },{
          ...fields.createdAt,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        }])
     
      return {
        columns,
        facets
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords