import React from 'react'
import Icon from '@material-ui/icons/CheckCircle'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({
	checked: {
		color:({color})=>theme.palette[color].main,
		fontSize:({size})=>size
	}
}))

function CheckedIcon ({color='success', size, className=''}) {

	const style = useStyle({color,size})

	return <Icon className={style.checked+' '+className} />
}

export default CheckedIcon