import React from 'react'
import ActionFormBase from '../base/ui/ActionForm'
import withRecordForm from '../base/hocs/withRecordForm'
import ImageInput from '../base/ui/ImageInput'
import SwitchFormField from '../base/ui/SwitchFormField'
import {required} from '../base/utils/validate'
import store from '../admin/users/store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'
import {modelFormFieldValues} from '../base/utils/form'
import FormFieldsModelFactory from '../base/ui/FormFieldsModelFactory'
import FormErrorList from '../base/ui/FormErrorList'
import {validators, requireUserFieldUsername} from '../config'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete, roles,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

    const validate = async function(values){
        var errors = {}

        const emalErrs = await validators.email({value:values.email})
        if(emalErrs.length){
        	errors.email=<FormErrorList items={emalErrs} />
        }

        if(requireUserFieldUsername){
        	const nameErrs = await validators.username({value:values.username})
	        if(nameErrs.length){
	        	errors.username=<FormErrorList items={nameErrs} />
	        }
        }

        if(values.password){
	        const passErrs = await validators.password({value:values.password})
	        if(passErrs.length){
	        	errors.password=<FormErrorList items={passErrs} />
	        }
	    }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const fields = [{
    		...config.fields.avatar
    	},{
			...config.fields.email
		},{
			...config.fields.username
		},{
			...config.fields.firstname
		},{
			...config.fields.lastname
		},{
			...config.fields.password,
			type:'password'
		}]

    const initialValues = modelFormFieldValues(record, fields) 

	const form = useFormik({
		enableReinitialize: true,
		validate,
		initialValues,
		onSubmit
	})

	return  <ActionFormBase 
		recordTypeDisplayName={config.recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<FormFieldsModelFactory fields={fields} form={form} />
	    
	</ActionFormBase>
}


export default withRecordForm(ActionForm, {config, store, services})