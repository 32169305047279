import React from 'react'
import Box from '../../base/ui/Box'
import Button from '../../base/ui/Button'
import Text from '../../base/ui/Text'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import prettyMilliseconds from 'pretty-ms'
import ClusterGrowthChartMini from './ClusterGrowthChartMini'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles(theme=>({
	item:{
		border:'none',
		width:250,
		height:250,
		background: theme.palette.background.modal,
		margin:10,
		padding:0,
		'&>span':{
			height:'100%'
		}
	},
	itemContent:{
		flex:1,
		height:'100%'
	},
	itemText: {
		color:'rgba(255,255,255, .7)',
		paddingTop:3
	},
	itemSubText: {
		color:'rgba(255,255,255, .9)'
	},
	itemTimeText:{
		color: 'rgba(255,255,255, .8)',
		textAlign:'right'
	},
	check:{
		color:'rgba(255,255,255, .8)',
		margin:5
	},
	chart:{
		cursor:'pointer'
	}
}))

function SensorDetailsStatus ({
	records, 
	detailsMap, 
	onSelect
}){
	const classes = useStyles()
	const theme = useTheme()

	const toTime = (ms)=>{
		return prettyMilliseconds(ms, {separateMilliseconds:true})
	}

	return <Box center hor wrap>
			{records.map((rec,i)=>{
				const detail = detailsMap[rec.id]
				var style = {}
				var error=false
				var disabled=true
				if(detail){
					if(detail.error){
						error=true
						style.background=theme.palette.error.light
					}
					else {
						disabled=false
						style.background=theme.palette.primary.light
					}
				}
				//console.log(11111, detail)
				return <Button disabled={disabled} style={style} className={classes.item} onClick={()=>onSelect({record:rec, index:i})}>
					<Box between className={classes.itemContent} vert>
				
						<Box vert>
							<Text center size={1.25} className={classes.itemText} >{rec.id}</Text>
							<Text center size={.7} className={classes.itemSubText} >{rec.label}</Text>
						</Box>
						{detail && !error && <Box className={classes.chart} >
							<ClusterGrowthChartMini data={detail} />
						</Box>}
						{detail &&  <Box hor centerVert end>
								<Text size={.9} className={classes.itemTimeText} >{toTime(detail._ts)}</Text>
								{!error && <CheckCircleIcon className={classes.check} />}
								{error && <ErrorIcon className={classes.check} />}
						</Box>}
					</Box>
				</Button>
			})}
			</Box>
}

export default SensorDetailsStatus