import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color'


const useStyles = makeStyles(theme=>({
	swatch: {
		width:({width})=>width,
      	background: Color(theme.palette.secondary.main).alpha(.3).toString(),
      	borderRadius:9,
      	margin:10,
      	padding:10,
      	height:({height})=>height,
      	overflow:'hidden'
	},
	label:{
		color: Color(theme.palette.text.primary).alpha(.6).toString(),
		fontSize: '.7em',
		marginRight: 3
	},
	value:{
		marginLeft:3
	},
	title:{
		marginBottom:5
	},
	colorBadge:{
		height:10,
		margin:'-10px -10px 5px -10px'

	}
}))

function ListSwatch ({list=[], height='auto', width='auto', title, colorBadge}){
	const styles = useStyles({height, width})

	return <Box vert className={styles.swatch}>
				{colorBadge && <Box className={styles.colorBadge} style={{backgroundColor:colorBadge}}></Box>}
				{title && <Text className={styles.title} heading={6} center>{title}</Text>}
				{list.map((item,i)=>(<Box hor between key={i}>
					{item.label && <Text className={styles.label}>{item.label}</Text>}	
					<Text className={styles.value}>{item.value}</Text>
				</Box>))}
			</Box>
}

export default ListSwatch