import React, {useState} from 'react'
import FormFieldsModelFactory from './FormFieldsModelFactory'
import FormFieldModelFactory from './FormFieldModelFactory'
import Box from './Box'
import Text from './Text'
import Button from './Button'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTheme } from '@material-ui/core/styles';

function FormSubFieldsModelFactory ({
	field ,fields, form, subFieldsComponent="Columns"
}) {
	const [value, setValue] = React.useState(2);
	const theme = useTheme()

	const handleChange = (event, newValue) => {
		setValue(newValue);
	}

	const flds = fields.filter((f)=>!f.subfields && !f.references)
	const subflds = fields.filter((f)=>f.subfields)
	const refs = fields.filter((f)=>f.references)

	return <Box vert>
		{field && <Button text>{field.name}</Button>}
		<FormFieldsModelFactory 
			form={form}
			fields={flds} 
		/>
		<Tabs
		    value={value}
		    indicatorColor="primary"
		    textColor="primary"
		    onChange={handleChange}
		    variant="fullWidth"
		    style={{marginTop:30, marginBottom:10}}
		>
		    {subflds.map((sfld)=>(<Tab value={sfld.field} label={sfld.name} />))}
		    {refs.map((sfld)=>(<Tab value={sfld.field} label={sfld.name} />))}
		</Tabs>
		{subflds.map((sfld)=>{
			return <>
				{sfld.field === value && <FormSubFieldsModelFactory
					subFieldsComponent={subFieldsComponent} 
					form={form}
					fields={Object.keys(sfld.subfields).map((f)=>sfld.subfields[f])} 
				/>}
			</>
		})}
		{refs.map((sfld)=>{
			return <>
				{sfld.field === value && <FormFieldModelFactory
					field={sfld} 
					form={form}
				/>}
			</>
		})}
	</Box>
}

export default FormSubFieldsModelFactory