import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from './Box'

const useStyle = makeStyles(theme=>({
	'@keyframes fade': {
	  '10%': {background: theme.palette.rotation[0]},
	  '20%': {background: theme.palette.rotation[1]},
	  '60%': {background: theme.palette.rotation[2]},
	  '100%': {background: theme.palette.rotation[3]}
	},
	'@keyframes fadein':{
		'0%':{opacity:0},
		'100%':{opacity: 1},
	},
	root:{
		position:'absolute',
		top:0,
		left:0,
		right:0,
		bottom:0,
		opacity:0,
		animation: '$fadein 1s linear 50ms infinite alternate',
		background: 'rgba(0,0,0,.2)'
	},
  	pix: {
  		height:({size})=>size,
  		width:({size})=>size,
  		background: theme.palette.rotation[0]
  	},
  	color: {
  		//animation: '$fade 1s linear infinite alternate'
  	}
}))

function RupeeLoader ({size=10, loading, text}) {

	const style = useStyle({size})

	if(!loading) return false

	const n = 'rgba(0,0,0,0)'
	const b = '#000'
	const w = '#fff'
	const c = 'c' //// theme.palette.rotation[Math.min(loading-1, theme.palette.rotation.length-1)]

	//10w 13h
	const matrix = [
		[n,n,n,n,b,b,n,n,n,n],
		[n,n,n,b,w,c,b,n,n,n],
		[n,n,b,w,w,c,c,b,n,n],
		[n,b,w,w,w,c,c,c,b,n],
		[b,w,c,w,c,b,c,b,c,b],
		[b,w,w,c,c,c,b,c,c,b],
		[b,w,c,w,c,c,b,c,c,b],
		[b,c,c,c,w,b,w,b,c,b],
		[n,b,c,c,c,c,c,c,b,n],
		[n,n,b,c,c,c,c,b,n,n],
		[n,n,n,b,c,c,b,n,n,n],
		[n,n,n,n,b,b,n,n,n,n],
	]

	return <Box vert center className={style.root}>
		{matrix.map((row, r)=>{
			return <Box key={r} hor>
				{row.map((pix, p)=>{
					return <Box vert key={p} 
					className={style.pix+' '+(pix==='c'?style.color:'')} 
					style={{height:(r===6?size*6:size), background:(pix!=='c'?pix:undefined)}}></Box>
				})}
			</Box>
		})}
	</Box>
}

export default RupeeLoader