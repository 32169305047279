import agent from 'superagent'
import {authServer} from '../../config'
import {handleError, handleResolve} from '../../base/utils/http'
import {dispatch} from '../../base/bus'

export const login = ({email, username, password}) =>
  new Promise((resolve, reject) => {    
    dispatch('global-loading',true)
    agent.post(`${authServer}/api/auth/token`)
    .send({email, password, username})
    .then(handleResolve(resolve, {loader:true}))
    .catch(handleError(reject, {notify:false, loader:true}))
  })

export const resetPassword = (email) =>
  new Promise((resolve, reject) => {    
    agent.post(`${authServer}/api/auth/reset-password`)
    .send({email})
    .then(handleResolve(resolve))
    .catch(handleError(reject, {notify:false}))
  })

export const verifyCode = ({code, password}) =>
  new Promise((resolve, reject) => {    
    dispatch('global-loading',true)
    agent.post(`${authServer}/api/auth/validate-reset-password`)
    .send({code, password})
    .then(handleResolve(resolve, {loader:true}))
    .catch(handleError(reject, {notify:false, loader:true}))
  })

export const register = ({email, username, password}) =>
  new Promise((resolve, reject) => {    
    dispatch('global-loading',true)
    agent.post(`${authServer}/api/auth/register`)
    .send({email, username, password})
    .then(handleResolve(resolve, {loader:true}))
    .catch(handleError(reject, {notify:false, loader:true}))
  })

export const logout = ()=>
  new Promise((resolve, reject)=>{
    agent.post(`${authServer}/api/auth/release`)
    .then(handleResolve(resolve))
    .catch(handleError(reject))
  })


export const refresh = () =>
  new Promise((resolve, reject) => {
    agent.post(`${authServer}/api/auth/refresh`)
    .then(handleResolve(resolve))
    .catch(handleError(reject, {notify:false}))
  })

export const getAuthUser = (token) =>
  new Promise((resolve, reject) => {
    agent.get(`${authServer}/api/auth/user`)
    .set('x-access-token',token)
    .then(handleResolve(resolve, {vo:(res)=>{
      const user = res.body
      if(user.avatar){
        user.avatar=`${authServer}${user.avatar.http}?token=${token}`
      }
      return user
    }}))
    .catch(handleError(reject))
  })

export const putAuthUser = (token, params) =>
  new Promise((resolve, reject) => {
    agent.put(`${authServer}/api/auth/user`)
    .set('x-access-token',token)
    .send(params)
    .then(handleResolve(resolve, {vo:(res)=>{
      const user = res.body
      if(user.avatar){
        user.avatar=`${authServer}${user.avatar.http}?token=${token}`
      }
      return user
    }}))
    .catch(handleError(reject))
  })