import React from 'react';
import { withRouter } from 'react-router-dom'

export const withForm = (Comp) => {
  class Cls extends React.Component {

  	state = {
      successMessage: false,
      failureMessage: false,
      infoMessage: false,
      isFormProcessing: false,
    }

    setSuccessMessage = (msg) => {
      this.setState({ successMessage: msg, failureMessage: false, infoMessage: false })
    }

    setFailureMessage = (msg) => {
      this.setState({ successMessage: false, failureMessage: msg, infoMessage: false })
    }

    setInfoMessage = (msg) => {
      this.setState({ infoMessage: msg })
    }

    setFormProcessing = (status) => {
      this.setState({ isFormProcessing: status })
    }

    render() {
      return (<Comp {...this.props} {...this.state} 
        setSuccessMessage={this.setSuccessMessage}
        setInfoMessage={this.setInfoMessage}
        setFailureMessage={this.setFailureMessage}
        setFormProcessing={this.setFormProcessing}
      />)
    }
  }

  return withRouter(Cls)
}

export default withForm