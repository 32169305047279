import React from 'react'
import Logo from './Logo'
import Box from '../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles';
import LoggedInStatus from '../admin/LoggedInStatus'

const useStyle = makeStyles(theme=>({
	header: {
	    backgroundColor: theme.palette.background.header,
	    zIndex: 2,
	    justifyContent:'space-between',
	    alignItems: 'center',
	    boxShadow: theme.palette.shadow.header
	}
}))

function Header () {

	const style = useStyle()

	return (
		<Box hor className={style.header}>
			<Box vert center margin={'0 20px'}>	
				<Logo to="/admin" />
			</Box>
		    <LoggedInStatus />
		</Box>)
}

export default Header