import React from 'react'
import {dispatch, on} from '../../base/bus' 
import withForm from "./withForm"

export const withRecordForm = (Comp, {config, store, services}) => {
  
  class Cls extends React.Component {

  	state = {
      record: undefined,
      isRecordLoading: true,
      isRecordUpdating: true
    }

    loadRecord(){
      if(!this.props.match.params.id) return
      if(this.props.match.params.action==='create') return

      dispatch(`loading-${config.recordTypeName}`)
      services.findRecordById(this.props.match.params.id)
      .then((res)=>{
        dispatch(`loaded-${config.recordTypeName}`,res.record)
      })
      .catch((error)=>{
        console.error(error)
      })
    }

    componentDidMount(){
      this._loading = on(`loading-${config.recordTypeName}`, ()=>{
        this.setState({isRecordLoading:true})
      })
      this._set = on(`loaded-${config.recordTypeName}`, ()=>{
        this.setState({record:store.getRecord(), isRecordLoading: false})
      })
      this._updating = on(`updating-${config.recordTypeName}`, ()=>{
        this.setState({isRecordUpdating:true})
      })
      this._update = on(`updated-${config.recordTypeName}`, ()=>{
        this.setState({record:store.getRecord(), isRecordUpdating: false})
      })
      
      this.loadRecord()
    }

    componentWillUnmount(){
      this._loading()
      this._set()
      this._updating()
      this._update()
    }

    onSubmit=(values, actions) => {
      console.log(11112, values)
      const {setFormProcessing, setInfoMessage, setFailureMessage} = this.props
      const action = this.props.match.params.action

      setFormProcessing(true)
      setInfoMessage('processing...')
      dispatch(`updating-${config.recordTypeName}`)

      const service = action==='create'
        ?services.createRecord(values)
        :services.updateRecord(this.state.record.id, values)

      service.then((res)=>{
        const record = res.record || res.body.record
        dispatch(`updated-${config.recordTypeName}`, record)
        dispatch('notify-success', {message:`Successfully ${action==='create'?'Created':'Updated'} ${record[config.recordDisplayField]}`})
        setInfoMessage(false)
        setFormProcessing(false)
        this.props.onSuccess? this.props.onSuccess(record) : this.onClose()
      })
      .catch((error)=>{
        setInfoMessage(false)
        setFormProcessing(false)
        console.error(error)
        //dispatch(`updated-${config.recordTypeName}`) //why update form with empty record
        setFailureMessage(error.message)
      })
    }

    onDelete=()=>{
      const {setFormProcessing, setInfoMessage, setFailureMessage} = this.props

      setFormProcessing(true)
      setInfoMessage('Removing...')

      services.deleteRecord(this.state.record)
      .then(()=>{
        dispatch('notify-success', {message:`Successfully Removed ${config.recordTypeDisplayName}`})
        dispatch(`deleted-${config.recordTypeName}`)
        setInfoMessage(false)
        setFormProcessing(false)
        this.onClose()
      })
      .catch((error)=>{
        setInfoMessage(false)
        setFormProcessing(false)
        setFailureMessage(error.message)
      })
    }

    onClose=()=>{
      this.props.onClose
        ?this.props.onClose()
        :this.props.history.push(config.recordsPath)
    }

    render() {
      const action = this.props.match.params.action
      const title = this.props.title!==undefined?this.props.title : ( action==='edit'?`Edit ${config.recordTypeDisplayName} ${this.state.record
        ?this.state.record[config.primaryAttribute]:''}`
        :`Create New ${config.recordTypeDisplayName}`)
      
      return (<Comp {...this.props} {...this.state} 
        action = {action}
        title = {title}
        onSubmit={this.onSubmit}
        onClose={this.onClose}
        onDelete={this.onDelete}
      />)
    }
  }

  return withForm(Cls)
}

export default withRecordForm
