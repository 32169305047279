import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {getPlasmaMap} from '../utils/color'

const cm = getPlasmaMap(256)

const useStyles = makeStyles((theme) => ({
  
  bar:{
  	display:'flex', 
    flexDirection:'row',
    minWidth:250
  },
  swatch: {
    flex:1 ,
    height:24,
  }
}));

function PlasmaBar({width='100%'}){
	const classes = useStyles()
	return <div className={classes.bar} style={{width}}>{cm.map((c)=>{
	        return <div key={c} className={classes.swatch} style={{background:c}}></div>
	      })}
	</div>
}

export default PlasmaBar