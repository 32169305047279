import {useState, useEffect} from 'react'
import {on, dispatch} from '../../base/bus'
import {useParams} from 'react-router-dom'

function useModelRecord({store, services, config}){
	
	const [record, setRecord] = useState(undefined)
	const [loading, setLoading] = useState(true)

	const params = useParams()

	//register loading state and request record
	useEffect(()=>{
		 (async()=>{
			try {
				const event = on(`loading-${config.recordTypeName}`, ()=>setLoading(true))
				if(params.action!=='create'){
					dispatch(`loading-${config.recordTypeName}`)
					const {record} = await services.findRecordById(params.id)
					dispatch(`loaded-${config.recordTypeName}`,record)
					setRecord(record)
				}
				return event
			} catch (error) {
				throw error
			}
		})()	 
	},[config.recordTypeName, store])

	useEffect(function(){
		return  on(`loaded-${config.recordTypeName}`, ()=>{
			setRecord(store.getRecord())
			setLoading(false)
		})
	},[config.recordTypeName, store])

	useEffect(function(){
		return  on(`updated-${config.recordTypeName}`, ()=>{
			setRecord(store.getRecord())
			setLoading(false)
		})
	},[config.recordTypeName, store])

	return [record,loading]
}

export default useModelRecord