import React from 'react'
import withForm from '../../base/hocs/withForm'
import Frame from './Frame'
import Button from '../../base/ui/Button'
import Link from '../../base/ui/Link'
import {login, getAuthUser} from './services'
import TextField from '../../base/ui/TextFormField'
import {required, password} from '../../base/utils/validate'
import {dispatch} from '../../base/bus'
import {useFormik} from 'formik'
import FormMessageBox from '../../base/ui/FormMessageBox'
import {requireUserFieldUsername, requireUserFieldEmail, validators} from '../../config'
import FormErrorList from '../../base/ui/FormErrorList'

function Login ({
	history,
	setFailureMessage, 
	setInfoMessage, 
	infoMessage, 
	failureMessage, 
	successMessage,
	setSuccessMessage,
	isFormProcessing, 
	setFormProcessing
}) {

	var initialValues = {
		password:''
	}
	if(requireUserFieldEmail) initialValues.email=''
	if(requireUserFieldUsername) initialValues.username=''

	const validate = async (values) => {
        var errors = {}

        const passErrs = await validators.password({value:values.password})
        if(passErrs.length){
        	errors.password=<FormErrorList items={passErrs} />
        }

        if(requireUserFieldEmail){
        	const emalErrs = await validators.email({value:values.email})
	        if(emalErrs.length){
	        	errors.email=<FormErrorList items={emalErrs} />
	        }
        } 

        if(requireUserFieldUsername){
        	const nameErrs = await validators.username({value:values.username})
	        if(nameErrs.length){
	        	errors.username=<FormErrorList items={nameErrs} />
	        }
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return 
        }
    }

    const onSubmit = async (values, actions) => {
		try{
			setFormProcessing(true)
			setInfoMessage('Authenticating...')
			const {access_token} = await login(values)
			const user = await getAuthUser(access_token)

        	dispatch('user-token', access_token)
			dispatch('user-data', user)
			setSuccessMessage('Successfully Authentication')
			setFormProcessing(false)
			history.push('/admin')
        }
        catch(error){
          dispatch('loaded-user')
          setFormProcessing(false)
          setFailureMessage(error.message)
          console.log(error)
        }
	}

	const form = useFormik({
		initialValues,
		validate,
	    onSubmit
	})

	const {handleSubmit} = form

	return <Frame title={"Login"} subtitle={"Sensor Dashboard"} >
		<form onSubmit={handleSubmit}>
			<FormMessageBox
				failureMessage={failureMessage}
				successMessage={successMessage}
				infoMessage={infoMessage}
			/>

			{requireUserFieldUsername && <TextField
				id="username"
				form={form}
				label="Username"
				autoComplete="username"
				autoFocus={true}
	        />}

			{requireUserFieldEmail && <TextField
				id="email"
				form={form}
				label="Email"
				autoComplete="email"
				autoFocus={requireUserFieldUsername?false:true}
	        />}

	        <TextField
				id="password"
				form={form}
				label="Password"
				type="password"
				autoComplete="current-password"
				margin="normal"
	        />
			<Button primary submit 
				loading={isFormProcessing} 
				disabled={isFormProcessing}
			>
				Login
			</Button>
			{false &&<Link to={'/register'}>Not a member yet? Register</Link>}
			<Link to={'/reset'}>Forgot your Password? Reset it now</Link>
		</form>
	</Frame>
}


export default withForm(Login)
