import React from 'react'
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

function ClusterGrowthChart ({data}){
	const clusterCount = Math.max(data.m_AmberStatus.m_CurrentClusterCount-1, 0)
	const clusterGrowthArray = data.m_Training.m_ClusterGrowth
	const clusterGrowth = clusterGrowthArray[clusterGrowthArray.length-1]

	const plots = clusterGrowthArray.map((clusterGrowth, i)=>{
		return {
			clusterCount:i+1,
			clusterGrowth,
		}
	})

	return <ResponsiveContainer width={"100%"} height={120}>
				<LineChart 
					data={plots}
				>
	      			<YAxis
	      				type="number"
	      				dataKey="clusterCount"
						hide={true}
	          			domain={["dataMin", "dataMax"]}
	      			/>
	      				
	      			<XAxis
						type="number" 
						dataKey="clusterGrowth"
						domain={["dataMin", "dataMax"]}
						hide={true}
					/>
				    <Line 
				    	dot={false}
				    	isAnimationActive={false}
				    	type="monotone" 
				    	dataKey="clusterCount" 
				    	stroke={'#fff'}
				    	strokeWidth={2} 
				    />
				</LineChart>
			</ResponsiveContainer>
}

export default ClusterGrowthChart