import {dispatch} from '../bus'

export const handleError = (reject, options={})=>{
	const {notify=true, loader=false} = options

	return (error) => {
		console.log(error, error.message)
		if(error&&error.message&&error.message==='jwt expired'){
			console.log('reload browser')
			return window.location.reload()
		}

		var e;
		if(error.response && error.response.body) e = error.response.body
		else if (error.response && error.response.text) e = error.response.text
		else if (error.message) e=error
		else if (typeof error === 'string') e=error
		
		if(typeof e === 'string') e = {message: e}
		if(notify) dispatch('error', e)
		if(loader) dispatch('global-loading',false)
	    reject(e)
	}
}

export const handleResolve = (resolve, options={})=>{
	const {vo, loader=false} = options
	
	return (res) => {
		if(loader) dispatch('global-loading',false)
		resolve(vo?vo(res):res.body)
	}
}