import React from 'react'
import IconButton from './IconButton'
import Confirm from '../controlled/Confirm'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({
	icon: {
		color:({color})=>color?theme.palette[color].main:theme.palette.icon.active,
		fontSize:({size})=>size
	}
}))

function DeleteIconButton ({color, size, ...props}) {
	const style = useStyle({color,size})

	return <Confirm {...props}>
		<IconButton ><DeleteIcon className={style.icon} /></IconButton>
	</Confirm>
}

export default DeleteIconButton