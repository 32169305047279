import React from 'react'
import Box from './Box'
import SceneHeader from './SceneHeader'
import JSONView from './JSONView'

function AttributesDetail ({record={}, title, back}){
	
	return <Box vert grow hidden>
		<SceneHeader title={title} back={back} />
		<Box vert scroll margin={20}>
			<JSONView data={record}  />
		</Box>
	</Box>
}


export default AttributesDetail