import React from 'react'
import AnalyticStack from './AnalyticStack'
import AnalyticCombo from './AnalyticCombo'

class Analytics extends React.Component {
	render(){
		if(this.props.expandedAnalytics) {
			return < AnalyticStack {...this.props} />
		}
		return <AnalyticCombo {...this.props} /> 
	}
	
	shouldComponentUpdate(nextProps){
		return (nextProps.data===this.props.data && nextProps.expandedAnalytics===this.props.expandedAnalytics && nextProps.sampleSize === this.props.sampleSize && nextProps.endDate === this.props.endDate)?false:true
	}

}

export default Analytics