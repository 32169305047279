import React from 'react';
import {on} from '../bus'

export const withError = (Comp) => {
  return class extends React.Component {

  	state = {
      error: false
    }

    componentDidMount(){
      this._e = on('error', (error)=>{
        this.setState({error:error})
      })
    }

    componentWillUnmount(){
      this._e()
    }

    onCloseError = () => {
      this.setState({error:false})
    }

    render() {
      return (<Comp {...this.props} {...this.state} onCloseError={this.onCloseError} />)
    }
  }
}

export default withError
