import React from 'react'
import { LineChart, XAxis, YAxis, ResponsiveContainer, Tooltip, ReferenceArea } from 'recharts';
import moment from 'moment'
import {debounce} from '../../base/utils/listeners'

class RecentSamplesChartScub extends React.Component {

	state={
		startZoomX:false,
		startPanX:false,
		endZoomX:false,
		dragging:false,
		panning:false,
		//startClientX:false,
	}
	
	dragStart = (e)=>{
		if(e===null||!e) return
		if(this.state.startZoomX && this.state.endZoomX && e.activeLabel>=this.state.startZoomX && e.activeLabel<=this.state.endZoomX){
			this.setState({
				panning:true,
				startPanX:e.activeLabel,
				//startClientX:e.chartX
			})
			return		
		}
		this.setState({ 
			startZoomX: e.activeLabel, 
			dragging:true, 
			//startClientX:e.chartX, 
			endZoomX:false
		})
	}

	dragMove = debounce((e)=>{
		//console.log('over', e)
		if(e===null||!e) return
		if(this.state.dragging!==false){
			this.setState({ endZoomX: e.activeLabel })
		}
		if(this.state.panning!==false){
			const move = e.activeLabel-this.state.startPanX
			const startZoomX = this.state.startZoomX+move
			const endZoomX = this.state.endZoomX+move
			if(startZoomX<0) return
			if(endZoomX>this.props.allTimesArray[this.props.allTimesArray.length-1].x) return
			this.setState({ 
				startZoomX, 
				endZoomX,
				startPanX: e.activeLabel
			})
		}
	},10)

	setZoomScrub = () =>{
		this.props.setZoomScrub({
			x1:Math.min(this.state.startZoomX, this.state.endZoomX),
			x2:Math.max(this.state.startZoomX, this.state.endZoomX)
		})
	}

	zoom = (e)=>{
		if(e===null||!e) {
			//console.log('zoom out of range')
			return
		}

		console.log('stopZoom', e)	
		if(this.state.dragging){
			if(Math.abs(e.activeLabel-this.state.startZoomX)>1){
				this.setZoomScrub()
			}else{
				this.props.setZoomScrub(false)
			}
		}
		else if(this.state.panning){
			this.setZoomScrub()
		}

		this.setState({
			dragging:false,
			panning:false,
			startPanX:false,
		})
	}

	componentDidUpdate(lastProps){
		if(this.props.zoomScrub !== lastProps.zoomScrub){
			this.setState({
				startZoomX: this.props.zoomScrub.x1,
				endZoomX: this.props.zoomScrub.x2,
			})
		}
	}

	render(){
		const {zoomed, allTimesArray, zoomScrub, theme} = this.props
		const {dragging, panning} = this.state
				
		console.log('render RecentSamplesChartScrubber')

		const plots = allTimesArray.map(({ms,x})=>{
			var plot = {
				ms,
				x
			}
			return plot
		})

		return <ResponsiveContainer width={"100%"} height={200}>
			<LineChart 
				data={plots}
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
				onMouseDown={this.dragStart}
	            onMouseMove={this.dragMove}
	            onMouseUp={this.zoom}
			>
	  			<YAxis
	  				type="number"
	  				ticks={[1.5]}
	      			domain={[-.5, 1.5]}
					stroke={'transparent'}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					ticks={[allTimesArray[allTimesArray.length-1].x]}
					allowDecimals={false}
					tickFormatter={(x)=>moment.utc(allTimesArray[x].ms).format('M/D/YYYY h:mm:ss a')}
					tickLine={false}
					domain={[allTimesArray[0].x, allTimesArray[allTimesArray.length-1].x]}
					stroke={'transparent'}
				/>
				{!zoomed && !this.state.dragging && <Tooltip />}
			    {this.state.endZoomX &&  <ReferenceArea 
			    	x1={this.state.startZoomX} 
			    	x2={this.state.endZoomX} 
			    	y1={-.5}
			    	y2={1.5}
			    	fill={theme.palette.secondary.dark} 
			    	strokeOpacity={.05} 
			    />}
			</LineChart>
		</ResponsiveContainer>
	}

	shouldComponentUpdate(nextProps, nextState){
		return (
			this.props.zoomed===nextProps.zoomed &&
			this.state.startZoomX===nextState.startZoomX &&
			this.state.endZoomX===nextState.endZoomX &&
			this.props.zoomScrub===nextProps.zoomScrub
		)?false:true
	}
}

export default RecentSamplesChartScub