import React from 'react'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid, ReferenceLine } from 'recharts';

class RecentSamplesAreaChart extends React.Component {

	render(){
		const {data, theme, selectedFeatures, allTimesArray} = this.props
				
		console.log('render RecentSamplesAreaChart')
		const rsArray = data.m_RecentSamples.m_Values

		const plots = allTimesArray.map(({ms,x})=>{

			var ys = selectedFeatures.map((sf)=>{
				return rsArray[sf][x]
			})
			
			const y1 = [Math.min(...ys), Math.max(...ys)]

			var plot = {
				ms,
				x,
				y1
			}
			
			return plot
		})

		//return false
		return <ResponsiveContainer width={"100%"} height={200}>
			<AreaChart 
				data={plots}
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
	  			<YAxis
	  				type="number"
	  				allowDataOverflow={true}
	  				dataKey="y1"
	  				ticks={[-.5,0,.5,1,1.5]}
	      			domain={[-.5, 1.5]}
					stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
			    <Area 
			    	key={`y1`}
				    isAnimationActive={false}
			    	dataKey={`y1`}
			    	stroke={theme.palette.primary.main}
			    	strokeWidth={1} 
			    />
			    <ReferenceLine y={1} stroke="red" />
			    <ReferenceLine y={0} stroke="red" />
			</AreaChart>
		</ResponsiveContainer>
	}

	shouldComponentUpdate(nextProps, nextState){
		//return false
		 return (
			nextProps.data===this.props.data && 
			nextProps.selectedFeatures===this.props.selectedFeatures
		)?false:true
	}
}

export default RecentSamplesAreaChart