import React from 'react'
import {getRecords} from '../sensors/services'
import { withRouter } from "react-router-dom";

export const withSensorsList = (Comp) => {
  
  class Cls extends React.Component {

  	constructor(pars){
  		super(pars)

  		this.state = {
	  		sensors: [],
	  		limit:25,
	  		skip:0,
	  		count:0,
	  		sort:'-lastModified'
	  	}

	  	this.getSensors({
	  		limit: this.state.limit,
	  		skip: this.state.skip,
	  		sort: this.state.sort
	  	})
  	}

  	getSensors = async ({limit, sort, skip})=>{
  		try{
  			const res = await getRecords({
  				limit, 
  				skip,
  				sort,
  				query:{...this.props.query}
  			})

  			const state = {
  				limit,
  				skip,
  				sort,
  				sensors: [...this.state.sensors, ...res.records],
  				count: res.count.filtered,
  			}
  			this.setState(state)
  		}catch(e){
  			console.error(e)
  		}
  	}

  	goToSensor=(id)=>{
  		this.props.history.push(`/admin/sensors/${id}`)
  	}

  	loadMoreSensors=()=>{
  		this.getSensors({
  			limit: this.state.limit,
  			sort: this.state.sort,
  			skip: this.state.skip+this.state.limit
  		})
  	}

    render() {
      return (<Comp {...this.props} {...this.state} 
      	loadMoreSensors={this.loadMoreSensors}
      	goToSensor={this.goToSensor} />)
    }
  }

  return withRouter(Cls)
}

export default withSensorsList
