import React from 'react'
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import MButton from '@material-ui/core/Button';

function Button ({history, color, style, size, onClick, error, className, children, primary, text, submit, active, disabled, to, value, name}) {
	
	const styles = makeStyles((theme)=>({
		label: {
		},
		danger: {
			boxShadow: 'none',
			backgroundColor: theme.palette.error.main,
			borderColor: theme.palette.error.main,
			color: theme.palette.common.white,
			'&:hover': {
		      backgroundColor: theme.palette.error.dark,
		      borderColor: theme.palette.error.dark,
		      boxShadow: 'none',
		    },
		    '&:active': {
		      backgroundColor: theme.palette.error.dark,
		      borderColor: theme.palette.error.dark,
		      boxShadow: 'none',
		    },
		    '&:focus': {
		      boxShadow: 'none',
		    },
		}
	}))()

	let props = {
		size,
		name,
		style,
		className,
		value,
		disabled,
		onClick,
		classes: {
			label: styles.label
		}
	}

	if(primary){
		props.variant = "contained"
		props.color = "primary"
			
	}
	else if (error){
		props.variant = "contained"
		props.color="secondary"
		props.classes.root = styles.danger
	}
	else if(text){
		props.variant = "text"
		props.color = "default"

	} else if(active){
		props.variant = "contained"
		props.color = "default"

	}else {
		props.variant = "outlined"
		props.color = "default"
	}
	if(color){
		props.color=color
	}

	if(to){
		props.onClick=()=>{
			history.push(to)
		}
	}

	if (submit) {
		props.type = "submit"
	}

	return <MButton {...props} >{children}</MButton>
}


export default withRouter(Button)