import React from 'react'
import withRecords from './withRecords'
import SceneGrid from '../../base/ui/SceneGrid'
import SceneTransitions from '../../base/ui/SceneTransitions'
import Detail from  './Detail'
import Box from '../../base/ui/Box'
import Button from '../../base/ui/Button'
import ActionForm from './ActionForm'
import {recordSetTypeDisplayName, recordsPath, recordTypeName, recordTypeDisplayName} from './config'
import {useParams } from "react-router-dom";
import SceneHeader from '../../base/ui/SceneHeader'
import Footer from '../../ui/Footer'
import DetailCarousel from './DetailCarousel'

function Records ({
	columns,
	facets,
	bulkActions,
	rowCount,
	totalRows,
	recordsLoading, 
	records, 
	sorter, 
	filters,
	dataGridId, 
	onItemsRendered,
	getPerm,
	clearFilters,
	isFiltered,
	user,
	history,
	carouselRecords=[],
	clearCarousel
}) {

	let params = useParams()

    var title = recordSetTypeDisplayName+ (recordsLoading!==false?"":(" ("+(rowCount===totalRows? rowCount :rowCount+' of '+totalRows)+")"))
    
    const position = params.id||params.action==='section'||carouselRecords.length?1:0

    const actions = getPerm(`+:${recordTypeName}:create`)
    	? <Button to={`${recordsPath}/action/create`}>{`Create a new ${recordTypeDisplayName}`}</Button>
    	: ''

	return <>
		{position===0 && <SceneHeader title={title} back={{to:'/admin'}} />}
		<SceneTransitions index={position}>
			{user && <SceneGrid
				uid={dataGridId}
				bulkActions={bulkActions}
				columns={columns}
				records={records}
				sorter={sorter}
				onItemsRendered={onItemsRendered}
				facetProps={{facets, filters, clearFilters, isFiltered, vert:true}}
			/>}
			<Box vert grow>
				{params.id && <Detail key={params.id} />}
				{carouselRecords.length>0 && <DetailCarousel close={clearCarousel} records={carouselRecords} />}
			</Box>
		</SceneTransitions>
		{params.action!=="section" && <Footer actions={actions} />}
		<ActionForm key={params.id} />
	</>
}


export default withRecords(Records)