import React from 'react'
import Confirm from '../controlled/Confirm'
import Button from './Button'

function ConfirmLeaveForm ({
	dirty, onClose, className, text="Cancel"
}) {
	if(!dirty) return <Button className={className} onClick={onClose}>{text}</Button>

	return <Confirm onConfirm={onClose} content="All Edits will be lost if you Leave now">
		<Button className={className}>{text}</Button>
	</Confirm>
}

export default ConfirmLeaveForm