import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	field: {
		margin:'10px 5px'
	}
})

function TextAreaFormField ({form, id, ...rest}){

	const styles = useStyles()

	const { errors, handleBlur, setValues, touched, handleChange, values} = form

	return <TextField 
		id={id}
		error={(touched[id] && errors[id])?true:false}
		helperText={errors[id]}
		value={values[id]}
		autoComplete={id}
		onChange={handleChange}
		onBlur={handleBlur}
		className={styles.field}
		multiline
		rows={4}
		{...rest}
    />
}


export default TextAreaFormField