import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import IconButton from '../../base/ui/IconButton'
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color'
import Section from './Section'
import ExpanderIcon from './ExpanderIcon'
import SelectAllNoneButton from './SelectAllNoneButton'
import Chip from '@material-ui/core/Chip';
import DialpadIcon from '@material-ui/icons/Dialpad';
import FeaturesHorzNav from './FeaturesHorzNav'

const useStyles = makeStyles(theme=>({
	text: {
		fontWeight:900,
		fontSize:'1.15rem',
		color: theme.type==="dark"?theme.palette.secondary.darkest:theme.palette.secondary.lightest
	},
	chip:{
		height:26,
		'&>span':{
			paddingRight:8,
			paddingLeft:8
		}
	},
	icon:{
		color: theme==='dark'? '#ffffff' : theme.palette.primary.main
	},
}))

function FeaturesSection ({
	children, 
	slim, 
	onChangeSlim, 
	onChangeAllFeatures,     
	selectedFeatures=[],
	features,
	featurePicker,
	setFeaturePicker
}){
	const styles = useStyles()
	console.log('render FeaturesSection')

	const controls = <Box hor center>
		<ExpanderIcon expanded={slim?false:true} onChange={onChangeSlim} />
		<SelectAllNoneButton total={features.length} selected={selectedFeatures.length} onChange={onChangeAllFeatures} />
		<IconButton onClick={()=>setFeaturePicker(!featurePicker)} ><DialpadIcon className={styles.icon} /></IconButton>
	</Box>

	const count = <Chip color="secondary" className={styles.chip} label={<Text className={styles.text}>{selectedFeatures.length}</Text>} />

	return <Section start={controls} title="Features"  post={count} >
		{children}
	</Section>
}

export default FeaturesSection