import React from 'react'
import { LineChart, Line, XAxis, YAxis } from 'recharts';
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import withDims from '../../base/hocs/withDims'
import { useTheme, makeStyles} from '@material-ui/core/styles';
import ChartTipTimeBase from './ChartTipTimeBase'

const useStyles = makeStyles(theme=>({
	colorBar:{
		height:5,
		width:26,
		//marginLeft:-13,
		marginBottom:3

	},
	text:{
		//marginLeft:-13, 
		width:26,
	},
	feature:{
		fontSize:'.7em',
		color: theme.palette.text.disabled
	},
	label:{
		fontSize:'.5em',
		color: theme.palette.text.disabled
	}
}))


function RecentSampleChartTip (props){
	const theme = useTheme()
	const styles = useStyles()
	const {label, allTimesArray, colors, selectedFeatures, dimensions, rsArray} = props
	if(!label) return null
	
	const breakPoint = 50
	const bigData = selectedFeatures.length>breakPoint?true:false

	const plots = selectedFeatures.map((fi)=>({
		y: rsArray[fi][label],
		color: colors[fi],
		x: fi
	}))

	return <ChartTipTimeBase {...props}>
		<Box vert margin={'0 10px 0 10px'} width={bigData ? (dimensions.width-200)/2 : plots.length*26} height={bigData?200:75}>
			<LineChart 
				data={plots}
				margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
				width={bigData ? (dimensions.width-200)/2 : plots.length*26} 
				height={bigData?200:50}
			>
				<YAxis
					type={'number'}
					dataKey={'y'}
					hide={bigData?false:true}
					tickFormatter={(y)=>(y*100).toFixed(0)+'%'}
					stroke={theme.palette.text.disabled}
				/>
				<XAxis 
					type={'number'}
					dataKey={'x'} 
					tickCount={plots.length}
					hide={bigData?false:true}
					domain={[0,plots.length-1]}
					tickFormatter={(x)=>x+1}
	  				stroke={theme.palette.text.disabled}
				/>
				<Line 
					dot={false} 
					dataKey={'y'} 
					isAnimationActive={false}
					strokeWidth={2}
					stroke={theme.palette.primary.main}
				/>
			</LineChart>
		
			<Box hor between>
			{!bigData && plots.map((plot,i)=>(
				<Box key={i} vert centerH width={0}>
					<Box vert style={{background:plot.color}} center className={styles.colorBar}></Box>
					<Box hor center className={styles.text}>
						<Text center className={styles.label} >#</Text>
						<Text center className={styles.feature} >{plot.x+1}</Text>
					</Box>
				</Box>
			))}
			</Box>
		</Box>
	</ChartTipTimeBase>
}

export default withDims(RecentSampleChartTip)