import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

function SceneTransitions ({
	children,
	index=0,
}) {
	const style = makeStyles((theme)=>({
		mask: {
			display: 'flex',
			flexDirection: 'row',
			overflow:'hidden',
			flex: 1,
			transition: 'transform .4s',
			width: children.length+'00%',
			'&>*': {
				flex: 1
			}
		}
	}))()

    const position = (index*(100/children.length)*-1)

	return <div className={style.mask} style={{transform:'translateX('+position+'%)'}}>
		{children}
	</div>
}


export default SceneTransitions