import React from 'react'
import AnomalyWarningLevelChart from './AnomalyWarningLevelChart'
import AnomalyIndexesChart from './AnomalyIndexesChart'
import AnomalyDetectionsChart from './AnomalyDetectionsChart'
import AnomalyHistoryChart from './AnomalyHistoryChart'


function AnomalyStack (props){
	return <>
		<AnomalyWarningLevelChart 
			{...props}
		/>
		<AnomalyHistoryChart 
			{...props}
		/>
		<AnomalyDetectionsChart 
			{...props}
		/>
		<AnomalyIndexesChart 
			{...props}
		/>
	</>
}

export default AnomalyStack