import React from 'react';
import {on} from '../base/bus'
import {showBackgroundImage} from './store'

export const withStyleChange = (Comp) => {
  return class extends React.Component {

  	state = {
      showBackgroundImage: showBackgroundImage()
    }

    componentDidMount(){
      this._e = on('show-background-image-change', ()=>{
        this.setState({showBackgroundImage:showBackgroundImage()})
      })
    }

    componentWillUnmount(){
      this._e()
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }
}

export default withStyleChange
