import React from 'react';
import {on} from '../bus'

export const withSuccess = (Comp) => {
  return class extends React.Component {

  	state = {
      success: false
    }

    componentDidMount(){
      this._e = on('notify-success', (success)=>{
        this.setState({success})
      })
    }

    componentWillUnmount(){
      this._e()
    }

    onCloseSuccess = () => {
      this.setState({success:false})
    }

    render() {
      return (<Comp {...this.props} {...this.state} onCloseSuccess={this.onCloseSuccess} />)
    }
  }
}

export default withSuccess
