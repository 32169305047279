import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '../../base/ui/Box'

const useStyles = makeStyles(theme=>({
	root:{
		height:32,
		width:32,
		borderRadius:16,
		background: ({value})=>{
			if(value===0){
				return 'green'
			}else if(value===1){
				return 'orange'
			}else if(value===2){
				return 'red'
			}else{
				return ''
			}
		}
	}
}))

function Warning ({value}){
	const styles = useStyles({value})
	return <Box className={styles.root}></Box>
}

export default Warning