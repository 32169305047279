import React from 'react'
import { useTheme } from '@material-ui/core/styles';
import { ScatterChart, Scatter, XAxis, YAxis, Label, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';
import ThresholdChartTip from './ThresholdChartTip'

function ThresholdChart ({data}){
	const theme = useTheme()

	const plotYArray = data.m_Training.m_GeometricPlotY
	const plotXArray = data.m_Training.m_GeometricPlotX

	const plots = plotXArray.map((plotX, i)=>{
		return {
			plotX,
			plotY:plotYArray[i],
		}
	})

	return <ScatterChart 
			width={400} 
			height={400}
			margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
		>
				<YAxis
					type="number"
					tickCount={10}
					tickFormatter={(v)=>v.toFixed(2)}
					dataKey="plotY"
					allowDecimals={true}
					tickLine={false}
					position="left"
	  				domain={[0, 'dataMax']}
	  				stroke={theme.palette.text.disabled}
				>
					<Label      			
					value="% Total Inferences"
					position="left"      				
					angle={-90}
					style={{ textAnchor: "middle"}}
					fill={theme.palette.text.label}
		        />
				</YAxis>
				<XAxis
				type="number" 
				dataKey="plotX"
				position="bottom"
				allowDecimals={false}
				tickLine={false}
				ticksCount={10}
				domain={[0, 1000]}
				stroke={theme.palette.text.disabled}
			>
				<Label
					value="RI"
					position="bottom"
					style={{ textAnchor: "middle" }}
					fill={theme.palette.text.label}
		        />
			</XAxis>
			<CartesianGrid 
				stroke={theme.palette.text.disabled}
			/>
			<Tooltip isAnimationActive={false}  content={<ThresholdChartTip />}/>
		    <Scatter 
		    	data={plots}
		    	fill={theme.palette.primary.main}
		    />
		    <ReferenceLine x={data.m_AnomalyThreshold} stroke="red" />
		</ScatterChart>
}

export default ThresholdChart