import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import SideBar from './SideBar'
import Box from './Box'
import Facet from './Facet'
import Link from './Link'

const useStyles = makeStyles({
	sidebar: {
		margin: '10px 10px 0'
	},
	clear: {
		flexDirection:'column',
		margin: '0 20px -10px'
	},
	link: {
		display: 'flex',
    	justifyContent: 'flex-end'    
	}
})

function Facets ({
	filters={},
	facets=[],
	width,
	className='',
	vert,
	hor,
	clearFilters,
	isFiltered
}) {

	const style = useStyles()

	if(!hor) {
		//console.log(filters)
		return (<SideBar width={width}>
			{isFiltered() && <Box className={style.clear}>
				<Link onClick={clearFilters} className={style.link}>Clear All Filters</Link>
			</Box>}
			<Box vert childMargin={5} className={style.sidebar}>
				{facets.map((facet,i)=>{
					return <Facet {...facet} key={facet.field+i} value={filters[facet.field]} />
				})}
			</Box>
		</SideBar>)
	}

	return (<Box hor className={style.topSidebar}>
		{facets.map((facet,i)=>{
			return <Facet {...facet} key={facet.field+i} value={filters[facet.field]} />
		})}
	</Box>)
}


export default Facets