import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import colormap from 'colormap'


export const random = ()=>{
	const randomColor = Math.floor(Math.random()*16777215).toString(16)
  	return "#" + randomColor
}

export const materialRainbow = (count)=>{
	const colors = [red,orange,yellow,green,blue,purple]
	var weights = [500,900,200,700,300,800]
	const shades = Math.ceil(count/colors.length)

	var clrs=[]
	var i
	for(i=0; i<shades; i++){

		if(i>=weights.length){
			weights.push(800)
		}

		colors.forEach((c)=>{
			clrs.push(c[weights[i]])
		})
	}
	return clrs
}

export const getPlasmaMap = (colors=1000) => {
	return colormap({
	    colormap: 'plasma',
	    nshades: colors,
	    format: 'hex',
	    alpha: 1
	})
}