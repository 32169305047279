import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import withBarLoading from '../hocs/withBarLoading'
import PlasmaBar from './PlasmaBar'

const useStyles = makeStyles((theme) => ({
  wrap: {
  	position:'absolute',
  	top:0,
  	left:0,
    right:0,
  	zIndex:10000,
  	height:16,
  	width:'100%',
  	overflow:'hidden',
  	transition: 'width .3s'
  },
  bar:{
  	display:'flex', 
    flexDirection:'row',
  },
  swatch: {
    flex:1 ,
    height:24,
  }
}));

function Loader({
	loading,
	percent
}){
	const classes = useStyles()
	if(loading===false) return false

	return <div className={classes.wrap} style={{width:`${percent}%`}}>
		<PlasmaBar />
	</div>
}

export default withBarLoading(Loader)