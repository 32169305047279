import React from 'react'
import withError from '../hocs/withError'
import Notify from './Notify'


function ErrorsToast({error, onCloseError}) {

  return (<Notify 
        open={error?true:false} 
        message={error.message||error.error} 
        onClose={onCloseError} 
        type='error' 
      />)
}

export default withError(ErrorsToast);
