import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Text from './Text'

const useStyle = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
}))
function SwitchField ({label, className='', id, test="SwitchField", disabled, hint, error, value='', style={}, onChange}) {
	const classes = useStyle()
	return (<>
		<FormControlLabel style={style} className={classes.formControl+' '+className}
			label={<Text>{label}</Text>}
			control={<Switch
		      id={id}
		      color={'primary'}
		      name={id}
		      test={test}
		      checked={value}
		      onChange={onChange}
		      disabled={disabled}
		    />}
		/>
	    {error && <FormHelperText>{error}</FormHelperText>}
	   	{hint && <FormHelperText>{hint}</FormHelperText>}
	</>)
}
export default SwitchField