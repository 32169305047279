import React from 'react'

import { VariableSizeGrid as Grid } from 'react-window'
import Text from './Text'
import Box from './Box'
import Button from './Button'
import IconButton from './IconButton'
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'
import {on} from '../bus'
import { withTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import BulkActionsMenu from './BulkActionsMenu'
import './DataGrid.css'

class DataGrid extends React.Component {

	gridRef = React.createRef()

	state = {
		selected:[],
		selectAll: false,
	}

	static getDerivedStateFromProps(props, state){
		if(!state.selected || state.selected.length===0) return null

		const selected = props.records.filter((rec)=>{
			return state.selected.includes(rec.id)
		})

      	return {selected:selected.map((s)=>s.id)}
    }

	componentDidMount(){
		this._e = on(`datagrid-${this.props.uid}-scroll-to-item`, (index)=>{
			this.gridRef.current.scrollToItem(index)
		})
	}

	componentWillUnmount(){
      this._e()
    }

	render(){
		const {
			height, 
			headers=true, 
			rowHeight=64, 
			width, 
			style, 
			records=[], 
			columns=[], 
			bulkActions=[],
			sorter,
			onItemsRendered
		} = this.props

		var gridHeight = height-64
		if (!headers) gridHeight=gridHeight+64

		const bcols = bulkActions.length
			
			? [{
				field:'id',
				width:120,
				header: ()=>{
					return <Box hor centerVert style={{height:60}}>
						<Switch id={'col-header-select-all'}
					      color={'primary'}
					      name={'col-header-select-all'}
					      checked={this.state.selectAll}
					      onChange={(e,v)=>{
					      	const selectAll = v
					      	const selected = v 
					      		? this.props.records.map((r)=>r.id)
					      		: []
					      	this.setState({selectAll,selected})
					      }}
					    />

					    <BulkActionsMenu
					    	selected={this.state.selected} 
					    	bulkActions={bulkActions}
					    	records={records}
					    />
					    
					</Box>
				},
				cell: ({record})=>(
					<Switch id={record.id}
				      color={'primary'}
				      name={record.id}
				      checked={this.state.selected.includes(record.id)?true:false}
				      onChange={(e,v)=>{
				      	const selected = v 
				      		? [...this.state.selected, record.id]
				      		: this.state.selected.filter((vv)=>record.id===vv?false:true)
				      	const selectAll = records.length === selected.length
				      	this.setState({selected, selectAll})
				      }}
				    />
				)
			  }, ...columns]

			: columns

		const cols = bcols.map((col)=>{
			return {
				...col,
				cell: col.cell || (({value})=>(<Text className='ui-dg-cell-content'>{value}</Text>))
			}
		})

		

		const Cell = ({ columnIndex, rowIndex, style }) => (
		  <div className='ui-dg-cell' style={{...style, ...(!headers && rowIndex===0)?{border:'none'}:{borderTop:`1px solid ${this.props.theme.palette.border.dataGridRow}`}}}>
		  	{cols[columnIndex].cell && cols[columnIndex].cell({
		  		record: records[rowIndex], 
		  		value: records[rowIndex][cols[columnIndex].field],
		  		index: rowIndex,
		  		column: cols[columnIndex]
		  	})}
		  </div>
		)

		return <div className="ui-dg" style={style}>
			{headers && <div className="ui-dg-columns">
				{cols.map((col, i)=>{
					var sortBy = {field:col.field, order:'asc'}
					if(sorter && col.field===sorter.field && sorter.order==='asc') sortBy.order='desc'

					const header = col.header
						? col.header(col)
						: (col.name
							? <Button disabled={!col.sorter} onClick={()=>{col.sorter(sortBy)}} text>
									{col.name}
									{sorter && sorter.field===col.field && sorter.order==='asc' && <AscIcon fontSize="small" />}
									{sorter && sorter.field===col.field && sorter.order==='desc' && <DescIcon fontSize="small" />}
								</Button>
							:false)

					return <div className="ui-dg-column" key={i} style={{width: col.width+'px'}}>
						{header}
					</div>
				})}
			</div>}
			<Grid
				ref={this.gridRef}
			    columnCount={cols.length}
			    onItemsRendered={onItemsRendered}
			    columnWidth={index => cols[index].width}
			    height={gridHeight}
			    rowCount={records.length}
			    rowHeight={index => rowHeight}
			    width={width}
			    className="ui-dg-grid"
			>
			    {Cell}
			</Grid>
		</div>
	}
}

export default withTheme(DataGrid)