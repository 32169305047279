import {on, dispatch} from '../../base/bus'

export default ({recordTypeName, recordSetTypeName, pager, filters, initialSorter}) => {

  var _record
  var _recordLoading = false
  var _recordUpdating = false
  var _recordSetLoading = false
  var _recordSet = {
    records: [],
    sorter: initialSorter,
    filters: filters,
    pager: pager,
    rowCount: undefined,
    totalRows: undefined
  }

  on(`loading-${recordTypeName}`, ()=>{
  	_recordLoading=true
  })

  on(`updating-${recordTypeName}`, ()=>{
    _recordUpdating=true
  })

  on(`loaded-${recordTypeName}`, (record)=>{
    _record = record
    _recordLoading = false
    _recordUpdating = false
  })

  on(`updated-${recordTypeName}`, (record)=>{
    _record = record
    _recordLoading = false
    _recordUpdating = false
  })

  on(`deleted-${recordTypeName}`, (id)=>{
    _recordLoading = false
    _recordUpdating = false
    if (_record && _record.id===id) {
      _record = undefined 
    }
    _recordSet.records = _recordSet.records.filter((rec)=>{
      return rec.id!==id
    })
    dispatch(`loaded-${recordSetTypeName}`, _recordSet)
  })

  on(`loading-${recordSetTypeName}`, (recordSet={})=>{
    _recordSet = {..._recordSet,...recordSet}
    _recordSetLoading=true
  })

  on(`loaded-${recordSetTypeName}`, (recordSet={})=>{
    _recordSet = {..._recordSet,...recordSet}
    _recordSetLoading = false
  })

  return {
    getRecord : () => {
      return _record
    },

    isRecordLoading : () => {
      return _recordLoading
    },

    isRecordUpdating : () => {
      return _recordUpdating
    },

    getRecordSet : () => {
      return _recordSet
    },

    isRecordSetLoading : () => {
      return _recordSetLoading
    },
  }
}
