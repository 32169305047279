import React from 'react'
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import Link from '../../base/ui/Link'
import { useTheme } from '@material-ui/core/styles'
import withUsersList from './withUsersList'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageAvatar from '../../base/ui/ImageAvatar'

function UserMiniList ({records=[], title='Users', limit, count, skip, loadMoreRecords, goToRecord}){
	const theme = useTheme()
	
	return <Box vert hidden grow>
		<Text center heading={3}>{title}</Text>
		{records.length>0 && <Box scroll vert>
			<List component="nav">
		        {records.map((r)=>{
		       
		        return <ListItem button onClick={()=>goToRecord(r.id)}>
		          <ListItemIcon>
					<ImageAvatar size={45} src={r.avatar} />
		          </ListItemIcon>
		          <ListItemText 
		          	secondary={<Text style={{color:theme.palette.text.muted}} size={.9}>{r.email}</Text>} 
		          	primary={<Text size={1.1}>{r.name}</Text>} 
		          />
		        </ListItem>})}
		    </List>
		    {(count>(skip+limit)) && <Link onClick={()=>loadMoreRecords()}>See More</Link>}
		</Box>}
	</Box>
}

export default withUsersList(UserMiniList)