import React from 'react'
import ListSwatch from './ListSwatch'

function Learning ({data, width=300}){

	const Learning = [{
			label:"Buffer Length",
			value: data.m_StreamingParameters.m_SamplesToBufferForAutotuning
		},{
			label:"Numerator",
			value: data.m_StreamingParameters.m_GraduationAtRateNumerator
		},{
			label:"Demominator",
			value: data.m_StreamingParameters.m_GraduationAtRateDenominator
		},{
			label:"Max Clusters Allowed",
			value: data.m_StreamingParameters.m_GraduateAtMaxClusters
		},{
			label:"Max Samples in Learning",
			value: data.m_StreamingParameters.m_GraduateAtMaxSamples
		}
	]	

	return <ListSwatch title="Learning" list={Learning} width={width} />
}

export default Learning