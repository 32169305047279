import React, {useCallback} from 'react';
import withImagePreview from '../hocs/withImagePreview'
import {useDropzone} from 'react-dropzone'
import  Text from './Text'
import  Box from './Box'
import ImageAvatar from './ImageAvatar';

const ImageInput = ({form, createPreviewUrl, onChange=(()=>{}), previewUrl, label, Icon, id}) => {

  const onDrop = useCallback(acceptedFiles => {
    createPreviewUrl(acceptedFiles[0])
    form.setFieldValue(id,acceptedFiles[0])
  }, [id, createPreviewUrl, form])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  var value = form.values[id]

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} name={id} />
      <Box vert centerHor>
        <ImageAvatar size={88} src={value||previewUrl ? previewUrl || value : false} Icon={Icon} />
        <Text style={{marginTop:10}}>Click or drag Image to this area</Text>
        {isDragActive && <p>Drop the files here ...</p>}
      </Box>
    </div>
  )
}

export default withImagePreview(ImageInput)
