import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MDialog from '@material-ui/core/Dialog';

function Dialog ({ 
	open=false, 
	actions,
	title,
	children,
	handleClose,
	maxWidth='md'
}) {

	return (<MDialog
	      	open={open}
	      	onClose={handleClose}
	      	maxWidth={maxWidth}
	    >
	    	{title && <DialogTitle>{title}</DialogTitle>}
	    	{children && <DialogContent>
		    	{children}
		    </DialogContent>}
			{actions && <DialogActions>
				{actions}  
			</DialogActions>}
	</MDialog>)
}

export default Dialog