import React from 'react';
import {getToken} from './store'
import {dispatch} from '../../base/bus'
import {getAuthUser, refresh} from './services'
import {withUser} from './withUser'
import jwt from 'jsonwebtoken'
import {secret, authRefreshTokenUnderSeconds} from '../../config'

export const withAuth = (Comp) => {
  class Cls extends React.Component {
    
    componentDidMount(){
      (async ()=>{
        try{
          const token = getToken()
          console.log('access token', token)
          if(token){
            const user = await getAuthUser(token)
            dispatch('user-data', user)
            this.startClock(token)
          }
          else{
            const userToken = await refresh()
            console.log('refresh token', userToken.access_token)
            const user2 = await getAuthUser(userToken.access_token)
            dispatch('user-token', userToken.access_token)
            dispatch('user-data', user2)
            this.startClock(userToken.access_token)
          }          
        }catch(err){
          console.error(err)
          dispatch('auth-logout')
        }
      })()
    }

    startClock = (token)=>{
      const decoded = jwt.verify(token, secret) 
      const secondsLeft = decoded.exp-decoded.iat
      console.log('seconds left',secondsLeft)
      console.log('decoded', decoded)
      const refreshIn = secondsLeft-authRefreshTokenUnderSeconds
      console.log('refresh in', refreshIn)
      
      setTimeout(()=>{
        console.log('requesting new tokens')
        refresh()
          .then(({access_token})=>{
            getAuthUser(access_token).then((user)=>{
              dispatch('user-token', access_token)
              dispatch('user-data', user)
              this.startClock(access_token)
            })
            .catch((e=>{
              dispatch('error', {message:e.message})
              dispatch('auth-logout')
            }))
          })
          .catch((e=>{
            dispatch('error', {message:e.message})
            dispatch('auth-logout')
          }))

      }, refreshIn*1000)
    }

    render() {
      return (<Comp {...this.props} {...this.state}
      />)
    }
  }
  return withUser(Cls)
}
export default withAuth
