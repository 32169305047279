import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

function Box ({style={}, className, children, end,
	vert, hor, grow, center, wrap, between, around, test='Box',
	centerVert, centerHor, childMargin, lighter, darker, relative, scroll, hidden,
	margin, padding, width, height, onClick
}) {
	
	var root = {
		display: 'flex'
	}

	if(vert) root['flex-direction'] = 'column';
	if(hor) root['flex-direction'] = 'row';
	if(grow) root['flex'] = 1;
	if(wrap) root['flex-wrap'] = 'wrap';
	if(around) root['justify-content'] = 'space-around';
	if(between) root['justify-content'] = 'space-between';
	if(vert && end) root['justify-content'] = 'flex-end';
	if(hor && end) root['justify-content'] = 'flex-end';

	if(vert && centerHor) root['align-items'] = 'center'; 
	if(hor && centerHor) root['justify-content'] = 'center';

	if(vert && centerVert) root['justify-content'] = 'center'; 
	if(hor && centerVert) root['align-items'] = 'center';

	if(center){
		root['justify-content'] = 'center'; 
		root['align-items'] = 'center';
	} 

	if(lighter){
		root['background-color'] = `rgba(255,255,255, ${lighter})`
	}

	if(darker){
		root['background-color'] = `rgba(0,0,0, ${darker})`
	}

	if(relative){
		root['position'] = 'relative'
	}

	if(hidden) {
		root['overflow'] = 'hidden'
	}
	else if(scroll){
		root['overflow'] = 'auto'
	}

	if(childMargin){
		root['&>*'] = {
	  		margin: childMargin,
	  	}
	}

	if(padding){
		root['padding'] = padding
	}

	if(margin){
		root['margin'] = margin
	}

	if(height){
		root['height'] = height
	}

	if(width){
		root['width'] = width
	}

	var classes = makeStyles({root})()

	if(className) classes.root = classes.root+' '+className

	return <div onClick={onClick} style={style} test={test} className={classes.root}> 
        {children}
      </div>
}

export default Box