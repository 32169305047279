if (process.env.NODE_ENV === 'production') {
	console.log = ()=>{}
  	console.info = ()=>{}
  	console.debug = ()=>{}
  	console.warn = ()=>{}
  	console.error = ()=>{}
}

const config = window._webapp_config

export const authServer = config.authServer
export const secret = config.secret
export const requireUserFieldUsername = config.requireUserFieldUsername
export const requireUserFieldEmail = config.requireUserFieldEmail
export const authRefreshTokenUnderSeconds = config.authRefreshTokenUnderSeconds

export const validators = config.validators(config)
export const models = config.models