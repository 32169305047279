import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'sensor'
export const recordSetTypeName = 'sensors'
export const recordTypeDisplayName = 'Sensor'
export const recordSetTypeDisplayName = 'Sensors'
export const clientEndpoint = 'sensor'
export const recordDisplayField = 'id'
export const recordsPath = '/admin/sensors'
export const primaryAttribute = 'id'

export const recordsPerPage = 100

export const initialSorter = {
	field:'state',
	order:'desc'
}

export const initialFilters = {
    'id':'',
    'state':'',
    'label':'',
    'tenant':null,
    'warning':'',
    'samples':'',
    'lastModified':null
}

export const fields = inferFields({
	id:{
	  	type: 'string',
	},
	state:{
		type: 'string',
		options: [{label:"Monitoring", value:"Monitoring"},{label:"Buffering",value:"Buffering"},{label:"Autotuning", value:"Autotuning"},{label:"Learning",value:"Learning"}],
		default:'Monitoring'
	},
	tenant:{
		type: 'string',
		ref:'tenant'
	},
	label:{
		type: 'string',
	},
	samples:{
		type: 'number',
		default: 0
	},
	warning:{
		type: 'number',
		options: [{label:"Green",value:0}, {label:"Amber",value:1}, {label:"Red",value:2}],
		default:0
	},
	lastModified: {
		type: 'date'
	}
})

//console.log(fields)