import React from 'react'
import withForm from '../../base/hocs/withForm'
import Frame from './Frame'
import Button from '../../base/ui/Button'
import Link from '../../base/ui/Link'
import {register} from './services'
import TextField from '../../base/ui/TextFormField'
import {required, password, equals} from '../../base/utils/validate'
import {useFormik} from 'formik'
import FormMessageBox from '../../base/ui/FormMessageBox'
import {requireUserFieldUsername, requireUserFieldEmail, validators} from '../../config'
import FormErrorList from '../../base/ui/FormErrorList'

function Register ({
	history,
	setFailureMessage, 
	setInfoMessage, 
	infoMessage, 
	failureMessage, 
	successMessage,
	setSuccessMessage,
	isFormProcessing, 
	setFormProcessing
}) {

	var initialValues = {
		password:'',
		confirmPassword:''
	}
	if(requireUserFieldEmail) initialValues.email=''
	if(requireUserFieldUsername) initialValues.username=''

	const validate = async (values) => {
        var errors = {}

        const passErrs = await validators.password({value:values.password})
        if(passErrs.length){
        	errors.password=<FormErrorList items={passErrs} />
        }

    	const emalErrs = await validators.email({value:values.email})
        if(emalErrs.length){
        	errors.email=<FormErrorList items={emalErrs} />
        }

        if(requireUserFieldUsername){
        	const nameErrs = await validators.username({value:values.username})
	        if(nameErrs.length){
	        	errors.username=<FormErrorList items={nameErrs} />
	        }
        }

        if(await required(values.confirmPassword)) {
          errors.confirmPassword='Please input your Password again'
        }
        else if(await equals(values.password, values.confirmPassword)){
          errors.confirmPassword='Passwords must match' 
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return 
        }
    }

    const onSubmit = async ({email,username,password}, actions) => {
		try{
			setFormProcessing(true)
			setInfoMessage('Requesting Registration...')
			const {record} = await register({email, username, password})
			setSuccessMessage(`Check Email ${record.username||record.email} for Confirmation Code`)
			setFormProcessing(false)
			history.push('/login')
        }
        catch(error){
          setFormProcessing(false)
          setFailureMessage(error.message)
        }
	}

	const form = useFormik({
		initialValues,
		validate,
	    onSubmit
	})

	const {handleSubmit, touched, errors} = form

	return <Frame title={"Register"} subtitle={"Sensor Dashboard"} >
		<form onSubmit={handleSubmit}>
			<FormMessageBox
				failureMessage={failureMessage}
				successMessage={successMessage}
				infoMessage={infoMessage}
			/>

			{requireUserFieldUsername && <TextField
				id="username"
				form={form}
				label="Username"
				autoComplete="username"
				autoFocus={true}
	        />}

			<TextField
				id="email"
				form={form}
				label="Email"
				autoComplete="email"
				autoFocus={requireUserFieldUsername?false:true}
	        />

	        <TextField
				id="password"
				form={form}
				label="Password"
				type="password"
				autoComplete="current-password"
				margin="normal"
	        />

	        {touched.password && !errors.password && <TextField
				id="confirmPassword"
				form={form}
				label="ConfirmPassword"
				type="password"
				margin="normal"
	        />}

			<Button primary submit 
				loading={isFormProcessing} 
				disabled={isFormProcessing}
			>
				Register
			</Button>
			<Link to={'/login'}>Already a member? Login</Link>
		</form>
	</Frame>
}


export default withForm(Register)
