import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListSwatch from './ListSwatch'
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, ReferenceLine, CartesianGrid, Tooltip } from 'recharts';
import ClusterGrowthChartTip from './ClusterGrowthChartTip'

const useStyles = makeStyles(theme=>({
	heading:{
		marginBottom:15
	},
	section:{
		borderBottom:`1px solid ${theme.palette.text.disabled}`,
		paddingBottom:15,
		marginBottom:15
	}
}))

function ClusterGrowthChart ({data}){
	const styles = useStyles()
	const theme = useTheme()

	const clusterCount = Math.max(data.m_AmberStatus.m_CurrentClusterCount-1, 0)
	const clusterGrowthArray = data.m_Training.m_ClusterGrowth
	const clusterGrowth = clusterGrowthArray[clusterGrowthArray.length-1]

	const plots = clusterGrowthArray.map((clusterGrowth, i)=>{
		return {
			clusterCount:i+1,
			clusterGrowth,
		}
	})

	return <Box vert grow>
		<Text center heading={2}>Cluster Growth</Text>
		<Text center heading={5} className={styles.heading}>{`Cluster Count ${clusterCount}`}</Text>
		<Box hor wrap centerHor className={styles.section}>
			<ResponsiveContainer width={"100%"} height={400}>
				<LineChart 
					data={plots}
					margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
				>
	      			<YAxis
	      				type="number"
	      				tickCount={12}
	      				dataKey="clusterCount"
	      				allowDecimals={false}
						tickLine={false}
	      				position="left"
	          			domain={["dataMin", "dataMax"]}
	          			stroke={theme.palette.text.disabled}
	      			>
	      				<Label      			
							value="Count"
							position="left"      				
							angle={-90}
							style={{ textAnchor: "middle"}}
							fill={theme.palette.text.label}
				        />
	      			</YAxis>
	      			<XAxis
						type="number" 
						dataKey="clusterGrowth"
						position="bottom"
						tickCount={20}
						allowDecimals={false}
						tickLine={false}
						domain={["dataMin", "dataMax"]}
						stroke={theme.palette.text.disabled}
					>
						<Label
							value="Growth"
							position="bottom"
							style={{ textAnchor: "middle" }}
							fill={theme.palette.text.label}
				        />
					</XAxis>
					<CartesianGrid 
						stroke={theme.palette.text.disabled}
					/>
					<Tooltip isAnimationActive={false} content={<ClusterGrowthChartTip />} />
					<ReferenceLine x={data.m_StreamingParameters.m_SamplesToBufferForAutotuning} stroke="red" />
				    <Line 
				    	dot={false}
				    	isAnimationActive={false}
				    	type="monotone" 
				    	dataKey="clusterCount" 
				    	stroke={theme.palette.primary.main}
				    	strokeWidth={2} 
				    />
				</LineChart>
			</ResponsiveContainer>
		</Box>
	</Box>
}

export default ClusterGrowthChart