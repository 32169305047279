import {useState, useEffect} from 'react'
import {on, dispatch} from '../bus'

function useEventStore (event, getter){

	const [value, setter] = useState(getter())

	useEffect(()=>{
		setter(getter())
		return on(event, ()=>{
			setter(getter())
		})
	}, [event, getter, setter])

	const dispatcher = (val)=>{
		dispatch(event, val)
	}

	return [value, dispatcher]
}

export default useEventStore