import React from 'react'
import { withTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, ReferenceArea } from 'recharts';
import RecentSampleChartTip from './RecentSampleChartTip'
import {debounce} from '../../base/utils/listeners'

class RecentSamplesChartZoomerOverMap extends React.Component {
	
	state={
		startZoomX:false,
		endZoomX:false,
		dragging:false,
		startClientX:false,
	}

	activeLabel=null

	dragStart = (e)=>{
		if(e===null||!e) return
		this.props.populateExtendedFeatures(null)
		this.setState({ 
			startZoomX: e.activeLabel, 
			dragging:true, 
			startClientX:e.chartX, 
		})
	}

	dragMove = debounce((e)=>{
		//console.log('over', this.state)
		if(e===null||!e) return
		if(this.state.dragging!==false){
			this.setState({ endZoomX: e.activeLabel })
		}
	},10)

	setZoom = () =>{
		this.props.setZoom({
			x1:Math.min(this.state.startZoomX, this.state.endZoomX),
			x2:Math.max(this.state.startZoomX, this.state.endZoomX)
		})
	}

	zoom = (e)=>{
		if(e===null||!e) return
		if(this.state.dragging){
			if(Math.abs(e.chartX-this.state.startClientX)>5){
				this.setZoom()
			}
		}
		this.setState({
			startZoomX:false,
			endZoomX:false,
			dragging:false,
			startClientX:false,
		})
	}

	openTip = debounce((p)=>{
		if(p===null) return
		if(p.activeLabel !== this.activeLabel){
			//console.log(2222,p, this.activeLabel)
			this.activeLabel=p.activeLabel
			this.props.populateExtendedFeatures(p.activeLabel)
		}
	}, 10)

	closeTip = debounce((p)=>{
		if(p===null) return
		if(this.activeLabel){
			this.activeLabel=null
			this.props.populateExtendedFeatures(null)
		}
	}, 10)

	render(){
		const {data, theme, allTimesArray, colors, selectedFeatures, recentTimeArray} = this.props
				
		console.log('render RecentSamplesChartZoomerOverMap', this.state.dragging)
		const rsArray = data.m_RecentSamples.m_Values

		const plots = recentTimeArray.map(({time,x})=>{
			var plot = {
				time,
				x,
				y1:-.5
			}
			return plot
		})

		return <ResponsiveContainer width={"100%"} height={300}>
			<LineChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
				onMouseDown={this.dragStart}
	            onMouseMove={(e)=>this.state.dragging?this.dragMove(e):this.openTip(e)}
	            onMouseUp={this.zoom}
				onMouseLeave={this.closeTip}
			>
	  			<YAxis
	  				type="number"
	  				dataKey="y1"
	  				tickFormatter={(y)=>(y*100)+'%'}
	  				allowDataOverflow={true}
	  				ticks={[1.5]}
	      			domain={[-.5, 1.5]}
	      			hide={false}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
					hide={false}
				/>
				{!this.state.dragging && <Tooltip
					isAnimationActive={false}
					content={<RecentSampleChartTip colors={colors} allTimesArray={allTimesArray} selectedFeatures={selectedFeatures} rsArray={rsArray} />}
				/>}
				<Line 
			    	dot={false}
			    	type="monotone" 
				    isAnimationActive={false}
			    	dataKey={`y1`}
			    	strokeWidth={0} 
			    />
			    {this.state.startZoomX && this.state.endZoomX &&  <ReferenceArea 
			    	x1={this.state.startZoomX} 
			    	x2={this.state.endZoomX} 
			    	y1={-.5}
			    	y2={1.5}
			    	fill={theme.palette.secondary.dark} 
			    	strokeOpacity={.05} 
			    />}
			</LineChart>
		</ResponsiveContainer>
	}

	shouldComponentUpdate(nextProps, nextState){
		return (
			nextProps.data===this.props.data && 
			nextProps.selectedFeatures===this.props.selectedFeatures && 
			nextProps.sampleSize===this.props.sampleSize && 
			nextProps.recentTimeArray[0].x === this.props.recentTimeArray[0].x &&
			this.state.startZoomX===nextState.startZoomX &&
			this.state.endZoomX===nextState.endZoomX
		)?false:true 
	}
}

export default withTheme(RecentSamplesChartZoomerOverMap)