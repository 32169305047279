import React, {useState} from 'react'
import { useTheme } from '@material-ui/core/styles';
import { ComposedChart, Line, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import AnomalyChartSection from './AnomalyChartSection'
import AnomaliesChartTip from './AnomaliesChartTip'
import AnomalySeriesBtn from './AnomalySeriesBtn'
import Box from '../../base/ui/Box'

function AnomalyCombo ({data, expandedAnomalies, onChangeExpanded, allTimesArray, recentTimeArray}){
	const theme = useTheme()
	const [ah, setAh] = useState(true)
	const [aw, setAw] = useState(true)
	const [ai, setAi] = useState(true)
	const [ad, setAd] = useState(true)

	const awArray = data.m_RecentAWs.m_Values
	const ahArray = data.m_RecentAHs.m_Values
	const adArray = data.m_RecentADs.m_Values
	const aiArray = data.m_RecentSIs.m_Values

	const plots = recentTimeArray.map(({time,x})=>{
		return {
			aw:awArray[x],
			ah:ahArray[x],
			ad:adArray[x],
			ai:aiArray[x],
			time,
			x
		}
	})

	const anomaliesLegend = (<Box hor>
		<AnomalySeriesBtn series={ah} color={'purple'} onChange={setAh} />
		<AnomalySeriesBtn series={aw} color={'orange'} onChange={setAw} />
		<AnomalySeriesBtn series={ai} color={theme.palette.primary.main} onChange={setAi} />
		<AnomalySeriesBtn series={ad} color={'yellow'} onChange={setAd} />
	</Box>)

	return <AnomalyChartSection 
		title="Anomalies" 
		expandedAnomalies={expandedAnomalies} 
		onChangeExpanded={onChangeExpanded} 
		post={anomaliesLegend}
	>
		<ResponsiveContainer width={"100%"} height={300}>
			<ComposedChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
				<defs>
					<linearGradient id="colorAw" x1="0" x2="0" y1="0" y2="1">
				    	<stop offset="0%" stopColor="rgb(244, 67, 54)" />
				    	<stop offset="50%" stopColor="rgb(255, 152, 0)" />
				    	<stop offset="100%" stopColor="rgb(76, 175, 80)" />
				    </linearGradient>
				</defs>
	  			<YAxis
	  				type="number"
	  				dataKey="ah"
			    	yAxisId={'ah'}
					hide={true}
	      			domain={[0, data.m_AmberAlertCriticalValue]}
	  			/>
	  			<YAxis
	  				type="number"
	  				dataKey="aw"
			    	yAxisId={'aw'}
					hide={true}
					domain={[0, 2]}
	  			/>
	  			<YAxis
	  				type="number"
	  				dataKey="ai"
			    	yAxisId={'ai'}
			    	ticks={['']}
					domain={[0, 1000]}
	  			/>
	  			<YAxis
	  				type="number"
	  				dataKey="ad"
			    	yAxisId={'ad'}
					hide={true}
					domain={[0, 1]}
	  			/>
	  			<XAxis
	  				type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					stroke={theme.palette.text.disabled}
					domain={["dataMin", "dataMax"]}
				/>
				<Tooltip isAnimationActive={false} content={<AnomaliesChartTip allTimesArray={allTimesArray} />} />
				{aw && <Area 
			    	dot={false}
			    	type="step"
			    	fillOpacity={1}
			    	yAxisId={'aw'}
	  				name={'Warning'}
			    	isAnimationActive={false}
			    	dataKey="aw" 
			    	fill="url(#colorAw)"
			    	stroke={'orange'}
			    	strokeWidth={0}
			    />}
			    {ai && <Line 
			    	type="monotone"
			    	dot={false}
			    	isAnimationActive={false}
	  				name={'Index'}
			    	dataKey="ai" 
			    	yAxisId={'ai'}
			    	stroke={theme.palette.primary.main}
			    	strokeWidth={1} 
			    />}
			    {ah && <Line 
   			    	type="step"
   			    	dot={false}
   			    	yAxisId={'ah'}
   	  				name={'History'}
   			    	isAnimationActive={false}
   			    	dataKey="ah" 
   			    	stroke={'purple'}
   			    	strokeWidth={3} 
   			    />}
			    {ad && <Line 
			    	type="step"
			    	dot={false}
			    	isAnimationActive={false}
			    	dataKey="ad" 
	  				name={'Detections'}
			    	yAxisId={'ad'}
			    	stroke={'yellow'}
			    	strokeWidth={2} 
			    />}
			</ComposedChart>
		</ResponsiveContainer>
	</AnomalyChartSection>
}

export default AnomalyCombo