import React, {useState, useEffect} from 'react'
import ActionFormBase from '../../base/ui/ActionForm'
import withRecordForm from '../../base/hocs/withRecordForm'
import withRoles from '../roles/withRoles'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'
import {fieldValues} from '../../base/utils/form'
import {getRecords as tenantRecords} from '../../amber/tenants/services'
import {requireUserFieldUsername, validators} from '../../config'
import FormFieldsModelFactory from '../../base/ui/FormFieldsModelFactory'
import {modelFormFieldValues} from '../../base/utils/form'
import FormErrorList from '../../base/ui/FormErrorList'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete, roles,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

	const [tenantList, setTenantList] = useState([])
	useEffect(()=>{
		tenantRecords()
		.then((res)=>{
			if(res.records){
				setTenantList(res.records.map((rec)=>{
					return {value:rec.id, label:rec.name}
				}))
			}
		})	
	},[])

	const validateCreate = async function(values){
        var errors = {}

        const emalErrs = await validators.email({value:values.email})
        if(emalErrs.length){
            errors.email=<FormErrorList items={emalErrs} />
        }

        if(requireUserFieldUsername){
            const nameErrs = await validators.username({value:values.username})
            if(nameErrs.length){
                errors.username=<FormErrorList items={nameErrs} />
            }
        }

        const passErrs = await validators.password({value:values.password})
        if(passErrs.length){
            errors.password=<FormErrorList items={passErrs} />
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const validateEdit = async function(values){
        var errors = {}

        const emalErrs = await validators.email({value:values.email})
        if(emalErrs.length){
            errors.email=<FormErrorList items={emalErrs} />
        }

        if(requireUserFieldUsername){
            const nameErrs = await validators.username({value:values.username})
            if(nameErrs.length){
                errors.username=<FormErrorList items={nameErrs} />
            }
        }

        if(values.password){
            const passErrs = await validators.password({value:values.password})
            if(passErrs.length){
                errors.password=<FormErrorList items={passErrs} />
            }
        }

        if(await required(values.tenant)) {
          errors.tenant='Please Choose your primary Tentant'
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    var fields = []

    if(action!=='create'){
    	fields.push({
    		...config.fields.avatar
    	})
    }

    fields.push({
    	...config.fields.username
    })
    fields.push({
    	...config.fields.email
    })
    fields.push({
    	...config.fields.password,
    	type: 'password',
    	label: action==='create'?'Password':'New Password'
    })

    if(action!=='create'){
    	fields.push({
    		...config.fields.confirmed
    	})
    	fields.push({
    		...config.fields.firstname
    	})
    	fields.push({
    		...config.fields.lastname
    	})
    	fields.push({
    		...config.fields.tenant,
    		options:tenantList,
    		label:"Primary Tenant"
    	})
    	fields.push({
    		...config.fields.roles,
	    	options:roles.map((r)=>({value:r.id, name:r.name})),
    	})
    	fields.push({
    		...config.fields.tenants,
	    	options:tenantList.map((r)=>({value:r.value, name:r.label})),
    	})	
    }
	
    const initialValues = modelFormFieldValues(record, fields) 

	const form = useFormik({
		enableReinitialize: true,
		validate: action==='create'?validateCreate:validateEdit,
		onSubmit,
		initialValues
	})

	return <ActionFormBase 
		recordTypeDisplayName={config.recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>	

		<FormFieldsModelFactory fields={fields} form={form} />
	    
	</ActionFormBase>
}


export default withRoles(withRecordForm(ActionForm, {config, store, services}))