import React from 'react'
import Box from '../../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles'
import RecentSamplesChartZoomerOverMap from './RecentSamplesChartZoomerOverMap'
import ZoomOutButton from './ZoomOutButton'
import RecentSamplesChartZoomer from './RecentSamplesChartZoomer'

const useStyles = makeStyles(theme=>({
	layer1:{
		position:'absolute', 
		zIndex:1, 
		top:0, 
		left:0, 
		width:'100%'
	},
	layer2:{
		position:'absolute', 
		zIndex:2, 
		top:0, 
		left:0, 
		width:'100%'
	},
}))

function RecentSamplesZoomLayers ({
	data, 
	sampleSize, 
	height, 
	populateExtendedFeatures,
	colors, 
	selectedFeatures, 
	recentTimeArray, 
	setZoom, 
	allTimesArray, 
	zoomed, 
	zoomerArray,
	setZoomLess
}){
	const styles = useStyles()

	return <Box vert relative height={height}>
		<div className={styles.layer1} >
		{zoomed && <RecentSamplesChartZoomer 
			data={data} 
			selectedFeatures={selectedFeatures} 
			colors={colors} 
			setZoom={setZoom}
			recentTimeArray={recentTimeArray}
			allTimesArray={allTimesArray}
			sampleSize={sampleSize}
			populateExtendedFeatures={populateExtendedFeatures}
		/>}
		</div>
		<div className={styles.layer2} >
		{zoomed && <RecentSamplesChartZoomerOverMap
			data={data} 
			selectedFeatures={selectedFeatures} 
			colors={colors} 
			setZoom={setZoom}
			recentTimeArray={recentTimeArray}
			allTimesArray={allTimesArray}
			sampleSize={sampleSize}
			populateExtendedFeatures={populateExtendedFeatures}
		/>}
		</div>
		{zoomerArray.length>0 && <ZoomOutButton onZoomLess={setZoomLess} zoomerArray={zoomerArray} />}
	</Box>
}

export default RecentSamplesZoomLayers