import {inferFields} from '../../base/utils/model'
export const recordTypeName = 'asset'
export const recordSetTypeName = 'assets'
export const recordTypeDisplayName = 'Asset'
export const recordSetTypeDisplayName = 'Assets'
export const clientEndpoint = 'asset'
export const recordDisplayField = 'filename'
export const recordsPath = '/admin/assets'
export const primaryAttribute = 'filename'

export const recordsPerPage = 50

export const initialSorter = {
	field:'createdAt',
	order:'desc'
}

export const initialFilters = {
    'filename':'',
    'mimetype':'',
    'updatedAt':null,
    'createdAt':null
}

export const fields = inferFields({
	data:{
	  	type: 'binary',
	},
	filename:{
	  	type: 'string',
	},
	mimetype:{
	  	type: 'string',
	},
	size:{
	  	type: 'number',
	},
	createdAt:{
	  	name: 'Created',
	  	type: 'date',
	},
	updatedAt:{
	  	name: 'Updated',
	  	type: 'date',
	}
})