import React from 'react'

import Button from '../base/ui/Button'
import Box from '../base/ui/Box'
import SceneHeader from '../base/ui/SceneHeader'
import Footer from '../base/ui/Footer'
import { makeStyles } from '@material-ui/core/styles'
import withUser from '../admin/auth/withUser'
import Color from 'color'

const useStyle = makeStyles(theme=>{
	return {
		wrap: {
			padding:10,

			'&>button': {
				width: 200,
			    height: 200,
			    margin: 10,
			    //borderColor: theme.palette.type==='dark'? Color(theme.palette.secondary.lightest).alpha(.4).toString(): Color(theme.palette.secondary.dark).alpha(.4).toString()
			}
		}
	}
})

function Home ({getPerm, userRole, user}) {
	
	const style = useStyle()

	const actions = <Button to={'/fusion/asset/config'}>Generate Asset Config</Button>

	return <>
		<SceneHeader title={'Fusion Asset Configuration'} back={{to:'/admin'}} />
		<Box center hor grow>
			<Box wrap hor centerHor test="main-menu" className={style.wrap}>
				<Button to="/fusion/asset/config/wizard">Wizard</Button>
				{getPerm('+:assetconfigorg:read') && <Button to="/fusion/orgs">Configurations</Button>}
			</Box>
		</Box>
		<Footer actions={actions} />
	</>
}

export default withUser(Home)