import React from 'react'
import withForm from '../../base/hocs/withForm'
import Frame from './Frame'
import Button from '../../base/ui/Button'
import Link from '../../base/ui/Link'
import Text from '../../base/ui/Text'
import {resetPassword} from './services'
import TextField from '../../base/ui/TextFormField'
import {required} from '../../base/utils/validate'
import {useFormik} from 'formik'
import FormMessageBox from '../../base/ui/FormMessageBox'
import FormErrorList from '../../base/ui/FormErrorList'

function ResetPassword ({
	history,
	setFailureMessage, 
	setInfoMessage, 
	infoMessage, 
	failureMessage, 
	successMessage,
	setSuccessMessage,
	isFormProcessing, 
	setFormProcessing
}) {

	var initialValues = {
		email:''
	}

	const validate = async (values) => {
        var errors = {}

        if(await required(values.email)) {
          errors.email='Please input an email.'
        }
		console.log(11114,errors)

        if(Object.keys(errors).length){
          return errors
        }else{
          return 
        }
    }

    const onSubmit = async ({email}, actions) => {
		try{
			setFormProcessing(true)
			setInfoMessage('Looking up user by email...')
			const res = await resetPassword(email)
			setSuccessMessage(`Please Check your email for a password reset code.`)
			setFormProcessing(false)
			history.push('/verify')
        }
        catch(error){
        	console.error(error)
          	setFormProcessing(false)
          	setFailureMessage(error.message)
        }
	}

	const form = useFormik({
		initialValues,
		validate,
	    onSubmit
	})

	const {handleSubmit, touched, errors} = form

	return <Frame title={"Reset Password"} >
		<Text center >{"We will send a reset code to your email."}</Text>
		<form onSubmit={handleSubmit}>
			<FormMessageBox
				failureMessage={failureMessage}
				successMessage={successMessage}
				infoMessage={infoMessage}
			/>

			<TextField
				id="email"
				form={form}
				label="Email"
				autoFocus={true}
	        />

			<Button primary submit 
				loading={isFormProcessing} 
				disabled={isFormProcessing}
			>
				Send Email
			</Button>
			<Link to={'/login'}>Never mind. I remember it now.</Link>
		</form>
	</Frame>
}


export default withForm(ResetPassword)
