import React from 'react'
import {getRecords} from '../../admin/users/services'
import { withRouter } from "react-router-dom";

export const withMiniRecordsList = (Comp) => {
  
  class Cls extends React.Component {

  	constructor(pars){
  		super(pars)

  		this.state = {
	  		records: [],
	  		limit:25,
	  		skip:0,
	  		count:0,
	  		sort:'-createdAt'
	  	}

	  	this.getRecords({
	  		limit: this.state.limit,
	  		skip: this.state.skip,
	  		sort: this.state.sort
	  	})
  	}

  	getRecords = async ({limit, sort, skip})=>{
  		try{
  			const res = await getRecords({
  				limit, 
  				skip,
  				sort,
  				query:{...this.props.query}
  			})
  			//console.log(1111, res)
  			const state = {
  				limit,
  				skip,
  				sort,
  				records: [...this.state.records, ...res.records],
  				count: res.count.filtered,
  			}
  			this.setState(state)
  		}catch(e){
  			console.error(e)
  		}
  	}

  	goToRecord=(id)=>{
  		this.props.history.push(`/admin/users/${id}`)
  	}

  	loadMoreRecords=()=>{
  		this.getRecords({
  			limit: this.state.limit,
  			sort: this.state.sort,
  			skip: this.state.skip+this.state.limit
  		})
  	}

    render() {
      return (<Comp {...this.props} {...this.state} 
      	loadMoreRecords={this.loadMoreRecords}
      	goToRecord={this.goToRecord} />)
    }
  }

  return withRouter(Cls)
}

export default withMiniRecordsList
