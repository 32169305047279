import React from 'react'
import IconButton from '../../base/ui/IconButton'
import Box from '../../base/ui/Box'
import LayersClear from '@material-ui/icons/LayersClear'
import Layers from '@material-ui/icons/Layers'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	icon:{
		color: theme==='dark'? '#ffffff' : theme.palette.primary.main
	},
}))

function SelectAllNoneButton ({selected, total, onChange}){
	const style = useStyles()
	return <Box hor center>
		<IconButton className={style.icon} disabled={selected===total} onClick={()=>onChange(1)}>
			<Layers />
		</IconButton>
		<IconButton className={style.icon} disabled={selected===0} onClick={()=>onChange(0)}>
			<LayersClear />
		</IconButton>
	</Box>
}

export default SelectAllNoneButton