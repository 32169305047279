
import React from 'react'
import useModelRecord from '../../base/hooks/useModelRecord'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import Button from '../../base/ui/Button'
import Card from '../../base/ui/Card'
import store from './store'
import * as services from './services'
import * as config from './config'
import SceneHeader from '../../base/ui/SceneHeader'
import { makeStyles } from '@material-ui/core/styles'
import withCopyToClipboard from '../../base/hocs/withCopyToClipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import jwt from 'jsonwebtoken'
import {secret} from '../../config'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckedIcon from '../../base/ui/CheckedIcon'
import Moment from 'moment'

const useStyles = makeStyles((theme)=>({
	token:{
		lineBreak: 'anywhere'
	},
	heading:{
		padding:'20px 0 10px'
	},
	btn:{
		color:'#fff'
	}
}))

function Detail ({copyToken}){
	const [record, loading] = useModelRecord({store, config, services})
	const classes = useStyles()
	//console.log('Detail', record, loading)
	
	if(!record || loading) return false

	var decoded
	try{
		decoded = jwt.verify(record.token, secret)
	}catch(e){
		//free pass
	}
	 
	//console.log(decoded)

	const copy = (<Button onClick={()=>copyToken({token:record.token})} primary>
					<Box hor center>
						<FileCopyIcon />
						<Text className={classes.btn}>Copy API key to Clipboard</Text>
					</Box>
				</Button>)

	return <Box vert grow hidden>
		<SceneHeader 
			title={`${config.recordTypeDisplayName} ${record[config.primaryAttribute]}`} 
			back={config.recordSetTypeDisplayName} 
		/>
		<Box vert scroll margin={20}>
			
			<Box hor>
				<Box vert grow padding={20}>
					<Text center heading={3}>API Key Status</Text>
				     <TableContainer component={Paper}>
				      <Table className={classes.table}>
				     	<TableRow>
				           <TableCell><Text>{'Expires'}</Text></TableCell>
				           <TableCell><Text weight={500}>{record.expires?Moment(record.expires).format("dddd, MMMM Do YYYY, h:mm:ss a"):'No Expiration'}</Text></TableCell>
				        </TableRow>
				        <TableRow>
				           <TableCell><Text>{'Is Expired'}</Text></TableCell>
				           <TableCell><Text weight={500}>{record.isExpired && <CheckedIcon />}</Text></TableCell>
				        </TableRow>
				        <TableRow>
				           <TableCell><Text>{'Is Active'}</Text></TableCell>
				           <TableCell><Text weight={500}>{record.isActive && <CheckedIcon />}</Text></TableCell>
				        </TableRow>
				      </Table>
				     </TableContainer>

				     <Text center className={classes.heading} heading={3} >Usage</Text>
					<Card title={copy}>
						<Text className={classes.token}>{record.token}</Text>
					</Card>
				</Box>
				<Box vert grow padding={20}>
					<Text center heading={3}>Decoded Token</Text>
					{decoded && <TableContainer component={Paper}>
				      <Table className={classes.table}>
				      	{Object.keys(decoded).map((claim)=>(<TableRow>
				            <TableCell><Text>{claim}</Text></TableCell>
				            <TableCell><Text weight={500}>{decoded[claim]}</Text></TableCell>
				          </TableRow>))}
				      </Table>
				     </TableContainer>}
				</Box>
			</Box>
		</Box>
	</Box>
}


export default withCopyToClipboard(Detail)