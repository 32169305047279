import React from 'react';
import {on} from '../bus'

const withBarLoading = (Comp)=>{

  class Cmp extends React.Component {

    state={
      percent:0
    }

    _dir='+'

    componentDidMount(){
      
      this._e = on('global-loading', async(loading)=>{
        if(!loading){
          this.setState({percent:0})
        }else{
          await this.setState({percent:0})
          this.iterate()
        }
      })
    }

    iterate = () =>{
      if(this._dir === '+' ){
          this._dir = '-'
          this.setState({percent:100})
      }
      else if(this._dir === '-'){
        this._dir="+"
        this.setState({percent:1})
      }
      setTimeout(this.iterate, 600)
    } 

    componentWillUnmount(){
      this._e()
    }

    render() {
      return <Comp {...this.props} {...this.state} />
    }
  }
  return Cmp
}
export default withBarLoading;
