import React from 'react'
import Text from '../base/ui/Text'
import Box from '../base/ui/Box'
import Auth from './Auth'

function E401 () {

	return (<Auth>
		<Box vert center grow >
			<Text heading={1} weight={100}>401</Text>
			<Text size="1rem" weight={500}>Not Authorized</Text>
		</Box>
	</Auth>)
}

export default E401