import React from 'react'
import {findRecordById} from './services'
import {dispatch} from '../../base/bus'

export const withCarousel = (Comp) => {
  
  class Cls extends React.Component {

  	state = {
      loaded:0,
      errors:0,
      detailsMap:{},
      averageResponseTimes:[],
      totalExecutionTime:0,
      viewer:false,
      slideIndex:0,
      tab:'stack',
      startTime: Date.now()
    }

    componentDidMount(){
      this.loadDetails()
    }

    goToSlide=({record, index})=>{
      this.setState({viewer:true, slideIndex:index})
    }

    goToPrevious=()=>{
      const slideIndex = this.state.slideIndex===0
        ? this.props.records.length-1
        : this.state.slideIndex-1

        this.setState({slideIndex})
    }

    goToNext=()=>{
      const slideIndex = this.state.slideIndex===this.props.records.length-1
        ? 0
        : this.state.slideIndex+1

        this.setState({slideIndex})
    }

    showMenu=()=>{
      this.setState({viewer:false})
    }

    setView=(tab)=>{
      this.setState({tab})
    }

    loadDetails(){
      const startALL=Date.now()
      const fns = this.props.records.map((r)=>{
        return new Promise((resolve, reject)=>{
          const startTS = Date.now()
          findRecordById(r.id).then((res)=>{
            //console.log(111111,res)
            const range = Date.now()-startTS
            res.record._ts = range
            const averageResponseTimes = [...this.state.averageResponseTimes, range]
            const totalExecutionTime = Date.now()-this.state.startTime
            this.setState({
              totalExecutionTime,
              averageResponseTimes,
              loaded:this.state.loaded+1,
              detailsMap:{...this.state.detailsMap, [r.id]:res.record}
            })
            resolve(res.record)
          })
          .catch((e)=>{
            const range = Date.now()-startTS
            const totalExecutionTime = Date.now()-this.state.startTime

            this.setState({
              totalExecutionTime,
              errors:this.state.errors+1,
              detailsMap:{...this.state.detailsMap, [r.id]:{error:e, _ts:range, message:`${r.id} ${e.message}`}}
            })
            reject(e)
          })
        })
      })

      Promise.all(fns)
      .then(()=>{
        const totalExecutionTime = Date.now()-startALL
        this.setState({totalExecutionTime})
      })
      .catch((e)=>{
        console.log(e)
        dispatch('error', e)
        const totalExecutionTime = Date.now()-startALL
        this.setState({totalExecutionTime})
      })
    }

    render() {
      return (<Comp {...this.props} {...this.state} 
        showMenu={this.showMenu}
        setView={this.setView}
        goToSlide={this.goToSlide}
        goToPrevious={this.goToPrevious} 
        goToNext={this.goToNext} />)
    }
  }

  return Cls
}

export default withCarousel
