import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '../../base/ui/IconButton'

const ImageAvatar = ({src, size=88, to, Icon=AccountCircleIcon, className=''}) => {

  const css = {height:size, width:size, borderRadius:size/2, background:'transparent'}

  if (src && typeof src!=='object') {
    if(to) {
      return <IconButton to={to} className={className}>
        <Avatar src={src} style={css} />
      </IconButton>
    }
    return <Avatar src={src} style={css} className={className} />
  }

  if(to){
    return <IconButton to={to} className={className}>
      <Avatar style={css}>
        <Icon style={{fontSize:size}} color="secondary" />
      </Avatar>
    </IconButton>
  }
  return <Avatar style={css} className={className}>
    <Icon style={{fontSize:size}} color="secondary" />
  </Avatar>
  
}

export default ImageAvatar
