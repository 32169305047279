import React from 'react'
import FormFieldModelFactory from './FormFieldModelFactory'
import Box from './Box'

function FormFieldsModelFactory ({
	fields, form, subFieldsComponent,  width=400
}) {
	return <Box hor wrap center>
		{fields.map((field)=>{
			return <Box vert width={width}><FormFieldModelFactory 
				key={field.field} 
				subFieldsComponent={subFieldsComponent}
				field={field} 
				form={form} /></Box>
		})}
		</Box>
}

export default FormFieldsModelFactory