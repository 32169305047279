import React from 'react';
import {dispatch} from '../bus'

export const withCopyToClipboard = (Comp) => {
  return class extends React.Component {

    async copyToken ({token}) {
      try{
        await navigator.clipboard.writeText(token)
        dispatch('notify-success', {message: 'Successfully Copied Token to Clipboard.'})
      } catch (err){
        dispatch('error', {message:'Error Coping to clipboard. Try copying it manualy.'})
      }
    }

   
    render() {
      return (<Comp {...this.props} {...this.state} copyToken={this.copyToken} />)
    }
  }
}

export default withCopyToClipboard
