import React from 'react'
import { withTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, ReferenceLine } from 'recharts';
import RecentSampleChartTip from './RecentSampleChartTip'
import {debounce} from '../../base/utils/listeners'

class RecentSamplesChartZoomer extends React.Component {
	
	render(){
		const {data, theme, colors, selectedFeatures, height, allTimesArray, recentTimeArray} = this.props
				
		console.log('render RecentSamplesChartZoomer')
		const rsArray = data.m_RecentSamples.m_Values

		const plots = recentTimeArray.map(({time,x})=>{
			var plot = {
				time,
				x
			}
			selectedFeatures.forEach((sf)=>{
				plot[`y${sf}`] = rsArray[sf][x]
			})
			return plot
		})

		return <ResponsiveContainer width={"100%"} height={height||300}>
			<LineChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
	  			<YAxis
	  				type="number"
	  				dataKey="y1"
	  				tickFormatter={(y)=>(y*100)+'%'}
	  				allowDataOverflow={true}
	  				ticks={[-.5,0,.5,1,1.5]}
	      			domain={[-.5, 1.5]}
	      			stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
			    {selectedFeatures.map((i)=>(<Line 
			    	dot={false}
			    	key={`y${i}`}
			    	type="linear" 
				    isAnimationActive={false}
			    	dataKey={`y${i}`}
			    	stroke={colors[i]}
			    	strokeWidth={1} 
			    />))}
			    <ReferenceLine y={1} stroke="red" />
			    <ReferenceLine y={0} stroke="red" />
			</LineChart>
		</ResponsiveContainer>
	}

	shouldComponentUpdate(nextProps, nextState){
		return (
			nextProps.data===this.props.data && 
			nextProps.selectedFeatures===this.props.selectedFeatures &&
			nextProps.sampleSize===this.props.sampleSize && 
			nextProps.recentTimeArray[0].x === this.props.recentTimeArray[0].x 	
		)?false:true 
	}
}

export default withTheme(RecentSamplesChartZoomer)