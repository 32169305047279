import React from "react"
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Text from './Text'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
	card: {
		backgroundColor: theme.palette.success.main
	},
  text: {
    color: theme.palette.common.white
  }
}))

export default ({message}) => {
  const styles = useStyles()
  return (<div>
      {message && <Card className={styles.card}>
        <CardHeader classes={{root:styles.text,avatar:styles.text}} avatar={<CheckCircleIcon />} title={<Text className={styles.text} heading={6}>Success</Text>} />
          <CardContent>
            <Text className={styles.text}>{message}</Text>
          </CardContent>
      </Card>}
  </div>)
}