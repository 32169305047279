import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import moment from 'moment'
import Text from '../../base/ui/Text'
import DateTimeRangeField from '../../base/ui/DateTimeRangeField'
import Link from '../../base/ui/Link'
import withUser from '../auth/withUser'
import EditIcon from '../../base/ui/EditIcon'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import IconButton from '../../base/ui/IconButton'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)
      this.props.setTable(this.setTable())
    }

    setTable=()=>{

      var columns =[{
        ...fields.name,
        cell:({record, index, value}) => {
          return <Link block to={`${config.recordsPath}/${record.id}`} >{value}</Link>  
        }
      },{
        ...fields.key
      },{
        ...fields.resource
      },{
        ...fields.createdAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>  
        )
      },{
        ...fields.updatedAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>    
        )
      },]

      if(this.props.getPerm(`+:${config.recordTypeName}:update`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <IconButton onClick={()=>{
            this.props.history.push(`${config.recordsPath}/${record.id}/edit`)
          }} ><EditIcon /></IconButton> 
        )
      })


      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.name}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })
     
      return {
        columns,
        facets: [{
          ...fields.id,
          field:'_id'
        },{
          ...fields.name
        },{
          ...fields.key
        },{
          ...fields.resource
        },{
          ...fields.createdAt,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        },{
          ...fields.updatedAt,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        }]
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords