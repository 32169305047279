import moment from 'moment'

export const records = ({records, filters, facets})=>{
	return records.filter((rec)=>{
    	var keep = true
    	facets.forEach((facet)=>{
    		const m = dataType(facet)(filters[facet.field], rec[facet.field])
    		if (m===false) keep = false
    	})
		return keep   
    })
}

export const dataType = ({type, filterFn})=>{
	if(filterFn) return filterFn
	if (type==='string') return startsWith
	if (type==='number') return startsWithNumber
	if(type==='date') return equalsDate
	if(type==='combined-date-time') return combinedDateTimeObject
}

export const isInString = (filter, rec)=>{
	if (filter==='' || rec===undefined) return true
	return rec.toLowerCase().indexOf(filter.toLowerCase())=== -1 ? false : true
}

export const startsWith = (filter, rec)=>{
	if (filter==='' || rec===undefined) return true
	return rec.toLowerCase().indexOf(filter.toLowerCase())=== 0 ? true : false
}

export const startsWithNumber = (filter, rec)=>{
	if (filter==='' || rec===undefined) return true
	return (rec+'').indexOf(filter+'')=== 0 ? true : false
}

export const combinedDateTimeObject = (filter, rec)=>{
	if (filter===null) return true
	if(filter.equalsDate) {
		if(!equalsDate(filter.equalsDate, rec)) return false
	}
	if(filter.earlierThenDate){
		if(!earlierThenDate(filter.earlierThenDate, rec)) return false
	}
	if(filter.earlierThenDateTime){
		if(!earlierThenDateTime(filter.earlierThenDateTime, rec)) return false
	}
	if(filter.laterThenDate){
		if(!laterThenDate(filter.laterThenDate, rec)) return false
	}
	if(filter.laterThenDateTime){
		if(!laterThenDateTime(filter.laterThenDateTime, rec)) return false
	}
	return true
}

export const equalsDate = (filter, rec)=>{
	if (filter===null) return true
	return moment(filter).format('M/D/YYYY')=== moment(rec).format('M/D/YYYY')
}

export const earlierThenDateTime = (filter, rec)=>{
	if (filter===null) return true
	return moment(filter).format('X') >= moment(rec).format('X')
}

export const laterThenDateTime = (filter, rec)=>{
	if (filter===null) return true
	return moment(filter).format('X') <= moment(rec).format('X')
}

export const earlierThenDate = (filter, rec)=>{
	if (filter===null) return true
	return moment(filter).format('YYYYDDD') >= moment(rec).format('YYYYDDD')
}

export const laterThenDate = (filter, rec)=>{
	if (filter===null) return true
	return moment(filter).format('YYYYDDD') <= moment(rec).format('YYYYDDD')
}