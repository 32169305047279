import React from 'react'

import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import withAuth from '../admin/auth/withAuth'
import Theme from '../ui/Theme'
import ErrorsToast from '../base/ui/ErrorsToast'
import SuccessToast from '../base/ui/SuccessToast'
import Auth from '../ui/Auth'

import E404 from '../ui/E404'

import Login from '../admin/auth/Login'
import Register from '../admin/auth/Register'
import ResetPassword from '../admin/auth/ResetPassword'
import ValidateCode from '../admin/auth/ValidateCode'
import Admin from './Admin'
import Dashboard from '../amber/dashboard/Home'

import Loader from '../ui/Loader'

function App({user}) {
  if(user === undefined) return false
  return (
    <Router>
      <Theme>
      	<Switch>
	        <Route exact path="/">
            <Redirect to={'/admin/sensors'} />
          </Route>
          <Route path="/login" exact>
            <Auth><Login /></Auth>
          </Route>
          <Route path="/register" exact>
            <Auth><Register /></Auth>
          </Route>
          <Route path="/reset" exact>
            <Auth><ResetPassword /></Auth>
          </Route>
          <Route path="/verify" exact>
            <Auth><ValidateCode /></Auth>
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="*">
            <E404 />
          </Route>
	      </Switch>   
        <ErrorsToast />
        <SuccessToast />
        <Loader />
      </Theme>
    </Router>
  );
}

export default withAuth(App);
