import React from 'react'
import {useTheme } from '@material-ui/core/styles';
import ReactJson from 'react-json-view'

function JSONView ({data}){

	const theme = useTheme()
	
	return <ReactJson src={data} theme={theme.palette.type==='dark'?'summerfruit':'summerfruit:inverted'} collapsed={1} style={{backgroundColor:'transparent'}} />
}


export default JSONView