import React from 'react'
import IconButton from '../../base/ui/IconButton'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

function CollapseIconButton ({expanded, onChange}){
	return <IconButton onClick={()=>onChange(expanded?false:true)}>
		{expanded && <ExpandLess />}
		{!expanded && <ExpandMore />}
	</IconButton>
}

export default CollapseIconButton