import React from 'react'
import withRecords from './withRecords'
import SceneGrid from '../../base/ui/SceneGrid'
import SceneTransitions from '../../base/ui/SceneTransitions'
import SceneHeader from '../../base/ui/SceneHeader'
import Button from '../../base/ui/Button'
import Box from '../../base/ui/Box'
import Detail from './Detail'
import {recordSetTypeDisplayName, recordsPath, recordTypeName, recordTypeDisplayName} from './config'
import {useParams } from "react-router-dom";
import Footer from '../../ui/Footer'
import ActionForm from './ActionForm'

function Records ({
	columns,
	facets,
	rowCount,
	totalRows,
	recordsLoading, 
	records, 
	sorter, 
	filters,
	dataGridId, 
	onItemsRendered,
	getPerm,
	user,
	history,
	clearFilters,
	bulkActions,
	isFiltered
}) {

	let params = useParams()

    var title = recordSetTypeDisplayName+ (recordsLoading!==false?"":(" ("+(rowCount===totalRows? rowCount :rowCount+' of '+totalRows)+")"))
    
    const position = params.id&&!params.action?1:0

	const actions = getPerm(`+:${recordTypeName}:create`)
    	? <Button to={`${recordsPath}/record/create`}>{`Create a new ${recordTypeDisplayName}`}</Button>
    	: ''

	return <>
		{position===0 && <SceneHeader title={title} back={{to:'/admin'}} />}
		<SceneTransitions index={position}>
			{columns.length  && <SceneGrid
				uid={dataGridId}
				columns={columns}
				records={records}
				sorter={sorter}
				bulkActions={bulkActions}
				onItemsRendered={onItemsRendered}
				facetProps={{facets, filters, clearFilters, isFiltered, vert:true}}
			/>}
			<Box vert grow>
				{params.id && <Detail key={params.id} />}
			</Box>
		</SceneTransitions>
		<Footer actions={actions} />
		<ActionForm key={params.id} />
	</>
}


export default withRecords(Records)