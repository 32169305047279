import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import moment from 'moment'
import Text from '../../base/ui/Text'
import DateTimeRangeField from '../../base/ui/DateTimeRangeField'
import Link from '../../base/ui/Link'
import Box from '../../base/ui/Box'
import TextField from '@material-ui/core/TextField';
import withUser from '../../admin/auth/withUser'
import EditIcon from '../../base/ui/EditIcon'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import IconButton from '../../base/ui/IconButton'
import StatusIcon from './StatusIcon'
import Warning from './Warning'
import {getRecords as tenantRecords} from '../../amber/tenants/services'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        tenantOptions:[],
        carouselRecords:[]
      }
      
      tenantRecords().then(({records})=>{
        const tenantOptions = records.map((r)=>{
          return {value:r.id, label:r.name}
        })
        this.setState({tenantOptions})
        this.props.setTable(this.setTable())
      })
    }

    clearCarousel=()=>{
      this.setState({carouselRecords:[]})
    }

    setTable=()=>{

      var columns =[{
        ...fields.id,
        width:270,
        cell:({record, index, value}) => {
          return (<Box centerVert hor>
            <Link block to={`${config.recordsPath}/${record.id}/section/overview`} >{value}</Link>  
          </Box>)
        }
      },{
        ...fields.label
      },{
        ...fields.samples,
        width:110
      },{
        ...fields.state,
        width:170,
        cell:({record, value}) => {
          return <Box centerVert hor>
            <StatusIcon status={value} />
            <Text margin={7}>{value}</Text>
          </Box>
        }
      },{
        ...fields.warning,
        width:110,
        cell:({record, value}) => {
          return <Warning value={value} />
        }
      },{
        ...fields.tenant,
        cell: ({record, value})=>{
          if(!value || !value.name) return false
          return <Text>{value.name}</Text>
        }
      },{
        ...fields.lastModified,
        width: 180,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('lll')}</Text>  
        )
      }]

      if(this.props.getPerm(`+:${config.recordTypeName}:update`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <IconButton onClick={()=>{
            this.props.history.push(`${config.recordsPath}/${record.id}/edit`)
          }} ><EditIcon /></IconButton> 
        )
      })


      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.name}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })

      var bulkActions=[{
        Icon: ViewCarouselIcon,
        name: 'View All Carousel',
        action: (carouselRecords)=>{
          this.setState({carouselRecords})
        }
      }]

      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)){
        bulkActions.push({
          Icon: DeleteForeverIcon,
          name:'Delete All',
          action: this.props.deleteRecords,
        })
      }
     
      return {
        columns,
        bulkActions,
        facets: [{
          ...fields.label
        },{
          ...fields.id,
          field:'_id',
          type:'multiple-string',
          render: ({value, field, filter, name})=>(
            <TextField
              label={`Filter By ${name}`}
              value={value}
              clearable={true}
              onChange={(e)=>filter({[field]:e.target.value})}
            />
          )
        },{
          ...fields.tenant,
          options: this.state.tenantOptions
        },{
          ...fields.state,
        },{
          ...fields.warning,
        },{
          ...fields.lastModified,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        }]
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} clearCarousel={this.clearCarousel} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords