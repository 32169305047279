import React from 'react'
import { withRouter } from "react-router-dom";

import MLink from '@material-ui/core/Link';
import Text from './Text'

function Link ({history, style={}, color='primary', href, onClick, block, className, children, to}) {
	var props = {
		className: className,
		onClick: onClick,
		style: style,
		href: href
	}

	var textStyle={}

	if(block){
		props.style.display='block'
	}else{
		textStyle.display='inline'
	}

	if(to){
		props.onClick=()=>{
			history.push(to)
		}
		props.style.cursor='pointer'
	}

	return <Text style={textStyle} color={color}><MLink color="inherit" {...props} >{children}</MLink></Text>
}


export default withRouter(Link)