import React from 'react'

import BackIcon from '@material-ui/icons/ArrowBackIos'
import IconButton from './IconButton'
import Box from './Box'
import Text from './Text'
import Confirm from '../controlled/Confirm'
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme=>({
	pageHeader: {
		height: 64,
	    padding: '0 5px',
	    //backgroundColor: '#000000'
	    //background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)'
	},
	backText:{
		marginLeft:'-10px'
	},
	back:{
		fill: theme.palette.icon.active
	}
}))

function SceneHeader ({ back='', title}) {

	const style=useStyle()

	return (<Box hor centerVert className={style.pageHeader}>
			<Box hor width={200}>
				{back.constructor===String && <IconButton back>
					<Box hor centerVert>
						<BackIcon className={style.back} fontSize="large" />
						{back &&<Text className={style.backText}>{back}</Text>}
					</Box>
				</IconButton> }
				{back && back.confirm && <Confirm {...back.confirm} >
					<IconButton>
						<Box hor centerVert>
							<BackIcon className={style.back} fontSize="large" />
							{back.text && <Text className={style.backText}>{back.text}</Text>}
						</Box>
					</IconButton>
				</Confirm>}
				{back && back.onClick && <IconButton onClick={back.onClick}>
					<Box hor centerVert>
						<BackIcon className={style.back} fontSize="large" />
						{back.text && <Text className={style.backText}>{back.text}</Text>}
					</Box>
				</IconButton>}
				{back && back.to && <IconButton to={back.to}>
					<Box hor centerVert>
						<BackIcon className={style.back} fontSize="large" />
						{back.text && <Text className={style.backText}>{back.text}</Text>}
					</Box>
				</IconButton>}
			</Box>
			<Box hor grow centerHor>
				{title && <Text heading={1}>{title}</Text>}
			</Box>
			<Box hor width={200} end>
			</Box>
		</Box>)
}

export default SceneHeader