import React from 'react'
import {useTheme } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import AnomalyChartSection from './AnomalyChartSection'
import AnomalyWarningChartTip from './AnomalyWarningChartTip'

function AnomalyWarningLevelChart ({data, expandedAnomalies, onChangeExpanded, allTimesArray, recentTimeArray}){
	const theme = useTheme()

	const awArray = data.m_RecentAWs.m_Values

	const plots = recentTimeArray.map(({time,x})=>{
		return {
			Aw: awArray[x],// [0,1,2][Math.floor(Math.random()*10/3)],//awArray[x],
			time,
			x
		}
	})

	return <AnomalyChartSection title="Anomaly Warning Level" expandedAnomalies={expandedAnomalies} onChangeExpanded={onChangeExpanded}>
		<ResponsiveContainer width={"100%"} height={200}>
			<AreaChart 
				data={plots}
				syncId="recentSamples1"
				margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
			>
				<defs>
					<linearGradient id="colorAw" x1="0" x2="0" y1="0" y2="1">
				    	<stop offset="0%" stopColor="rgb(244, 67, 54)" />
				    	<stop offset="50%" stopColor="rgb(255, 152, 0)" />
				    	<stop offset="100%" stopColor="rgb(76, 175, 80)" />
				    </linearGradient>
				</defs>
	  			<YAxis
	  				type="number"
	  				ticks={[0,1,2]}
	  				dataKey="Aw"
	  				allowDecimals={false}
					tickLine={false}
	  				position="left"
	      			domain={[0, 2]}
	      			stroke={theme.palette.text.disabled}
	  			/>
	  			<XAxis
					type="number" 
					dataKey="x"
					position="bottom"
					tickCount={10}
					allowDecimals={false}
					tickLine={false}
					tickFormatter={(x)=>(allTimesArray[x]||{}).time}
					domain={["dataMin", "dataMax"]}
					stroke={theme.palette.text.disabled}
				/>
				<CartesianGrid 
					stroke={theme.palette.text.disabled}
				/>
				<Tooltip
					isAnimationActive={false}
					content={<AnomalyWarningChartTip allTimesArray={allTimesArray} />}
				/>
			    <Area 
			    	dot={false}
			    	type="step"
			    	fillOpacity={1}
			    	isAnimationActive={false}
			    	dataKey="Aw" 
			    	fill="url(#colorAw)"
			    	strokeWidth={0}
			    />
			</AreaChart>
		</ResponsiveContainer>
	</AnomalyChartSection>
}

export default AnomalyWarningLevelChart