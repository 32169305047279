import React from 'react'
import DateTimeRangeField from './DateTimeRangeField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	field: {
		margin:'10px 5px'
	}
})

function DateTimeRangeFormField ({form, id, ...rest}){

	const styles = useStyles()

	const { errors, touched, handleChange, values} = form

	return <DateTimeRangeField 
		id={id}
		name={id}
		error={(touched[id] && errors[id])?true:false}
		helperText={errors[id]}
		value={values[id]}
		onChange={(v)=>handleChange({target:{id:id, name:id, value:v.equalsDate}})}
		className={styles.field}
		ranger={false}
		{...rest}
    />
}


export default DateTimeRangeFormField