import React from 'react'
import RupeeLoader from '../base/controlled/RupeeLoader'
import CircularLoader from '../base/controlled/Loader'
import ColorBarLoader from '../base/controlled/ColorBarLoader'
import {on} from '../base/bus'
import {getLoader} from '../profile/store'

export default class Loader extends React.Component {
	
	state = {
      loader: getLoader()
    }

	componentDidMount(){
      this._e = on('selected-loader-change', ()=>{
        this.setState({loader:getLoader()})
      })
    }

    componentWillUnmount(){
      this._e()
    }

	render(){
		if(this.state.loader==='rupee'){
			return <RupeeLoader />
		}
		else if(this.state.loader==='colorbar'){
			return <ColorBarLoader />
		}
		return <CircularLoader />
	}
}  
	
