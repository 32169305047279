import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ConfirmLeaveForm from './ConfirmLeaveForm'
import Box from './Box'
import Button from './Button'

const useStyle = makeStyles({
	btn: {
		margin:5
	},
})

function ModelFormActions ({
	dirty, isValid, isFormProcessing, action, onDelete, onClose, createText="Create", cancelText="Cancel", editText='Update'
}) {

	const style= useStyle()

	return <Box hor end margin={5}>
		<ConfirmLeaveForm dirty={dirty} text={cancelText} className={style.btn} onClose={onClose} />
		<Button primary={dirty && isValid} submit className={style.btn}
			loading={isFormProcessing} 
			disabled={isFormProcessing}
		>{action==='create'?createText:editText}</Button>
		{onDelete && action==='edit' && <Button className={style.btn} onClick={onDelete} error>Delete</Button>}
	</Box>
}

export default ModelFormActions