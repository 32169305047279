import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color'
import Swatch from './Swatch'
import { FixedSizeList as List } from 'react-window';

const useStyles = makeStyles(theme=>({
	label:{
		color: Color(theme.palette.text.primary).alpha(.6).toString(),
		fontSize: '.6em',
		marginRight: 3
	},
	value:{
		color: Color(theme.palette.text.primary).alpha(.8).toString(),
		fontSize:'.8em'
	},
	on:{
		cursor:'pointer'
	},
	off:{
		opacity:.5,
		cursor:'pointer'
	}
}))

function FeaturesHorzNav ({
	data, 
	slim, 
	onToggleFeature, 
	colors, 
	selectedFeatures=[],
	featuresDetail,
	dimensions,
	listWidth,
	listHeight,
	hideUnSelected
}){
	const styles = useStyles()
	const rrsArray = data.m_RecentRawSamples.m_Values
	const rsArray = data.m_RecentSamples.m_Values

	console.log('render FeaturesHorzNav')
	
	const features = hideUnSelected?selectedFeatures.map((sf)=>data.m_Nano.m_NanoConfig.Features[sf]):data.m_Nano.m_NanoConfig.Features
	const swatchW = slim?70:190
	const width = listWidth || dimensions.width-40
	const cols = Math.floor(width/swatchW)
	const rows = Math.ceil(features.length/cols)

	var colsAry = [] 
	for(var i=0; i<cols; i++){
		colsAry.push([i])
	}
	
	var featureIndex=-1
	
	const Row = ({ index, style }) => {
		return (<div key={index} style={style}>
  			<Box centerHor hor>{colsAry.map((col,i)=>{
  				const c = (index*cols)+i
  				  				
  				if(!features[c]) return false
  				const activeFeature = hideUnSelected?true: selectedFeatures.includes(c)
  				if(activeFeature) {
					featureIndex++
				}

  				return (<Swatch key={c} 
					className={activeFeature?styles.on: styles.off} 
					width={slim?50:170} 
					height={slim?'auto':65}
					onClick={()=>onToggleFeature(hideUnSelected?selectedFeatures[c]:c)} 
					colorBadge={activeFeature?colors[hideUnSelected?selectedFeatures[c]:c]:'transparent'}
				>
					<Box centerHor={slim?true:false} vert >
						<Box hor between>
							<Box hor center>
								<Text className={styles.label}>#</Text>
								<Text className={styles.value}>{hideUnSelected?(selectedFeatures[c]+1):c+1}</Text>
							</Box>
							{!slim && <Box hor center>
								<Text className={styles.label}>Weight</Text>
								<Text className={styles.value}>{features[c].weight}</Text>
							</Box>}
						</Box>
						{!slim && <Box vert grow>
							<Text center className={styles.value}>{`${features[c].minVal.toFixed(3)} - ${features[c].maxVal.toFixed(3)}`}</Text>
							{activeFeature && featuresDetail && <Box hor grow between>
								<Box hor centerH>
									<Text className={styles.value}>{(rsArray[c][featuresDetail]*100).toFixed(2)}</Text>
									<Text className={styles.label}>%</Text>
								</Box>
								<Box hor centerH>
									<Text className={styles.value}>{(rrsArray[c][featuresDetail]).toFixed(5)}</Text>
								</Box>
							</Box>}
						</Box>}
					</Box>
				</Swatch>)
  			})}
  			</Box>
  		</div>) 
	}

	return (<List
		height={listHeight || (slim?80:250)}
		itemCount={rows}
		itemSize={slim?65:105}
		width={width}
	>
		{Row}
	</List>)
}

export default FeaturesHorzNav