import React from 'react';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const withImagePreview = (Comp, open=true) => {
  return class extends React.Component {

  	state = {
      previewUrl: false,
    }

    createPreviewUrl = (file) => {
      if(!file) return this.setState({ previewUrl: false })

      getBase64(file, (url)=>{
        this.setState({ previewUrl: url });
      })
    }

    render() {
      return (<Comp {...this.props} {...this.state} 
        createPreviewUrl={this.createPreviewUrl}
      />)
    }
  }
}

export default withImagePreview