import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import Button from '../../base/ui/Button'
import Card from '../../base/ui/Card'
import store from './store'
import * as services from './services'
import * as config from './config'
import SceneHeader from '../../base/ui/SceneHeader'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import Moment from 'moment'
import withDetailScene from './withDetailScene'
import SensorMiniList from '../sensors/SensorMiniList'
import UserMiniList from '../../admin/users/UserMiniList'
import {msToTimeString} from '../../base/utils/time'
import prettyMilliseconds from 'pretty-ms'

const useStyles = makeStyles((theme)=>({
	token:{
		lineBreak: 'anywhere'
	},
	heading:{
		padding:'20px 0 10px'
	},
	rule:{
		marginTop: 7,
		alignItems:'flex-end'
	}
}))

function Detail ({record, loading}){
	const classes = useStyles()
	const theme = useTheme()
	//console.log(1111, record)
	
	if(!record || loading) return false	

	const usage = record.usageInfo.getSensors

	const toTime = (ms)=>{
		return prettyMilliseconds(ms, {separateMilliseconds:true})
	}

	return <Box vert grow hidden>
		<SceneHeader 
			title={`${config.recordTypeDisplayName} ${record[config.primaryAttribute]}`} 
			back={config.recordSetTypeDisplayName} 
		/>
		<Box vert hidden margin={20}>
			<Box hidden hor>
				<Box vert hidden grow padding={20}>
					<SensorMiniList query={{tenant:record.id}} />
				</Box>
				<Box vert hidden grow padding={20}>
					<UserMiniList title={'Primary Users'} query={{tenant:record.id}} />
				</Box>
				<Box vert hidden grow padding={20}>
					<UserMiniList title={'User Rights'} query={{tenants:{$in:record.id}}} />
				</Box>
				<Box width={'40%'} vert  padding={20}>
					<Text center heading={3}>API Key Status</Text>
				     
		            <Box hor className={classes.rule} >
			           	<Text padding={'0 0 10px 0'}>{'Total Calls:'}</Text>
			           	<Text padding={'0 9px'} size={2} weight={200}>{usage.calls}</Text>
			        </Box>
			        <Text margin={'0 0 9px 0'} style={{color:theme.palette.text.muted}} size={.8}>{'total number of (successful) calls to the endpoint, all time'}</Text>
			        <Divider />
		            
		            <Box hor className={classes.rule} >
			           	<Text padding={'0 0 10px 0'}>{'Max Execution Time:'}</Text>
			           	<Text padding={'0 9px'} size={2} weight={200}>{usage.execMax>0 && toTime(usage.execMax)}</Text>
			        </Box>
			        <Text margin={'0 0 9px 0'} style={{color:theme.palette.text.muted}} size={.8}>{'maximum execution time in ms, all time'}</Text>
			        <Divider />

			        <Box hor className={classes.rule} >
			           	<Text padding={'0 0 10px 0'}>{'Total Execution Time:'}</Text>
			           	<Text padding={'0 9px'} size={2} weight={200}>{usage.execTot>0 && toTime(usage.execTot)}</Text>
			        </Box>
			        <Text margin={'0 0 9px 0'} style={{color:theme.palette.text.muted}} size={.8}>{'total execution time spent in the endpoint, all time'}</Text>
			        <Divider />

			        <Box hor className={classes.rule} >
			           	<Text padding={'0 0 10px 0'}>{'Last Called:'}</Text>
			           	<Text padding={'0 9px'} size={2} weight={200}>{usage.lastCalled && Moment(usage.lastCalled).format('MMM D YYYY, h:mm:ss a')}</Text>
			        </Box>
			        <Text margin={'0 0 9px 0'} style={{color:theme.palette.text.muted}} size={.8}>{'time this endpoint was last called'}</Text>
			        <Divider />

			        <Box hor className={classes.rule} >
			           	<Text padding={'0 0 10px 0'}>{'Last Execution Time:'}</Text>
			           	<Text padding={'0 9px'} size={2} weight={200}>{usage.lastExec>0 && toTime(usage.lastExec)}</Text>
			        </Box>
			        <Text margin={'0 0 9px 0'} style={{color:theme.palette.text.muted}} size={.8}>{'execution time for the last call to this endpoint'}</Text>
			        <Divider />
				        		
				</Box>
			</Box>
		</Box>
	</Box>
}

export default withDetailScene(Detail)