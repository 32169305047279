import React, {useState} from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles'
import ListSwatch from './ListSwatch'
import Section from './Section'
import Chip from '@material-ui/core/Chip';
import CollapseIconButton from './CollapseIconButton'
import Collapse from '@material-ui/core/Collapse';
import color from 'color'
import { FixedSizeList as List } from 'react-window';
import withDims from '../../base/hocs/withDims'

const useStyles = makeStyles(theme=>({
	heading:{
		marginBottom:15
	},
	section:{
		borderBottom:`1px solid ${theme.palette.text.disabled}`,
		paddingBottom:15,
		marginBottom:15
	},
	text: {
		fontWeight:900,
		fontSize:'1.15rem',
		color: theme.type==='dark'?theme.palette.secondary.darkest:theme.palette.secondary.lightest,
		lineHeight: '1rem'
	},
	chip:{
		backgroundColor: color(theme.palette.text.primary).alpha(.5).toString(),
		height:26,
		'&>span':{
			paddingRight:8,
			paddingLeft:8
		}
	}
}))

function FeaturesOverview ({data, dimensions}){
	const styles = useStyles()
	const [collapsed, setCollapsed] = useState(true)

	const features = data.m_Nano.m_NanoConfig.Features.map((f,i)=>{
		return [{
			label:"#",
			value:i+1
		},{
			label:"Min",
			value:f.minVal.toFixed(5)
		},{
			label:"Max",
			value:f.maxVal.toFixed(5)
		},{
			label:"Weight",
			value:f.weight
		}]
	})

	const count = <Chip className={styles.chip} label={<Text className={styles.text}>{features.length}</Text>} />

	const swatchW = 190
	const width = dimensions.width-40
	const cols = Math.floor(width/swatchW)
	const rows = Math.ceil(features.length/cols)
	
	var colsAry = [] 
	for(var i=0; i<cols; i++){
		colsAry.push([i])
	}

	const Row = ({ index, style }) => (
  		<div key={index} style={style}>
  			<Box centerHor hor>{colsAry.map((col,i)=>{
  				const c = (index*cols)+i
  				if(!features[c]) return false
  				return <ListSwatch key={c} list={features[c]} width={swatchW} />
  			})}
  			</Box>
  		</div>
	); 

	return <Section title="Features" post={count} pre={<CollapseIconButton collapsed={collapsed} onChange={()=>setCollapsed(!collapsed)} />}>
			<Collapse in={!collapsed}>
				<List
					height={300}
					itemCount={rows}
					itemSize={136}
					width={width}
				>
				{Row}
				</List>				
			</Collapse>
	</Section>
}

export default withDims(FeaturesOverview)



 
