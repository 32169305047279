import agent from 'superagent'
import {authServer} from '../config'
import {handleError, handleResolve} from '../base/utils/http'
import {dispatch} from '../base/bus'
import {getToken} from '../admin/auth/store'
import {clientEndpoint} from './config'

const recVo = (res)=>{
  recTrans(res.body.record)
  return res.body
}

export const recTrans = (rec)=>{
  if(rec.avatar){
    rec.avatar=`${authServer}${rec.avatar.http}?token=${getToken()}`
  }
}

const paramsBuilder = (req, rec)=>{
  const {avatar, ...params} = rec
  console.log('avatar', avatar)
  if(avatar && avatar.constructor===File){
    req = req.attach('avatar', avatar)
  }
  Object.keys(params).forEach((k)=>{
    req = req.field(k,params[k])
  })
  return req
}
  
export const findRecordById = (id) =>
  new Promise((resolve, reject) => {
    agent.get(`${authServer}/api/auth/user`)
    .set('x-access-token',getToken())
    .then(handleResolve(resolve, {vo:(res)=>{
      const user = res.body
      if(user.avatar){
        user.avatar=`${authServer}${user.avatar.http}?token=${getToken()}`
      }
      return {record:user}
    }}))
    .catch(handleError(reject))
  })

export const updateRecord = (id, params) =>
  new Promise((resolve, reject) => {
    var req = agent.put(`${authServer}/api/auth/user`)
    .set('x-access-token',getToken())
    req = paramsBuilder(req, params)
    req.then(handleResolve(resolve, {vo:(res)=>{
      const user = res.body
      if(user.avatar){
        user.avatar=`${authServer}${user.avatar.http}?token=${getToken()}`
      }
      return {record:user}
    }}))
    .catch(handleError(reject))
  })