import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ListSwatch from './ListSwatch'
import CheckedIcon from '@material-ui/icons/Check'

const useStyles = makeStyles(theme=>({
	icon:{
		marginRight:-7
	}
}))

function Autotune ({data, width=300}){
	const styles = useStyles()

	const Autotune = [{
			label:"Retries",
			value: data.m_AmberStatus.m_RetryCount
		},{
			label:"Streaming Window",
			value: data.m_AmberStatus.m_StreamingWindowSize
		},{
			label:"PV",
			value: data.m_Autotune.m_AP.m_AutotunePV===true?<CheckedIcon className={styles.icon} />:''
		},{
			label:"Range",
			value: data.m_Autotune.m_AP.m_AutotuneRange===true?<CheckedIcon className={styles.icon} />:''
		},{
			label:"By Feature",
			value: data.m_Autotune.m_AP.m_AutotuneByFeatures===true?<CheckedIcon className={styles.icon} />:''
		}]

	return <ListSwatch title="Autotune" list={Autotune} width={width} />
}

export default Autotune