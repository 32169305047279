import React from 'react'
import Text from './Text'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

const colors = [
	'rgb(237,48,321)',
	'rgb(180,216,240)',
	'rgb(229,240,58)',
	'rgb(163,255,55)',
	'rgb(140,0,234)',
	'rgb(110,108,236)',
	'rgb(255,180,97)',
	'rgb(129,176,101)',
]

const useStyle = makeStyles({
	root: {
	    position: 'absolute',
	    left: '50%',
	    top: '50%',
	},
	loader: {
	    position: 'absolute',
	    left: ({size})=> `-${size/2}px`,
	    top: ({size})=> `-${size/2}px`,
	    height: ({size})=> `${size}px !important`,
	    width: ({size})=> `${size}px !important`,
	},
	loaderText: {
		position: 'absolute',
	    textAlign: 'center',
	    width: '110px',
	    left: '-55px',
	    transform: 'translateY(-50%)'
	},
	loaderTitle: {
		textAlign: 'center',
    	fontSize: '16px',
    	textShadow: '0px 0px 15px black !important'
	},
	loaderNumber: {
		textAlign: 'center',
    	fontSize: '24px',
    	textShadow: '0px 0px 15px black !important'
	}
})

function Loader ({text, loading, size=120, count}) {

	const style = useStyle({size})

	if(!loading) return false

	const color = colors[loading-1]

	return (<div className={style.root}>
  		<CircularProgress className={style.loader} style={{'color':color}} />
  		<Text className={style.loaderText}>
  			{text && <Text className={style.loaderTitle}>{text}</Text>}
  		</Text>
  	</div>)
}

export default Loader
