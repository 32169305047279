export const defaultFieldValueFactory = (field)=>{
	if(field.default === undefined){
		if(field.type==='string' || field.type==='password'){
			return ''
		}
		//if(field.type==='number'){
		//	return 0
		//}
		//if(field.type==="boolean" || field.type==="date" || field.type==='number'){
			return null
		//}
	}
	return field.default
}

export const modelFormFieldValues = (record, fields)=>{
	
	var defaultFields = {}
	fields.forEach((field)=>{
		defaultFields[field.field] = defaultFieldValueFactory(field)
	})
	return fieldValues(record, defaultFields)
}

export const fieldValues = (record, fields)=>{
	var obj={}
	Object.keys(fields).forEach((k)=>{
		obj[k] = record && record[k] !== undefined ? record[k] : fields[k]
	})
	return obj
}