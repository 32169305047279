import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

function Text ({ heading, color, truncate, weight, lighter, darker, 
	style={}, center, inline, tag='div', className, children,
	subTitle, body, caption, overline, size, caps, margin, padding
}) {
	const theme = useTheme()

	var root = {}

	if(truncate){
		root.textOverflow = 'ellipsis'
    	root.overflow = 'hidden'
    	root.whiteSpace = 'nowrap'
	}

	if(margin){
		root.margin=margin
	}

	if(padding){
		root.padding=padding
	}

	if(lighter){
		root['color'] = `rgba(${theme.palette.type==='dark'?'0,0,0':'255,255,255'}, ${lighter})`
	}
	else if(darker){
		root['color'] = `rgba(${theme.palette.type==='dark'?'255,255,255':'0,0,0'}, ${darker})`
	}

	if(weight){
		root['font-weight'] = weight
	}

	if(size){
		root['font-size'] = size+'rem'
	}
	
	if(inline){
		root.display='inline'
	}

	if(caps){
		root.textTransform='uppercase'
	}

	const styles = makeStyles({
		root
	})()

	var props = {
		style: style,
		component: tag,
		color: color || 'textPrimary'
	}

	if(heading){
		props.variant = 'h'+heading
		props.component = 'h'+heading
	}
	else if(subTitle) {
		props.variant='subTitle'+subTitle
	}
	else if(body){
		props.variant='body'+body
	}
	else if(caption){
		props.variant='caption'
	}
	else if(overline){
		props.variant='overline'
	}

	props.className=styles.root
	if(className){
		props.className = props.className+' '+className
	}

	if(center){
		props.align = 'center'
	}

	return <Typography {...props} >{children}</Typography>
}

export default Text