import React from 'react'
import Box from '../../base/ui/Box'
import JSONView from '../../base/ui/JSONView'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Overview from "./Overview"
import Status from "./Status"
import FeaturesStack from './FeaturesStack'
import CarouselNavigator from './CarouselNavigator'

function CarouselItemDetail ({record, detail, setView, tab='stack', goToPrevious, goToNext}){

	return <Box vert grow hidden>
			<Box hor centerVert>
				<Box vert grow>
					{detail && detail.m_Nano && <Tabs
				        value={tab}
				        indicatorColor="secondary"
				        textColor="secondary"
				        onChange={(e,value)=>setView(value)}
				    > 
				    	<Tab value="overview" label="Overview" />
				        <Tab value="stack" label="Features Stack" />
				        <Tab value="data" label="Data" />
				    </Tabs>}
				</Box>
				<Box vert grow>
					{detail && detail.m_Nano && <Status data={detail} />}
				</Box>
				<Box vert center grow>
					<CarouselNavigator goToNext={goToNext} goToPrevious={goToPrevious} />
				</Box>
		    </Box>

			{detail && detail.m_Nano && <Box vert scroll margin={20}>
				{tab==='overview' && <Overview data={detail}  />}
				{tab==='stack' && <FeaturesStack data={detail}  />}
				{tab==='data' && <JSONView data={detail}  />}
			</Box>}
	</Box>
}

export default CarouselItemDetail