import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import Link from '../../base/ui/Link'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import withUser from '../../admin/auth/withUser'
import EditIcon from '../../base/ui/EditIcon'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import IconButton from '../../base/ui/IconButton'
import moment from 'moment'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)
      this.props.setTable(this.setTable())
    }

    setTable=()=>{

      var columns =[{
        ...fields.name,
        cell:({record, index, value}) => {
          return (<Box centerVert hor>
            <Link block to={`${config.recordsPath}/${record.id}`} >{value}</Link>  
          </Box>)
        }
      },{
        ...fields.description
      },{
        ...fields.createdAt,
        width: 225,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY')}</Text>  
        )
      }]

      if(this.props.getPerm(`+:${config.recordTypeName}:update`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <IconButton onClick={()=>{
            this.props.history.push(`${config.recordsPath}/${record.id}/edit`)
          }} ><EditIcon /></IconButton> 
        )
      })

      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.name}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })
     
      return {
        columns,
        facets: [{
          ...fields.id,
          field:'_id'
        },{
          ...fields.name
        }]
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords