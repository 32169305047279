import React from 'react'
import {withRouter} from "react-router-dom";
import {findRecordByIdPopulated} from './services'

export const withDetailScene = (Comp) => {
  
  class Cls extends React.Component {
  	
  	constructor(props){
  		super(props)

  		findRecordByIdPopulated(this.props.match.params.id).then((res)=>{
  			this.setState({user:res.record})
  		})
  	}

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withRouter(Cls)
}

export default withDetailScene
