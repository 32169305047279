import React, {useState} from 'react'
import Box from './Box'
import Text from './Text'
import IconButton from './IconButton'
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from './Dialog'

function BulkActionsMenu ({selected,  records, bulkActions}){
	const [opened, setOpened] = useState(false)

	if(!selected || selected.length===0) return false

	const list = <List component="nav">
        {bulkActions.map((action,i)=>{
        	const selectedRecords = records.filter((r)=>selected.includes(r.id)?true:false)
        	return <ListItem key={i} button onClick={()=>{
        		action.action(selectedRecords)
        		setOpened(false)
        	}}>
	          {action.Icon && <ListItemIcon>
	            <action.Icon />
	          </ListItemIcon>}
	          <ListItemText primary={action.name} />
        </ListItem>})}
    </List>

	return <Box margin={'-5px 0 0 -5px'}>
				<IconButton onClick={()=>setOpened(true)}>
					<Badge badgeContent={selected.length} color={'primary'}>
						<MenuOpenIcon color={'primary'} />
					</Badge>
				</IconButton>
				<Dialog 
					open={opened}  
					handleClose={()=>setOpened(false)} 
				>{list}</Dialog>		
			</Box>
}

export default BulkActionsMenu