import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from '../base/ui/Text'
import Box from '../base/ui/Box'
import Card from '../base/ui/Card'
import Button from '../base/ui/Button'
import withUser from '../admin/auth/withUser'
import ActionForm from './ActionForm'
import ImageAvatar from '../base/ui/ImageAvatar'
import {getSelectedTheme, showBackgroundImage, getLoader} from './store'
import IconButton from '../base/ui/IconButton'
import useEventStore from '../base/hooks/useEventStore'
import {useParams, useHistory} from 'react-router-dom'
import Footer from '../ui/Footer'
import SwitchField from '../base/ui/SwitchField'
import RupeeLoader from '../base/ui/RupeeLoader'
import PlasmaBar from '../base/ui/PlasmaBar'
import CircularLoader from '../base/ui/Loader'

const useStyle = makeStyles(theme=>({
	root: {
		height:'100%'
	},
	name: {
		margin:7,
		color: theme.palette.common.white
	},
	role: {
		color: theme.palette.common.white
	},
	content: {
		padding: '150px 0px 20px 0px',
	},
	header: {
		backgroundColor: theme.palette.primary.dark,
		paddingBottom:20,
		borderBottom: `10px solid ${theme.palette.secondary.dark}`,
		zIndex:111
	},
	avatar: {
		margin:'-100px 20px 20px 20px'
	},
	perm: {
		margin:'5px 20px',
		width:200
	},
	swatch: {
		overflow:'hidden',
		height:80,
		width:80,
		borderRadius:8,
		border: theme.palette.type==='dark'?'1px solid rgba(255,255,255,.2)':`1px solid rgba(0,0,0,.2)`,
	},
	card:{
		display:'flex',
		flexDirection:'column',
		flex:1,
		margin:10
	},
	text:{
		color: theme.palette.type==='dark'?'rgba(255,255,255,.42)':`rgba(0,0,0,.4)`,
	},
	activeText:{
		color: theme.palette.secondary.main,
	},
	activeSwatch:{
		border: `1px solid ${theme.palette.secondary.main}`
	}
}))

function Profile ({user, signOut}) {

	const params = useParams()
	const history = useHistory()
	const style = useStyle()
	const [selectedTheme, setSelectedTheme] = useEventStore('selected-theme-change', getSelectedTheme)
	const [showBackground, changeBackground] = useEventStore('show-background-image-change', showBackgroundImage)
	const [loader, changeLoader] = useEventStore('selected-loader-change', getLoader)
	const [loadingPreview, setLoadingPreview] = useState(false)

	if(!user) return false

	const changeTheme = (name)=>{
		setSelectedTheme(name)
	    window.location.reload()
	}

	const logoutAction = () =>{
		signOut()
	}

	const actions = <Box hor childMargin={5}>
		<Button onClick={logoutAction}>Log Out</Button>
		<Button to={`/admin/profile/${user.id}/edit`}>Edit</Button>
	</Box>

	return (<Box vert hidden grow className={style.root}>	
			<Box vert hidden grow className={style.content}>
				<Box vert  center className={style.header}>
					<Box vert className={style.avatar} center>
						<ImageAvatar to={`/admin/profile/${user.id}/edit`} size={200} src={user.avatar} />
					</Box>
					<Text className={style.name} heading={1}>{user.name}</Text>
					<Text className={style.role} heading={4}>{user.roles[0].name}</Text>
				</Box>
			</Box>
			<Box vert hidden grow >
				<Box vert scroll grow between>
					<Box margin={10} hor>
						<Card className={style.card} title={'Permissions'}>	
							<Box hor wrap>	
								{user.roles[0].perms.map((perm)=>{
									return<Text key={perm.name} className={style.perm} weight={300}>{perm.name}</Text>
								})}
							</Box>
						</Card>
						<Card className={style.card} title={'Settings'}>
							<Text>Theme</Text>
							<Box vert>
								<Box hor>
									<IconButton onClick={()=>changeTheme('light')}>
										<Box className={style.swatch+' '+(selectedTheme==='light'? style.activeSwatch:'')} vert center>
											<Text className={selectedTheme==='light'? style.activeText:style.text}>Light</Text>
										</Box>
									</IconButton>
									<IconButton onClick={()=>changeTheme('dark')}>
										<Box className={style.swatch+' '+(selectedTheme==='dark'? style.activeSwatch:'')} vert center>
											<Text className={selectedTheme==='dark'? style.activeText:style.text}>Dark</Text>
										</Box>
									</IconButton>
									<Box vert>
										<SwitchField label="Show Background Image" value={showBackground} onChange={(e,v)=>{changeBackground(v)}} />
									</Box>
								</Box>
								<Box hor>
									<IconButton onClick={()=>changeLoader('rupee')}>
										<Box className={style.swatch+' '+(loader==='rupee'? style.activeSwatch:'')} vert center>
											{loadingPreview && <RupeeLoader loading={1} size={4} />}
											{!loadingPreview && <Text className={loader==='rupee'?  style.activeText:style.text}>Rupee</Text>}
										</Box>
									</IconButton>
									<IconButton onClick={()=>changeLoader('circular')}>
										<Box className={style.swatch+' '+(loader==='circular'? style.activeSwatch:'')} vert center>
											{loadingPreview && <CircularLoader loading={1} size={66} />}
											{!loadingPreview && <Text className={loader==='circular'?  style.activeText:style.text}>Circular</Text>}
										</Box>
									</IconButton>
									<IconButton onClick={()=>changeLoader('colorbar')}>
										<Box className={style.swatch+' '+(loader==='colorbar'? style.activeSwatch:'')} vert center>
											{loadingPreview && <PlasmaBar />}
											{!loadingPreview && <Text className={loader==='colorbar'?  style.activeText:style.text}>Plasma Bar</Text>}
										</Box>
									</IconButton>
									<Box vert>
										<SwitchField label="Show Preview of loaders" value={loadingPreview} onChange={(e,v)=>{setLoadingPreview(v)}} />
									</Box>
								</Box>
								
							</Box>
						</Card>
					</Box>
					
					
				</Box>
			</Box>
			<Footer actions={actions} />
			{params.action && <ActionForm onClose={()=>history.push('/admin/profile')} />}
		</Box>)
}

export default withUser(Profile)