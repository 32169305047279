import React from 'react'
import TextFormField from '../../base/ui/TextFormField'
import ActionFormBase from '../../base/ui/ActionForm'
import {recordTypeDisplayName} from './config'
import withRecordForm from '../../base/hocs/withRecordForm'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import SwitchesField from '../../base/ui/SwitchesField'
import withPermissions from '../permissions/withPermissions'
import { useFormik } from 'formik'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete, perms,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

	const form = useFormik({
		enableReinitialize: true,
		
		validate: async function(values){
	        var errors = {}

	        if(await required(values.name)) {
	          errors.email="Please input a Name"
	        }

	        if(Object.keys(errors).length){
	          return errors
	        }else{
	          return
	        }
	    },

	    initialValues: {
			name: record?record.name:'',
			description: record?record.description:'',
			perms: record?record.perms:[]
		},

		onSubmit: onSubmit
	})

	return <ActionFormBase 
		recordTypeDisplayName={recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<TextFormField
			id="name"
			label="Name"
			form={form}
			autoFocus={true}	
	    />

	    <TextFormField
			form={form}
			id="description"
			label="Description"
			multiline
	    />

	    <SwitchesField 
	    	label="Permissions" 
	    	id="perms"
	    	onChange={form.handleChange}
	    	options={perms.map((r)=>({value:r.id, name:r.name}))}
	    	error={form.errors['perms']}
	    	value={form.values['perms'].map((r)=>r.id||r)}
	    />

	</ActionFormBase>
}


export default withPermissions(withRecordForm(ActionForm, {config, store, services}))