import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toSlugCase } from '../utils/string'

const useStyles = makeStyles(theme=>({
	field: {
		margin:'10px 5px',
		color: theme.palette.text.input
	}
}))

function TextFormField ({form, id, slugify, ...rest}){

	const styles = useStyles()

	const [showPassword, setShowPassword] = React.useState(true);

	const { errors, setValues, handleBlur, touched, handleChange, values} = form

	const handleClickShowPassword = () => {
	    setShowPassword(!showPassword);
	}

	const handleMouseDown = (event) => {
	    event.preventDefault();
	}

	const onChange = (e)=>{
		if(slugify){
			setValues({...values, [id]:e.target.value, [slugify]:toSlugCase(e.target.value)})
		}else{
			handleChange(e)
		}
	}

	var props = {}
	if(rest.type&&rest.type==='password'){

		return <TextField 
			id={id}
			error={(touched[id] && errors[id])?true:false}
			helperText={errors[id]}
			value={values[id]}
			autoComplete={id}
			onChange={handleChange}
			onBlur={handleBlur}
			className={styles.field}
			{...rest}
			type={showPassword?'password':'text'}
			InputProps={{endAdornment:<InputAdornment position="end">
		        <IconButton
		          onClick={handleClickShowPassword}
		          onMouseDown={handleMouseDown}
		        >
		          {showPassword ? <Visibility /> : <VisibilityOff />}
		        </IconButton>
		      </InputAdornment>}}
	    />
	}

	return <TextField 
		id={id}
		error={(touched[id] && errors[id])?true:false}
		helperText={errors[id]}
		value={values[id]}
		autoComplete={id}
		onChange={onChange}
		onBlur={handleBlur}
		className={styles.field}
		{...rest}
    />
}


export default TextFormField