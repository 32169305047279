import React from 'react'
import Header from './Header'
import Box from '../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color'

const useStyles = makeStyles(theme=>({
    'root':{
      background: `linear-gradient(180deg, ${theme.palette.background.body} 20%, ${Color(theme.palette.background.body).darken(.2).alpha(.25).toString()} 100%)`
    }
}));

function Frame ({children}) {
	const styles = useStyles()

	return (<Box vert className={styles.root} hidden grow>
		<Header /> 
		<Box vert grow hidden>
      		{children}
      	</Box>
	</Box>)
}

export default Frame