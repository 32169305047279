import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Text from './Text'
import Link from './Link'
import Box from './Box'
import InputLabel from '@material-ui/core/InputLabel';

function SwitchesField ({label, test="SwitchesField", disabled,
	hint, error, value=[], style={}, options=[], onChange, id
}) {

	const classes = makeStyles(theme => ({
	  formControl: {
	    margin: '20px 5px 10px',
	  },
	  link: {
	  	padding:3
	  }
	}))()

	const all = options.length===value.length

	return (<Box vert style={style} className={classes.formControl}>
	    <Box hor centerVert between>
		    {label && <InputLabel>{label}</InputLabel>}

		   	{!all && <Link className={classes.link} onClick={()=>{
		   		const items = options.map((o)=>o.value)
		   		onChange({target:{id:id, name:id, value:items}})
		   	}}>Select All</Link>}

		   	{all && <Link className={classes.link} onClick={()=>{
		   		onChange({target:{id:id, name:id, value:[]}})
		   	}}>Select None</Link>}
	   	</Box>

	   	{error && <FormHelperText>{error}</FormHelperText>}
	   	{hint && <FormHelperText>{hint}</FormHelperText>}
	   	
	   	<FormGroup >
	    {options.map((opt,i)=>{
	      	return (<FormControlLabel
	      		key={opt.value}
				control={<Switch 
					color="primary"
					disabled={disabled}
					onChange={(event)=>{
						const items = event.target.checked 
							? [...value, opt.value]  
							: value.filter((v)=>v===opt.value?false:true)
						onChange({target:{id:id, name:id, value:items}})
					}}
					checked={(value.find((v)=>v===opt.value)?true:false)}  />}
				label={<Text>{opt.name}</Text>} />)
	  	})}
	    </FormGroup>
	</Box>)
}

export default SwitchesField