import React from 'react'
import Facets from './Facets'
import withDims from '../hocs/withDims'
import DataGrid from './DataGrid'
import Box from './Box'
import Text from './Text'


function SceneGrid ({
	dimensions, 
	height,
	width,
	siderWidth=0,
	columns,
	records,  
	sorter,
	headers,
	title,
	facetProps,
	bulkActions,
	setFilters,
	onItemsRendered,
	uid,
}) {

	const gwidth = width || dimensions.width-40-(facetProps && facetProps.vert?300:0)-siderWidth
	const gheight = height || dimensions.height-84-64-64 - (facetProps && facetProps.hor?64:0)
	var twidth = 0
	var noWidth = 0
	var dwidth;
	var columnsWidths = columns

	columns.forEach((col)=>{
		if(col.width) {
			twidth = twidth+col.width
		}else{
			noWidth++
		}
	})

	if(bulkActions && bulkActions.length){
		twidth = twidth + 120
	}	

	if(noWidth>0){
		dwidth = (gwidth-twidth)/noWidth
		columnsWidths = columns.map((col)=>{
			if(col.width) return col
			return {...col, width:dwidth}
		})
	}

	const grid = (<DataGrid
		height={gheight}
		width={gwidth}
		headers={headers}
		columns={columnsWidths}
		records={records}
		bulkActions={bulkActions}
		sorter={sorter}
		uid={uid}
		onItemsRendered={onItemsRendered}
	/>)

	if(facetProps && facetProps.hor){
		return (<Box centerHor vert>
			<Box vert style={{width:gwidth}}>
				<Facets {...facetProps} />
				{grid}
			</Box>
		</Box>)
	}

	if(facetProps && facetProps.vert){
		return (<Box hor>
			<Box centerHor vert style={{width:gwidth+40}}>
				{title && <Text heading={1}>{title}</Text>}
				{grid}
			</Box>
			<Facets {...facetProps} />
		</Box>)
	}

	return (<Box centerHor vert>
		{title && <Text heading={1}>{title}</Text>}
		{grid}
	</Box>)
}


export default withDims(SceneGrid)