import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function SelectField ({label, id, test="SelectField", disabled, empty, helperText, hint, error, value='', style={}, options=[], onChange, open, onOpen, onClose}) {

	const classes = makeStyles(theme => ({
	  formControl: {
	    minWidth: 200,
	  },
	  selectEmpty: {
	    marginTop: theme.spacing(2),
	  },
	  error:{
	  	color: theme.palette.error.main
	  }
	}))()

	return (<FormControl style={style} className={classes.formControl}>
	    {label && <InputLabel>{label}</InputLabel>}
	    <Select
	      id={id}
	      name={id}
	      test={test}
	      MenuProps={{
	      	test:`${test}-options`
	      }}
	      value={value}
	      onChange={onChange}
	      open={open}
	      onOpen={onOpen}
	      disabled={disabled}
	      onClose={onClose}
	    >
	      {empty && <MenuItem key='option-empty' value="">
            <em>{empty}</em>
          </MenuItem>}
	      {options.map((opt,i)=>{
	      	return (<MenuItem
		      	disabled={opt.disabled} 
		      	key={'option'+i} 
		      	value={opt.value}
		      >
		      	{opt.label}
		    </MenuItem>)
	  	})}
	    </Select>
	    {error && <FormHelperText className={classes.error}>{error===true?helperText:error}</FormHelperText>}
	   	{hint && <FormHelperText>{hint}</FormHelperText>}
	</FormControl>)
}

export default SelectField