import agent from 'superagent'
import {authServer} from '../../config'
import {handleError, handleResolve} from '../../base/utils/http'
import {dispatch} from '../../base/bus'
import {getToken} from '../auth/store'
import {clientEndpoint} from './config'

export const getRecords = (query={}) =>
  new Promise((resolve, reject) => {
    dispatch('global-loading',true)
    agent.get(`${authServer}/api/collection/${clientEndpoint}/find`) 
    .set('x-access-token',getToken())
    .query({...query, populate:'user'})
    .then(handleResolve(resolve, {loader:true}))
    .catch(handleError(reject, {loader:true}))
  })

export const findRecordById = (id) =>
  new Promise((resolve, reject) => {
    dispatch('global-loading',true)
    agent.get(`${authServer}/api/collection/${clientEndpoint}/find/${id}`)
    .set('x-access-token',getToken())
    .then(handleResolve(resolve, {loader:true}))
    .catch(handleError(reject, {loader:true}))
  })

export const createRecord = (params) =>
  new Promise((resolve, reject) => {
    agent.post(`${authServer}/api/collection/${clientEndpoint}`)
    .set('x-access-token',getToken())
    .send(params)
    .then(resolve)
    .catch(handleError(reject))
  })

export const updateRecord = (id, params) =>
  new Promise((resolve, reject) => {
    agent.put(`${authServer}/api/collection/${clientEndpoint}/id/${id}`)
    .set('x-access-token',getToken())
    .send(params)
    .then(resolve)
    .catch(handleError(reject))
  })

export const deleteRecord = (record) =>
  new Promise((resolve, reject) => {
    agent.delete(`${authServer}/api/collection/${clientEndpoint}/id/${record.id}`)
    .set('x-access-token',getToken())
    .then(handleResolve(resolve))
    .catch(handleError(reject))
  })
