
export const toSlugCase = (str) => {
  return str.replace(/([A-Z])/g,(x)=>(` ${x}`)).trim().replace(/\s+/g,'-').toLowerCase()
}

export const toCamelCase = (str) => {
  return str.replace(/-/g, ' ').split(' ').map((s,i)=>(s.charAt(0)[i===0?'toLowerCase':'toUpperCase']() + s.slice(1))).join('')
}

export const toSpaceCase = (str) => {
  return toSlugCase(str).split('-').map((s,i)=>(s.charAt(0).toUpperCase() + s.slice(1))).join(' ')
}