import React from 'react'
import Box from '../../base/ui/Box'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
	root: {
      	background: theme.palette.background.tooltip,
      	borderRadius:9,
      	padding:'5px  10px 5px 10px',
      	overflow:'hidden',
      	border:`1px solid ${theme.palette.secondary.main}`
	}
}))

function ChartTipBase ({ active, payload, children}){
	const styles = useStyles()

	if (active && payload && payload.length) {
		return <Box vert className={styles.root+" custom-tooltip"} >
	        {children}
	      </Box>
	}

	return null
}

export default ChartTipBase