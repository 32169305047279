import {useState} from 'react';

export default function (props={}) {

  const [open, setOpen] = useState(false);

  const onCancel = () => {
    setOpen(false)
    if(props.onCancel) props.onCancel()
  }

  const onConfirm = () => {
    setOpen(false)
    if(props.onConfirm) props.onConfirm()
  }

  const onOpen = () => {
    setOpen(true)
    if(props.onOpen) props.onOpen()
  }

  return {open, onOpen, onConfirm, onCancel}

}