
var stack = {}

module.exports = {

  dispatch: function (event, message) {
    if (!stack[event]) stack[event] = []
    console.log(`Dispatching: ${event} to ${stack[event].length} Subscribers`, message===undefined?'':message)
    stack[event].forEach((regfn) => {
      regfn(message)
    })
  },

  on: function (event, fn) {
    if (!stack[event]) stack[event] = []
    stack[event].push(fn)

    return () => {
      stack[event] = stack[event].filter((regfn) => {
        return regfn !== fn
      })
    }
  }

}
