import React, {useState, useEffect} from 'react'
import ActionFormBase from '../../base/ui/ActionForm'
import {recordTypeDisplayName} from './config'
import withRecordForm from '../../base/hocs/withRecordForm'
import {required} from '../../base/utils/validate'
import store from './store'
import * as services from './services'
import * as config from './config'
import { useFormik } from 'formik'
import {modelFormFieldValues} from '../../base/utils/form'
import FormFieldsModelFactory from '../../base/ui/FormFieldsModelFactory'
import {getRecords as tenantRecords} from '../../amber/tenants/services'
import withUser from '../../admin/auth/withUser'

function ActionForm ({ 
	onSubmit, onClose, title, onDelete, user,
	setFailureMessage, failureMessage, infoMessage, 
	isRecordUpdating, isRecordLoading, record, action
}) {

	const [tenantList, setTenantList] = useState([])
	useEffect(()=>{
		tenantRecords()
		.then((res)=>{
			if(res.records){
				setTenantList(res.records.map((rec)=>{
					return {value:rec.id, label:rec.name}
				}))
			}
		})	
	},[])

	const validate = async function(values){
        var errors = {}

        if(await required(values.name)) {
          errors.name="Please input a name for your API key"
        }

        if(await required(values.tenant)) {
          errors.tenant="Please choose a tenant for your API key"
        }

        if(Object.keys(errors).length){
          return errors
        }else{
          return
        }
    }

    const createFields = [{
			...config.fields.name,
			autoFocus:true
		},{
			...config.fields.tenant,
			options: tenantList,
			default: user.tenant.id
		},{
			...config.fields.expires,
			name: 'Seconds until Expiration',
		},{
			...config.fields.perms
		}]

	const editFields = [{
			...config.fields.revoked
		}]

    const initialValues = modelFormFieldValues(record, action==='create'?createFields:editFields) 

	const form = useFormik({
		enableReinitialize: true,
		validate,
		initialValues,
		onSubmit
	})

	return  <ActionFormBase 
		recordTypeDisplayName={recordTypeDisplayName}
		title={title}
		action={action}
		failureMessage={failureMessage}
		infoMessage={infoMessage}
		onClose={onClose} 
		onDelete={onDelete}
	    form={form}
	>
		<FormFieldsModelFactory fields={action==='create'?createFields:editFields} form={form} />
	    
	</ActionFormBase>
}


export default withUser(withRecordForm(ActionForm, {config, store, services}))