import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import { makeStyles } from '@material-ui/core/styles';
import StatusIcon from "./StatusIcon"
import useEventStore from '../../base/hooks/useEventStore'
import {getSampleSize} from './store'

const useStyles = makeStyles(theme=>({
	root:{
		marginTop:15
	},
	value:{
		color: theme.palette.secondary.main,
		lineHeight: '40px'
	},
	box:{
		alignItems:"center",
		justifyContent:'flex-end',
		margin:'0 3px 0 3px'
	}
}))

function Status ({data}){
	const [sampleSize] = useEventStore('amber-sample-size-change', getSampleSize)
	const styles = useStyles()
	const status = data.m_AmberStatus.m_Status

	return <Box hor centerHor className={styles.root}>
				<Box className={styles.box} vert>
					<StatusIcon status={status} />
					<Text size={.7}>{status}</Text>
				</Box>
				<Box className={styles.box} vert>
					<Text heading={5} className={styles.value}>{data.m_AmberStatus.m_TotalInferences}</Text>
					<Text size={.7}>Total Inferences</Text>
				</Box>
				{sampleSize!==false && <Box className={styles.box} vert>
					<Text heading={5} className={styles.value}>{sampleSize}</Text>
					<Text size={.7}>Sample Size</Text>
				</Box>}
			</Box>
}

export default Status