import React from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {getSelectedTheme} from '../profile/store'
import Color from 'color'
import GlobalStyle from './GlobalStyle'
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';

const themeName = getSelectedTheme()

const primaryColor = 'rgb(0,125,196)'
const secondaryColor = 'rgb(247,148,30)'
const darkestText = 'rgb(0,0,0)'
const lightestText = 'rgb(255,255,255)'
const darkestBackground = 'rgb(0,0,0)'
const lightestBackground = 'rgb(255,255,255)'

const secondary = {
  lightest: Color(secondaryColor).lighten(.80).toString(),
  light: Color(secondaryColor).lighten(.4).toString(),
  main: secondaryColor,
  dark: Color(secondaryColor).darken(.5).toString(),
  darkest: Color(secondaryColor).darken(.85).toString()
}

const primary = {
  lightest: Color(primaryColor).lighten(.75).toString(),
  light: Color(primaryColor).lighten(.4).toString(),
  main: primaryColor,
  dark: Color(primaryColor).darken(.5).toString(),
  darkest: Color(primaryColor).darken(.85).toString()
}

const rotation = [
  primary.main,
  secondary.main,
  red[500],
  lightGreen['A400'],
]

const textColor = themeName==='dark'? lightestText : darkestText
const contrastTextColor = themeName==='dark'? darkestText : lightestText
const backgroundColor = themeName==='dark'? darkestBackground : lightestBackground
const contrastBackgroundColor = themeName==='dark'? lightestBackground : darkestBackground
const contentBackgroundColor = Color(backgroundColor).mix(Color(contrastBackgroundColor), themeName==='dark'?.1:.02).toString()

const theme = createMuiTheme({
  palette: {
    primary: {...primary},
    secondary: {...secondary},
    type: themeName,
    text: {
      input: textColor,
      label: Color(textColor).alpha(.9).toString(),
      primary: Color(textColor).alpha(.7).toString(),
      disabled: Color(textColor).alpha(.2).toString(),
      muted: Color(textColor).alpha(.5).toString(),
      secondary: Color(textColor).alpha(.5).toString(),
    },
    rotation: rotation,
    background: {
      paper: backgroundColor,
      authForm: Color(backgroundColor).alpha(.6).toString(),
      header:  backgroundColor,
      footer: backgroundColor, 
      sidebar: `linear-gradient(180deg, rgba(0,0,0,0) 0%, ${Color(backgroundColor).darken(.05).toString()} 75%)`, 
      modelForm: Color(backgroundColor).darken(.03).toString(),
      modal: Color(backgroundColor).alpha(.6).toString(),
      tooltip: backgroundColor,
      body: contentBackgroundColor
    },
    shadow: {
      header: `0px -4px 20px 0px ${Color(contentBackgroundColor).darken(.1).toString()}`,
      sidebar: `-0px -4px 20px 0px ${Color(contentBackgroundColor).darken(.1).toString()}`
    },
    border:{
      dataGridRow: Color(contrastBackgroundColor).alpha(.2).toString()
    },
    icon: {
      active: primary.main,
      inactive: secondary.main,
    },
    action:{
      disabled: Color(textColor).alpha(.2).hex(),
    },
  },
  typography: {
    fontFamily:'Montserrat',
    fontSize: 14,
    h1:{
      fontSize: '2.25rem',
      fontWeight: 100,
      color: themeName==='dark'? Color(secondary.lightest).alpha(.7).toString() : Color(secondary.darkest).alpha(.7).toString(),
    },
    h2:{
      fontSize: '2rem',
      fontWeight: 100,
    },
    h3:{
      fontSize: '1.75rem',
      fontWeight: 300,
    },
    h4:{
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    h5:{
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6:{
      fontSize: '1rem',
      fontWeight: 700,
    },
    button: {
      fontWeight:500,
      fontSize:'1rem',
      textTransform: 'uppercase'
    },
    body1: {
      fontFamily:'Montserrat',
      fontSize:'1rem'
    },
    body2: {
      fontFamily:'Montserrat',
      fontSize:'1rem'
    }
  },
  shape: {
    borderRadius: 6
  }
})

function Theme({children}) {
  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyle>
        {children}
      </GlobalStyle>
    </MuiThemeProvider>
  );
}

export default Theme
