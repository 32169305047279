import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from './Box'

function SideBar ({
	children,
	width=300,
	className='',
	...box
}) {

	const style = makeStyles((theme)=>({
		sidebar: {
			background: theme.palette.background.sidebar,
			width: width,
			boxShadow: theme.palette.shadow.sidebar
		}
	}))()

	const clns = style.sidebar+' '+className

	return (<Box vert 
			{...box} 
			className={clns}>
		{children}
	</Box>)
}


export default SideBar