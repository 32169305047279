import React from 'react'
import useModelRecord from '../../base/hooks/useModelRecord'
import store from './store'
import * as services from './services'
import * as config from './config'
import {getToken} from '../auth/store'
import Box from '../../base/ui/Box'
import SceneHeader from '../../base/ui/SceneHeader'
import {authServer} from '../../config'

function Detail (){
	const [record, loading] = useModelRecord({store, config, services})
	
	if(!record || loading) return false

	return <Box vert grow hidden>
		<SceneHeader 
			title={`${config.recordTypeDisplayName} ${record[config.primaryAttribute]}`} 
			back={config.recordSetTypeDisplayName} 
		/>
		<Box vert scroll margin={20}>
			<img src={`${authServer}/api/files/${record.filename}?token=${getToken()}`} />
		</Box>
	</Box>
}


export default Detail