import crudScenesBuilder from '../../base/stores/crudScenesBuilder'
import {recordTypeName, recordSetTypeName, initialSorter, initialFilters, recordsPerPage} from './config'
import {on} from '../../base/bus'

var _samplesize=false

on('amber-sample-size-change', (v)=>{
	_samplesize=v
})

export const getSampleSize = ()=>{
	return _samplesize
}

export default crudScenesBuilder({
  recordTypeName: recordTypeName,
  recordSetTypeName: recordSetTypeName,
  initialSorter: initialSorter,
  filters: initialFilters,
  pager: {
    size: recordsPerPage,
    index: 0,
    type: 'endless'
  }
})


