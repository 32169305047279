import React from 'react'
import Box from '../../base/ui/Box'
import SceneHeader from '../../base/ui/SceneHeader'
import { makeStyles } from '@material-ui/core/styles'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {authServer} from "../../config"

const useStyles = makeStyles((theme)=>{
	if (theme.palette.type==='light') {
		return {}
	}
	return {
	'@global':{
		".swagger-ui .expand-methods svg, .swagger-ui .expand-operation svg":{
			fill:'#ffffff'
		},
		".swagger-ui .opblock .opblock-summary-operation-id, .swagger-ui .opblock .opblock-summary-path, .swagger-ui .opblock .opblock-summary-path__deprecated":{
			fontFamily:'monospace',
			fontWeight:100,
			color: 'rgba(255,255,255,.7)'
		},
		".swagger-ui, .swagger-ui .opblock .opblock-summary-description, .swagger-ui .opblock-description-wrapper p, .swagger-ui .opblock-external-docs-wrapper p, .swagger-ui .opblock-title_normal p, .swagger-ui table thead tr td, .swagger-ui table thead tr th, .swagger-ui .parameter__type":{
			fontFamily:'Montserrat',
			fontWeight:400,
			color: 'rgba(255,255,255,.8)'
		},
		".swagger-ui .parameter__name, .swagger-ui .response-col_status.col_header":{
			fontFamily:'Montserrat',
			fontWeight:400,
			color: 'rgba(255,255,255,.9)'
		},
		".swagger-ui .parameter__name, .swagger-ui .response-col_status":{
			color: theme.palette.secondary.main
		},
		".swagger-ui .opblock.opblock-post .opblock-summary, .swagger-ui .opblock.opblock-post":{
			border:'none'
		},
		".swagger-ui .tab li":{
			color: theme.palette.primary.light
		},
		".swagger-ui .info>div, .swagger-ui .info a, .swagger-ui .info .base-url":{
			fontFamily:'Montserrat',
			fontWeight:400,
			display:"none"
		},
		".swagger-ui .parameter__extension, .swagger-ui .parameter__in, .swagger-ui .response-col_links":{
			fontFamily:'Montserrat',
			fontWeight:400,
			color: 'rgba(255,255,255,.6)'
		},
		".swagger-ui .opblock .opblock-section-header h4, .swagger-ui .model-title":{
			fontFamily:'Montserrat',
			fontWeight:400,
			color: 'rgba(255,255,255,1)'
		},
		".swagger-ui .info .title": {
			fontFamily:'Montserrat',
			fontWeight:100,
			color: 'rgba(255,255,255,.7)'
		},
		".swagger-ui section.models h4": {
			fontFamily:'Montserrat',
			fontWeight:100,
			fontSize:24,
			color: 'rgba(255,255,255,.7)'
		},
		".swagger-ui .opblock-tag":{
			fontFamily:'Montserrat',
			fontWeight:100,
			color: 'rgba(255,255,255,.9)',
			"& small": {
				fontWeight:400,
				color: 'rgba(255,255,255,.7)'
			}
		},
		".swagger-ui .model, .swagger-ui .prop-format":{
			color: 'rgba(255,255,255,.8)'
		},
		".swagger-ui .btn.authorize":{
			borderColor: '#ffffff',
			fontFamily:'Montserrat',
			color: 'rgba(255,255,255,1)'
		},
		".swagger-ui .btn.authorize svg, .swagger-ui section.models h4 svg": {
			fill: '#ffffff'
		},
		".swagger-ui .info .title small, .swagger-ui .scheme-container":{
			background: theme.palette.secondary.main
		},
		".swagger-ui .opblock-body pre.microlight": {
			background: theme.palette.secondary.dark+ ' !important'
		},
		'.swagger-ui .opblock .opblock-section-header': {
			background: 'rgba(255,255,255,.1)'
		},
		".swagger-ui .opblock.opblock-post":{
			background: theme.palette.secondary.darkest
		},
		".swagger-ui section.models":{
			background: theme.palette.secondary.darkest
		},
		".swagger-ui .model-toggle:after":{
			background: `url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="rgb(255,255,255)" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>') 50% no-repeat`
		}
	}
}})



function Swagger (){
	useStyles()
	const jsonConfig = `${authServer}/api/explorer.json`  //https://petstore.swagger.io/v2/swagger.json
	return <Box vert hidden>
		<SceneHeader title={"API Explorer"} back={{to:'/admin'}} />
		<Box vert scroll>
			<SwaggerUI url={jsonConfig} tryItOutEnabled={true} />
		</Box>
	</Box>
}

export default Swagger
