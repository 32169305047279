import React from 'react';
import {getDimensions} from '../stores/resize'
import { on} from '../bus'

export const withDims = (Comp) => {
  return class extends React.Component {

  	state = {
      dimensions: getDimensions()
    }

    componentDidMount(){
      this._dims = on('updated-dimensions', ()=>{
        this.setState({dimensions:getDimensions()})
      })
    }

    componentWillUnmount(){
      this._dims()
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }
}

export default withDims