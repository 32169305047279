import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import SceneHeader from '../../base/ui/SceneHeader'
import SuccessMessage from '../../base/ui/SuccessMessage'
import ErrorMessage from '../../base/ui/ErrorMessage'
import InfoMessage from '../../base/ui/InfoMessage'
import withCarousel from './withCarousel'
import BulkSensorsSummery from './BulkSensorsSummery'
import SensorDetailsStatus from './SensorDetailsStatus'
import CarouselDetailViewer from './CarouselDetailViewer'

function DetailCarousel ({
	records, 
	detailsMap, 
	errors, 
	loaded, 
	close, 
	averageResponseTimes,
	totalExecutionTime,
	goToSlide,
	showMenu,
	tab,
	viewer,
	slideIndex,
	setView,
	goToPrevious, 
	goToNext
}){

	if(viewer){
		return <Box vert hidden grow>
			<CarouselDetailViewer 
				showMenu={showMenu}
				records={records} 
				slideIndex={slideIndex}
				tab={tab}
				detailsMap={detailsMap}
				setView={setView} 
				goToPrevious={goToPrevious}
				goToNext={goToNext}
			/>
		</Box>
	}

	return <Box vert hidden grow>
		<SceneHeader title={`Sensors Carousel (${records.length})`} back={{onClick:close}} />
		
		{(loaded+errors)===records.length && <Box vert margin={'10px 20px'}>
			{errors===0 && <SuccessMessage message={'To get started Click on any of the Sensors.'} />}
			{loaded===0 && <ErrorMessage message={`${errors} Sensor${errors===1?'':'s'} Loaded with errors. Try other Sensors.`} />}
			{loaded>0 && errors>0 && <InfoMessage message={`${errors} Sensor${errors===1?'':'s'} Loaded with errors. To get started Click on any of the Blue Sensors.`} />}

		</Box>}
		
		<BulkSensorsSummery 
			averageResponseTimes={averageResponseTimes} 
			totalExecutionTime={totalExecutionTime} 
			records={records}
			loaded={loaded}
			errors={errors}
		/>
		
		<Box vert scroll>
			<SensorDetailsStatus 
				detailsMap={detailsMap}
				records={records}
				onSelect={goToSlide}
			/>
		</Box>
	</Box>
}

export default withCarousel(DetailCarousel)