import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'refreshtoken'
export const recordSetTypeName = 'refreshtokens'
export const recordTypeDisplayName = 'Token'
export const recordSetTypeDisplayName = 'Tokens'
export const clientEndpoint = 'refreshtoken'
export const recordDisplayField = 'token'
export const recordsPath = '/admin/tokens'
export const primaryAttribute = 'token'

export const recordsPerPage = 100

export const initialSorter = {
	field:'created',
	order:'desc'
}

export const initialFilters = {
    'token':'',
    'user':'',
    'origin':'',
    'agent':'',
    'expires':null,
    'created':null
}

export const fields = inferFields({
	token:{
	  	name: 'Refresh Token',
	  	type: 'string',
	},
	user:{
		type: 'string',
	},
	origin:{
		type: 'string'
	},
	agent:{
		name: 'User Agent',
		type: 'string'
	},
	created:{
	  	type: 'date',
	},
	expires:{
	  	type: 'date',
	}
})