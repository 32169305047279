import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import SceneHeader from '../../base/ui/SceneHeader'
import CarouselItemDetail from './CarouselItemDetail'

const useStyles = makeStyles(theme=>({
	
}))

function CarouselDetailViewer ({
	records=[],  
	goToPrevious, 
	goToNext, 
	detailsMap={}, 
	slideIndex, 
	setView, 
	showMenu, 
	tab
}){
	const styles = useStyles()

	const record = records[slideIndex]
	const detail = detailsMap[record.id]

	return <>
		<SceneHeader title={`Viewing Sensor ${record.label||record.id} (${slideIndex+1} of ${records.length})`} back={{onClick:showMenu}} />
		<CarouselItemDetail 
			detail={detail} 
			record={record} 
			tab={tab} 
			setView={setView}
			goToPrevious={goToPrevious} 
			goToNext={goToNext} 
		/>
	</>
}

export default CarouselDetailViewer