import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Box from '../base/ui/Box'
import Logo from './Logo'

const useStyle = makeStyles(theme=>({
  	content:{
  		overflow:'hidden',
  		margin: '20px 0 0 0'
  	}
}))

function Auth ({children}) {
	
	const style = useStyle()

	return (<Box vert grow className={style.content}>
		<Box vert center>
			<Logo width="25%" />
		</Box>
		{children}
	</Box>)
}

export default Auth