export const recordsSorter = ({records, sorter, columns})=>{
	
	const defaultSortFn = (recs)=>{
		recs.sort(function(a,b){
			if (sorter.order==='desc') return a[sorter.field]>b[sorter.field]?-1:1
			return a[sorter.field]>b[sorter.field]?1:-1
		})
	}

	if (sorter) {
		var sortCol = columns.filter((col)=>{
			return col.field===sorter.field
		})
		const sortFn = sortCol[0].sortFn||defaultSortFn
		sortFn(records)
    }
}