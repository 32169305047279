import agent from 'superagent'
import {authServer} from '../../config'
import {handleError, handleResolve} from '../../base/utils/http'
import {dispatch} from '../../base/bus'
import {getToken} from '../auth/store'
import {clientEndpoint} from './config'

const recVo = (res)=>{
  recTrans(res.body.record)
  return res.body
}

const recsVo = (res)=>{
  res.body.records.forEach(recTrans)
  return res.body
}

export const recTrans = (rec)=>{
  if(rec.avatar){
    rec.avatar=`${authServer}${rec.avatar.http}?token=${getToken()}`
  }
}

const paramsBuilder = (req, rec)=>{
  const {avatar, ...params} = rec
  console.log('avatar', avatar)
  if(avatar && avatar.constructor===File){
    req = req.attach('avatar', avatar)
  }
  console.log(params, Object.keys(params))
  Object.keys(params).forEach((k)=>{
    console.log(k, params[k])
    if(params[k]!==null) {
      req = req.field(k,params[k])
    }
  })
  return req
}
  

export const getRecords = (query={}) =>
  new Promise((resolve, reject) => {
    dispatch('global-loading',true)
    agent.get(`${authServer}/api/collection/${clientEndpoint}/find`) 
    .set('x-access-token',getToken())
    .query({...query, populate:'tenant roles',})
    .then(handleResolve(resolve, {vo:recsVo, loader:true}))
    .catch(handleError(reject, {loader:true}))
  })

export const findRecordById = (id) =>
  new Promise((resolve, reject) => {
    dispatch('global-loading',true)
    agent.get(`${authServer}/api/collection/${clientEndpoint}/find/${id}`)
    .set('x-access-token',getToken())
    .then(handleResolve(resolve, {vo:recVo, loader:true}))
    .catch(handleError(reject, {loader:true}))
  })

export const findRecordByIdPopulated = (id) =>
  new Promise((resolve, reject) => {
    dispatch('global-loading',true)
    agent.get(`${authServer}/api/collection/${clientEndpoint}/find/${id}`)
    .set('x-access-token',getToken())
    .query({populate:'roles>perms'})
    .then(handleResolve(resolve, {vo:recVo, loader:true}))
    .catch(handleError(reject, {loader:true}))
  })

export const createRecord = (params) =>
  new Promise((resolve, reject) => {
     agent.post(`${authServer}/api/auth/register`)
      .set('x-access-token',getToken())
      .send(params)
      .then(resolve)
      .catch(handleError(reject))
  })

export const updateRecord = (id, params) =>
  new Promise((resolve, reject) => {
    var req = agent.put(`${authServer}/api/collection/${clientEndpoint}/id/${id}`)
    .set('x-access-token',getToken())
    
    req = paramsBuilder(req, params)

    req.then(resolve)
      .catch(handleError(reject))
  })

export const isUsedPassword = (password) =>
  new Promise((resolve, reject) => {    
    agent.get(`${authServer}/api/auth/old-password`)
    .set('x-access-token',getToken())
    .query({password})
    .then(handleResolve(resolve))
    .catch(handleError(reject, {notify:false}))
  })

export const deleteRecord = (record) =>
  new Promise((resolve, reject) => {
    agent.delete(`${authServer}/api/collection/${clientEndpoint}/id/${record.id}`)
    .set('x-access-token',getToken())
    .then(handleResolve(resolve))
    .catch(handleError(reject))
  })
