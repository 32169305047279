import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import InfoMessage from './InfoMessage'
import Box from './Box'

const useStyle = makeStyles({
	msg: {
		margin:5
	},
})

function FormMessageBox ({
	failureMessage, infoMessage, successMessage
}) {

	const styles= useStyle()
	if(!failureMessage && !infoMessage && !successMessage) return false

	return <Box vert margin={5}>
		<ErrorMessage className={styles.msg} message={failureMessage} />
		<InfoMessage className={styles.msg} message={infoMessage} />
		<SuccessMessage className={styles.msg} message={successMessage} />
	</Box>
}

export default FormMessageBox