import React from 'react'
import Box from './Box'
import Text from './Text'
import { makeStyles } from '@material-ui/core/styles';
import FormMessageBox from './FormMessageBox'
import ModelFormActions from './ModelFormActions'

const useStyles = makeStyles({
	form: {
		display:'flex',
		flexDirection:'column',
		flexGrow:1,
		justifyContent:'space-between',
		overflow: 'hidden',
		width:'100%'
	},
	title: {
		margin:20
	},
	fields: {
		margin:15
	}
})

function ActionFormFrame ({
	action, onClose, children, isFormProcessing, 
	onDelete, failureMessage, infoMessage, title,
	successMessage, createText, editText, cancelText,
	form
}) {
	const styles = useStyles()

	const {handleSubmit, isSubmitting, dirty, isValid} = form

	return	<form className={styles.form} onSubmit={handleSubmit}>
		<Box vert hidden>
			{title && <Text heading={2} center className={styles.title}>{title}</Text>}
			<FormMessageBox
				failureMessage={failureMessage} 
				infoMessage={infoMessage} 
				successMessage={successMessage}
			/>
			<Box vert className={styles.fields} scroll>
					{children}
			</Box>
		</Box>
		<ModelFormActions 
			dirty={dirty} 
			isValid={isValid} 
			isFormProcessing={isFormProcessing} 
			action={action} 
			onDelete={onDelete} 
			onClose={onClose}
			createText={createText}
			editText={editText}
			cancelText={cancelText}
		/>
	</form>
}

export default ActionFormFrame


		 
       
