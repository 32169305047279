import {inferFields} from '../../base/utils/model'

export const recordTypeName = 'exportedcollection'
export const recordSetTypeName = 'exportedcollections'
export const recordTypeDisplayName = 'Exported Collection'
export const recordSetTypeDisplayName = 'Exported Collections'
export const clientEndpoint = 'exportedcollection'
export const recordDisplayField = 'name'
export const recordsPath = '/admin/exports'
export const primaryAttribute = 'name'

export const recordsPerPage = 100

export const initialSorter = {
	field:'name',
	order:'asc'
}

export const initialFilters = {
	'id':'',
    'name':'',
    'description':''
}

export const fields = inferFields({
	id:{
		field: 'id',
	  	name: 'Id',
	  	type: 'string',
	},
	name:{
	  	type: 'string',
	},
	description:{
	  	type: 'string',
	},
	records: {
		type: 'object'
	}
})