import React from 'react'
import Box from '../../base/ui/Box'
import Text from '../../base/ui/Text'
import Frame from '../../ui/Frame'
import withUser from '../../admin/auth/withUser'
import E401 from '../../ui/E401'

function Home ({getPerm}) {
	if(!getPerm('+:dashboard:read')) return <E401 />
	return <Frame>
		<Box center hor grow>
			<Text heading={1}>Sexy Dashboard</Text>
		</Box>
	</Frame>
}


export default withUser(Home)