import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import Text from '../../base/ui/Text'
import Box from '../../base/ui/Box'
import FeaturesHorzNav from './FeaturesHorzNav'
import ExpanderIcon from './ExpanderIcon'
import SelectAllNoneButton from './SelectAllNoneButton'

function FeatureDialog ({
	slim,
	dimensions,
	data,
	colors,
	onToggleFeature,
	selectedFeatures,
	setSelectedFeatures,
	featurePicker,
	setFeaturePicker,
	onChangeSlim,
	onChangeAllFeatures,
	features
}){

	return <Dialog 
					open={featurePicker}
					onClose={()=>setFeaturePicker(false)}
					maxWidth={false}
				>
					<Box vert margin={20}>
						<Box hor centerVert>	
							<Box hor center>
								<ExpanderIcon expanded={slim?false:true} onChange={onChangeSlim} />
								<SelectAllNoneButton total={features.length} selected={selectedFeatures.length} onChange={onChangeAllFeatures} />
							</Box>
							<Box hor>
								<Text heading={4} center>Select Features</Text>
							</Box>
						</Box>
						<FeaturesHorzNav 
							slim={slim} 
							listWidth={dimensions.width-300}
							listHeight={600}
							data={data} 
							colors={colors}
							onToggleFeature={(i)=>setSelectedFeatures(selectedFeatures.includes(i)?selectedFeatures.filter((j)=>j!==i):[...selectedFeatures, i])}
							selectedFeatures={selectedFeatures}
						/>
					</Box>
				</Dialog>
}

export default FeatureDialog