import React from 'react';
import store from './store'
import * as services from './services'
import * as config from './config'
import withRecordsBase from '../../base/hocs/withRecordsBase'
import {fields} from './config'
import moment from 'moment'
import Text from '../../base/ui/Text'
import Link from '../../base/ui/Link'
import DateTimeRangeField from '../../base/ui/DateTimeRangeField'
import withUser from '../auth/withUser'
import DeleteIconButton from '../../base/ui/DeleteIconButton'
import {getRecords as userRecords} from '../../admin/users/services'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

export const withRecords = (Comp) => {
  
  class Cls extends React.Component {

    constructor(props){
      super(props)
      this.state = {
        userOptions:[]
      }
      
      userRecords().then(({records})=>{
        const userOptions = records.map((r)=>{
          return {value:r.id, label:r.name}
        })
        this.setState({userOptions})
        this.props.setTable(this.setTable())
      })
    }

    setTable=()=>{

      var columns =[{
        ...fields.token,
        width:325,
        cell:({record, index, value}) => {
          return <Link block to={`${config.recordsPath}/${record.id}`} >{value}</Link>  
        }
      },{
        ...fields.user,
        cell:({record, index, value}) => (
          <Text>{value && value.name}</Text>  
        )
      },{
        ...fields.origin
      },{
        ...fields.agent,
      },{
        ...fields.expires,
        width: 200,
        cell:({record, index, value}) => (
          <Text>{value && moment(value).format('M/D/YYYY h:mm:ss A')}</Text>    
        )
      },]

      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)) columns.push({
        width: 50,
        cell: ({record, index}) => (
          <DeleteIconButton 
            content={`Are you sure you Delete ${record.token}?`} 
            onConfirm={()=>{
              this.props.deleteRecord(record)
            }}
          />  
        )
      })

      var bulkActions=[]
      if(this.props.getPerm(`+:${config.recordTypeName}:delete`)){
        bulkActions.push({
          Icon: DeleteForeverIcon,
          name:'Delete All',
          action: this.props.deleteRecords,
        })
      }
     
      return {
        columns,
        bulkActions,
        facets: [{
          ...fields.token
        },{
          ...fields.user,
          options: this.state.userOptions
        },{
          ...fields.origin
        },{
          ...fields.agent
        },{
          ...fields.created,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        },{
          ...fields.expires,
          type: 'combined-date-time',
          render: ({value, field, filter, name})=>(
            <DateTimeRangeField 
              value={value} 
              empty={true} 
              label={name}
              onChange={(value)=>filter({[field]:value})}
            />
          )
        }]
      }
    }

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withUser(withRecordsBase(Cls, {config, store, services}))
}

export default withRecords