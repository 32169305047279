import React from 'react'
import * as services from './services'
import * as config from './config'
import withCollection from '../../base/hocs/withCollection'

export const withPermissions = (Comp) => {
  
  class Cls extends React.Component {

    render() {
      return (<Comp {...this.props} {...this.state} />)
    }
  }

  return withCollection(Cls, {config:{...config, query:{sort:'name'}}, services})
}

export default withPermissions
