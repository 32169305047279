import React from 'react'
import Box from '../../base/ui/Box'
import FeaturesHorzNav from './FeaturesHorzNav'
import Analytics from './Analytics'
import Anomalies from './Anomalies'
import RecentSamplesLayers from './RecentSamplesLayers'
import {materialRainbow} from '../../base/utils/color'
import Collapse from '@material-ui/core/Collapse';
import FeaturesSection from './FeaturesSection';
import moment from 'moment'
import {dispatch} from '../../base/bus'
import withDims from '../../base/hocs/withDims'
import RecentSamplesZoomLayers from './RecentSamplesZoomLayers'
import FeatureDialog from './FeatureDialog'

class FeaturesStack extends React.Component {

	state={
		slimNav:true,
		selectedFeatures: this.props.data.m_Nano.m_NanoConfig.Features.map((f,i)=>i),
		expandedAnomalies: false,
		expandedAnalytics: true,
		sampleSize: this.props.data.m_RecentTimes.m_Values.length,
		zoomScrub: false,
		zoomerArray: [],
		recentTimeArray: this.props.data.m_RecentTimes.m_Values.map((sec, x)=>({time: moment.utc(sec*1000).format('M/D/YYYY h:mm:ss a'), x})),
		featuresDetail: null,
		featurePicker:false
	}
	
	colors = materialRainbow(this.props.data.m_Nano.m_NanoConfig.Features.length)
	allTimesArray = this.props.data.m_RecentTimes.m_Values.map((sec, x)=>({time: moment.utc(sec*1000).format('M/D/YYYY h:mm:ss a'), x}))

	setSlimNav=(v)=>{
		this.setState({slimNav:v})
	}
	setFeaturePicker=(v)=>{
		this.setState({featurePicker:v})
	}

	setSelectedFeatures=(v)=>{
		this.setState({selectedFeatures:v})
	}

	setExpandedAnomalies=(v)=>{
		this.setState({expandedAnomalies:v})
	}

	setExpandedAnalytics=(v)=>{
		this.setState({expandedAnalytics:v})
	}

	setZoomScrub=(v)=>{
		//console.log('setZoomScrub',v)
		if(v===false){
			this.setState({
				zoomScrub:v,
				recentTimeArray:[...this.allTimesArray],
				sampleSize: this.allTimesArray.length,
				zoomerArray:[]
			})
			return
		}
		const recentTimeArray = this.allTimesArray.filter(({ms,x})=>{
			return (x>=v.x1 && x<=v.x2)?true:false
		})
		const sampleSize = recentTimeArray.length
		this.setState({
			zoomScrub:v,
			recentTimeArray,
			sampleSize
		})
	}	

	setZoom=(v)=>{
		//console.log('setZoomer',v)
		const recentTimeArray = this.allTimesArray.filter(({ms,x})=>{
			return (x>=v.x1 && x<=v.x2)?true:false
		})
		const sampleSize = recentTimeArray.length
		this.setState({
			zoomScrub:v,
			recentTimeArray,
			sampleSize,
			zoomerArray:[...this.state.zoomerArray, v]
		})
	}

	setZoomLess=()=>{
		const {zoomerArray} = this.state
		if(zoomerArray.length===0) return

		zoomerArray.pop()
		if(zoomerArray.length===0){
			this.setState({
				zoomScrub:false,
				recentTimeArray:[...this.allTimesArray],
				sampleSize: this.allTimesArray.length,
				zoomerArray:[]
			})
			return
		}

		const v = zoomerArray[zoomerArray.length-1]
		const recentTimeArray = this.allTimesArray.filter(({ms,x})=>{
			return (x>=v.x1 && x<=v.x2)?true:false
		})
		const sampleSize = recentTimeArray.length

		this.setState({
			zoomScrub:v,
			recentTimeArray,
			sampleSize,
			zoomerArray:[...zoomerArray]
		})
	}

	populateExtendedFeatures=(featuresDetail)=>{
		this.setState({featuresDetail})
	}

	componentDidMount(){
		dispatch('amber-sample-size-change', this.allTimesArray.length)
	}

	componentWillUnmount(){
		dispatch('amber-sample-size-change', false)
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.sampleSize !== prevState.sampleSize){
			dispatch('amber-sample-size-change', this.state.sampleSize)
		}
	}

	render(){
		const {data} = this.props
		const {slimNav, selectedFeatures, featurePicker, expandedAnomalies, expandedAnalytics, sampleSize, zoomScrub, zoomerArray, recentTimeArray, featuresDetail} = this.state
		const {setZoomScrub, setFeaturePicker, setExpandedAnomalies, setExpandedAnalytics, setSelectedFeatures, setSlimNav} = this
 		const zoomed =  recentTimeArray.length<data.m_RecentTimes.m_Values.length?true:false

		return <Box vert grow>
			<FeaturesSection
				slim={slimNav} 
				onChangeSlim={(expanded)=>setSlimNav(!expanded)} 
				onChangeAllFeatures={(i)=>setSelectedFeatures(i===1?data.m_Nano.m_NanoConfig.Features.map((f,i)=>i):[])}
				selectedFeatures={selectedFeatures}
				setFeaturePicker={setFeaturePicker}
				featurePicker={featurePicker}
				features={data.m_Nano.m_NanoConfig.Features}
			>
				{!featurePicker && <FeaturesHorzNav 
					slim={slimNav} 
					dimensions={this.props.dimensions}
					data={data} 
					hideUnSelected={true}
					colors={this.colors}
					onToggleFeature={(i)=>setSelectedFeatures(selectedFeatures.includes(i)?selectedFeatures.filter((j)=>j!==i):[...selectedFeatures, i])}
					selectedFeatures={selectedFeatures}
					featuresDetail={featuresDetail}
				/>}

				<FeatureDialog 
					features={data.m_Nano.m_NanoConfig.Features}
					slim={slimNav} 
					dimensions={this.props.dimensions}
					data={data} 
					colors={this.colors}
					setSelectedFeatures={setSelectedFeatures}
					selectedFeatures={selectedFeatures}
					featurePicker={featurePicker}
					setFeaturePicker={setFeaturePicker}
					onChangeSlim={(expanded)=>setSlimNav(!expanded)}
					onChangeAllFeatures={(i)=>setSelectedFeatures(i===1?data.m_Nano.m_NanoConfig.Features.map((f,i)=>i):[])}
				/>

				<RecentSamplesLayers
					data={data} 
					zoomed={zoomed}
					selectedFeatures={selectedFeatures} 
					colors={this.colors} 
					allTimesArray={this.allTimesArray}
					zoomScrub={zoomScrub}
					setZoomScrub={setZoomScrub}
				/>
				
				<Collapse in={zoomed}>
					<RecentSamplesZoomLayers 
						height={300}
						data={data} 
						zoomed={zoomed}
						selectedFeatures={selectedFeatures} 
						colors={this.colors} 
						setZoom={this.setZoom}
						recentTimeArray={recentTimeArray}
						allTimesArray={this.allTimesArray}
						sampleSize={sampleSize}
						populateExtendedFeatures={this.populateExtendedFeatures}
						zoomerArray={zoomerArray}
						setZoomLess={this.setZoomLess}
					/>
				</Collapse>
			</FeaturesSection>

			<Analytics 
				data={data} 
				expandedAnalytics={expandedAnalytics} 
				onChangeExpanded={setExpandedAnalytics}
				recentTimeArray={recentTimeArray}
				allTimesArray={this.allTimesArray}
				sampleSize={sampleSize}
			/>

			<Anomalies 
				data={data} 
				expandedAnomalies={expandedAnomalies} 
				onChangeExpanded={setExpandedAnomalies}
				recentTimeArray={recentTimeArray}
				allTimesArray={this.allTimesArray}
				sampleSize={sampleSize}
			/>
		</Box>
	}
}

export default withDims(FeaturesStack)