import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '../../base/ui/IconButton'
import Text from '../../base/ui/Text'
import UndoIcon from '@material-ui/icons/Undo'

const useStyles = makeStyles(theme=>({
	root:{
		position:'absolute',
		top:-35,
		left:-3
	},
	count:{
		marginLeft:5
	}
}))

function ZoomOutButton ({onZoomLess, zoomerArray}){
	const styles = useStyles()
	if(!zoomerArray || !zoomerArray.length) return false
	return <IconButton className={styles.root} onClick={onZoomLess}>
		<UndoIcon />
		<Text className={styles.count}>{zoomerArray.length}</Text>
	</IconButton>
}

export default ZoomOutButton