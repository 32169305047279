import React from 'react'
import Section from './Section'
import ExpanderIcon from './ExpanderIcon'

function AnomalyChartSection ({children, title, subtitle, post, expandedAnomalies, onChangeExpanded}){
	return <Section title={title} subtitle={subtitle} post={post} start={<ExpanderIcon expanded={expandedAnomalies} onChange={onChangeExpanded} />}>
		{children}
	</Section>
}

export default AnomalyChartSection