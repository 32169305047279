import {on, dispatch} from '../../base/bus'

var _user
var _token

export const getUser = ()=>{
  return _user
}

export const getToken = ()=>{
  return _token
}

on('user-token', (token)=>{
	_token=token
})

on('user-data', (data)=>{
  _user=data
  if(data && localStorage.getItem('amber-auth-redirect')){
  	window.location.href = localStorage.getItem('amber-auth-redirect')
    localStorage.removeItem('amber-auth-redirect')
  }
})
on('auth-logout', ()=>{
  _token=false
	dispatch('user-data', false)
})

on('loaded-user', (data)=>{
	if(_user && data && data._id===_user.id){
		dispatch('user-data', {...data, roles:_user.roles})
	}
})

on('updated-user', (data)=>{
  if(_user && data && data.id===_user.id){
    dispatch('user-data', {...data, roles:_user.roles})
  }
})
